import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks';

interface ResponseProps {
  zip_url: string;
}

export const useDownloadAllTracks = ({ songId }: { songId: string }) => {
  const api = useApi({});

  return useQuery({
    queryKey: ['tracks-download', songId],
    queryFn: async (): Promise<string> => {
      const response = await api.get<ResponseProps>(`/v1/songs/${songId}/tracks/zip`);

      return response.data.zip_url;
    },
    enabled: false
  });
};
