import React, { ReactNode } from 'react';
import { Modal as CoreModal, PortalProps, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { ButtonProps, ButtonLib } from '@/components';
import { useModalStore } from '@/store/useModalStore';

import styles from './modal.module.css';

export interface ModalFooterBtn extends Omit<ButtonProps, 'children'> {
  key: string;
  onClick: () => void;
  content: string | ReactNode;
  dataTestId?: string;
}

interface ModalProps {
  name: string;
  children: ReactNode;
  onClose?: () => void;
  title?: string;
  size?: string;
  head?: ReactNode;
  footer?: ReactNode;
  footerBtns?: ModalFooterBtn[];
  portalProps?: Omit<PortalProps, 'children'>;
  className?: string;
  isCentered?: boolean;
  closeOnClickOutside?: boolean;
}

export const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const {
    name,
    head,
    children,
    onClose,
    footerBtns = [],
    portalProps,
    title,
    footer,
    className = '',
    isCentered,
    size = 'auto',
    closeOnClickOutside = true
  } = props;
  const { modalOption, closeModal } = useModalStore();
  const theme = useMantineTheme();
  const md = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);

  if (!modalOption.name || !modalOption.name.includes(name)) {
    return null;
  }

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    closeModal();
  };

  return (
    <CoreModal.Root
      opened
      onClose={handleClose}
      size={size}
      fullScreen={!md}
      portalProps={portalProps}
      className={className}
      centered={isCentered}
      closeOnClickOutside={closeOnClickOutside}
    >
      <CoreModal.Overlay className={styles.overlay} />

      <CoreModal.Content className={styles.content}>
        <CoreModal.Header className={styles.header}>
          {head ? (
            head
          ) : (
            <>
              <CoreModal.CloseButton className={styles.closeButton} />

              <CoreModal.Title className={styles.title}>{title}</CoreModal.Title>
            </>
          )}
        </CoreModal.Header>

        <CoreModal.Body className={styles.body}>{children}</CoreModal.Body>

        {footer ? (
          footer
        ) : (
          <div data-div-type="footer" className={styles.footer}>
            {footerBtns.map(({ content, key, dataTestId, ...rest }) => (
              <ButtonLib key={key} {...rest} data-testid={dataTestId}>
                {content}
              </ButtonLib>
            ))}
          </div>
        )}
      </CoreModal.Content>
    </CoreModal.Root>
  );
};
