import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '@/hooks';
import { notifications } from '@/utils/notifications';
import { useSongStore } from '@/store';
import { ISong } from '@/models/Song.ts';

export const usePostSongApproveRevoke = () => {
  const api = useApi({});
  const queryClient = useQueryClient();
  const {
    sortOptions: { sortBy, sortOrder },
    limit,
    isRequireLicense
  } = useSongStore();

  return useMutation({
    mutationFn: async (id: string): Promise<ISong> => {
      const response = await api.post(`/v1/songs/${id}/approval/revoke`);

      return response.data;
    },
    onSuccess: (song: ISong) => {
      void queryClient.invalidateQueries({
        queryKey: ['songs', sortBy, sortOrder, limit, isRequireLicense]
      });
      notifications.success(`Song ${song.name} has been revoked from approve`);
    },
    onError: () => {
      notifications.error("Couldn't withdraw song approval");
    }
  });
};
