import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useApi } from '@/hooks';
import { AccountCard } from '@/models/Account';

export const useGetCardDetail = (): UseQueryResult<AccountCard, AxiosError> => {
  const api = useApi({});

  return useQuery<AccountCard, AxiosError>({
    queryKey: ['account-card'],
    queryFn: async (): Promise<AccountCard> => {
      const { data } = await api.get<AccountCard>('/v1/account/card-placeholder');

      return data;
    }
  });
};
