import { SVGAttributes } from 'react';

const LockIcon = ({ fill = 'currentColor', ...rest }: SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 1200 1200"
      {...rest}
    >
      <path
        fill={fill}
        d="M600 0C268.629 0 0 268.629 0 600s268.629 600 600 600s600-268.629 600-600S931.371 0 600 0m3.955 209.912l94.556 295.239l309.889 6.958l-251.588 181.055l89.136 296.924l-249.976-183.325l-254.81 176.587l97.119-294.434l-246.68-187.793l310.034 1.392z"
      />
    </svg>
  );
};

LockIcon.displayName = 'LockIcon';

export default LockIcon;
