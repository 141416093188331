import { useMantineTheme } from '@mantine/core';
import AddIcon from '@/assets/icons/Add';
import { ParticipantType, Collaborator } from '@/models/Participant';
import { useGetParticipants, useSongPermission } from '@/hooks';
import { useModalStore, useSongStore } from '@/store';
import { ADD_COLLABORATOR_MODAL_NAME, WORK_TYPE_SONGS } from '@/constants/song';
import { IconButton, PieChart, Table, Tooltip } from '@/components';
import { CollaborationsSongProps } from '@/models/Song.ts';
import { RELEASE_MODAL_NAME } from '@/pages/Projects';

import { COLUMNS } from './columns.tsx';
import { AddCollaboratorModal } from '../common';

export const Collaborators = () => {
  const { hasReadPermission } = useSongPermission();
  const { openModal, modalOption } = useModalStore();
  const { colors } = useMantineTheme();
  const { songData, isSongEditable } = useSongStore();
  const { data: collaborators, isFetching } = useGetParticipants({
    workType: WORK_TYPE_SONGS,
    workId: (songData as CollaborationsSongProps).song_id || songData.id,
    type: ParticipantType.PARTICIPANT
  });

  const songwriters =
    collaborators?.filter(
      (collaborator): collaborator is Collaborator => collaborator.role === 'lyricist'
    ) || [];

  const chartData = songwriters?.map(({ name, id, split }) => ({
    name,
    value: split,
    id
  }));

  const openCollaboratorModal = () => openModal({ name: ADD_COLLABORATOR_MODAL_NAME });

  const isVisibleAddCollaborator =
    songData.status !== 'release_scheduled' || modalOption.name !== RELEASE_MODAL_NAME;

  return (
    <div className="mt-14 flex flex-col">
      {chartData.length > 0 ? <PieChart data={chartData} label="Contributor • Publishing" /> : null}

      <div className="flex justify-between items-center mb-2">
        <div className="flex gap-x-1 text-sm font-semibold text-hookybase-500 dark:text-white w-full">
          COLLABORATORS
          <Tooltip tooltipContent="Adding a collaborator will allow you to input further split percentages." />
        </div>

        {isVisibleAddCollaborator && (
          <IconButton
            onClick={openCollaboratorModal}
            disabled={hasReadPermission || !isSongEditable}
          >
            <AddIcon color={isSongEditable ? colors.dark[3] : colors.dark[5]} />
          </IconButton>
        )}
      </div>

      {songwriters.length > 0 ? (
        <Table columns={COLUMNS} data={songwriters} isLoading={isFetching} />
      ) : (
        <div className="flex flex-col justify-center items-center pt-36">
          {isVisibleAddCollaborator && (
            <IconButton
              onClick={openCollaboratorModal}
              disabled={hasReadPermission || !isSongEditable}
            >
              <AddIcon color={isSongEditable ? colors.dark[3] : colors.dark[5]} />
            </IconButton>
          )}

          <div className="pt-4 text-white text-2xl text-center">No collaborators!</div>
          <p className="pt-4 text-hookybase-300 max-w-xs text-center">
            There are no collaborators assigned to this project.
            {isVisibleAddCollaborator ? ' Add them here.' : ''}
          </p>
        </div>
      )}

      <AddCollaboratorModal songId={songData.id} isSongWriterForm={true} />
    </div>
  );
};
