import { useAuth } from 'oidc-react';
import { ButtonLib, Typo } from '@/components';

const NotAuthorized = () => {
  const { signOutRedirect } = useAuth();
  const handleSignOut = () => {
    void signOutRedirect();
  };
  return (
    <main className="p-16 flex flex-col gap-4 items-center">
      <Typo.h1>403! Forbidden!</Typo.h1>
      <Typo.p>Access to this page is restricted.</Typo.p>
      <ButtonLib onClick={handleSignOut}>Sign Out</ButtonLib>
    </main>
  );
};

export default NotAuthorized;
