import { useApi } from '@/hooks';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { SongTrackProps } from '@/models/Song';

export interface UseGetSongTracksProps {
  songId?: string;
  refetchInterval?: number | false;
}

export const useGetSongTracks = ({ songId, refetchInterval = false }: UseGetSongTracksProps) => {
  const api = useApi({});

  return useQuery<SongTrackProps[], AxiosError>({
    queryKey: ['tracks', songId],
    queryFn: async (): Promise<SongTrackProps[]> => {
      const response = await api.get<SongTrackProps[]>(`/v1/songs/${songId}/tracks`);

      return response.data || [];
    },
    enabled: !!songId,
    refetchInterval
  });
};
