export const getAudioProgressBarWidth = (progress: number, duration: number) => {
  const progressPercent = (progress / duration) * 100;
  if (progressPercent >= 100) {
    return '100%';
  } else if (progress <= 0) {
    return '0';
  } else if (progressPercent < 10) {
    return `calc(${progressPercent}% + 3px)`;
  } else {
    return `calc(${progressPercent}%)`;
  }
};
