import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '@/hooks';
import { notifications } from '@/utils/notifications';

interface SelectEnterprisePlanProps {
  name: string;
  phone_number: string;
  email?: string;
}

export const useSelectEnterprisePlan = () => {
  const api = useApi({});

  return useMutation<unknown, AxiosError, SelectEnterprisePlanProps>({
    mutationFn: async (values): Promise<void> => {
      await api.post('/v1/subscription/callback', values);
    },
    onSuccess: () => {
      notifications.success('Request sent successfully');
    },
    onError: () => {
      notifications.error('Could not sent request');
    }
  });
};
