import React, { useState } from 'react';
import { Combobox, ComboboxOptionProps, ScrollArea, TextInput, useCombobox } from '@mantine/core';
import { Control, useController } from 'react-hook-form';

import styles from './autocomplete.module.css';
import inputStyles from '../input/input.module.css';
import { INPUT_WRAPPER_ORDER } from '@/components/atoms/form/input/constant.ts';
import { DefaultItemOption } from '@/models/common.ts';
import { getFilteredOption } from '@/components/atoms/form/autocomplete/utils.ts';

export interface AutocompleteProps {
  name: string;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  placeholder?: string;
  description?: string;
  classes?: string;
  isShowSearchValue?: boolean;
  data: DefaultItemOption[];
  onChange?: (name: string, option: DefaultItemOption) => void;
  renderOption?: (option: DefaultItemOption, currentValue: string | null) => React.ReactNode;
}

const CLASSES = {
  wrapper: inputStyles.wrapper,
  input: inputStyles.input,
  label: inputStyles.label,
  error: inputStyles.error,
  description: inputStyles.description
};

// TODO think about performance(debounce, memo)
export const Autocomplete: React.FC<AutocompleteProps> = (props) => {
  const {
    name,
    control,
    onChange,
    label,
    data,
    renderOption,
    placeholder,
    description,
    classes = '',
    isShowSearchValue = true
  } = props;

  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: ''
  });

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  });

  const [search, setSearch] = useState('');

  const isShouldFilter = data.every((item) => item.label !== search);
  const filteredOptions = getFilteredOption(data, isShouldFilter, search);

  const options = filteredOptions.map((item) => {
    if (renderOption) {
      return renderOption(item, field.value);
    }

    return (
      <Combobox.Option
        key={item.value}
        value={item.value}
        className={styles.option}
        active={item.value === field.value}
      >
        {item.label}
      </Combobox.Option>
    );
  });

  const handleOptionSubmit = (value: string, option: ComboboxOptionProps) => {
    const label = option.children as unknown as string;

    if (onChange) {
      onChange(name, {
        value,
        label
      });
    } else {
      field.onChange(value);
    }

    setSearch(isShowSearchValue ? label : '');

    combobox.closeDropdown();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    combobox.updateSelectedOptionIndex();

    setSearch(event.currentTarget.value);
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    combobox.closeDropdown();

    setSearch(isShowSearchValue ? event.currentTarget.value : '');
  };

  return (
    <div className={`${styles.wrapperBox} ${classes}`}>
      {label ? (
        <label className="block text-sm font-semibold text-hookybase-500 dark:text-white w-full mb-2">
          {label}
        </label>
      ) : null}

      <Combobox onOptionSubmit={handleOptionSubmit} store={combobox} keepMounted>
        <Combobox.Target>
          <TextInput
            value={search}
            classNames={CLASSES}
            onChange={handleInputChange}
            placeholder={placeholder}
            description={description}
            inputWrapperOrder={INPUT_WRAPPER_ORDER}
            error={fieldState.error ? fieldState.error.message : null}
            onClick={combobox.openDropdown as () => void}
            onFocus={combobox.openDropdown as () => void}
            onBlur={handleBlur}
          />
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>
            <ScrollArea.Autosize type="scroll" mah={250}>
              {options.length === 0 ? <Combobox.Empty>Nothing found</Combobox.Empty> : options}
            </ScrollArea.Autosize>
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </div>
  );
};
