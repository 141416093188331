import { useMutation } from '@tanstack/react-query';
import { useApi } from '@/hooks';
import { ParticipantsApiParams } from '@/models/Participant.ts';
import { notifications } from '@/utils/notifications';

export const useResentParticipantInvitation = ({
  workType = 'songs',
  workId
}: ParticipantsApiParams) => {
  const api = useApi({});

  return useMutation({
    mutationFn: async (id: string): Promise<void> => {
      await api.post(`/v1/${workType}/${workId}/participants/${id}/resend`);
    },
    onSuccess: () => {
      notifications.success(`The invitation has been resent`);
    },
    onError: () => {
      notifications.error(`Couldn't send invitation`);
    }
  });
};
