import { twMerge } from 'tailwind-merge';
import { ModelCard } from '@/pages/Models/components/ModelCard';
import { VoiceModel } from '@/models/VoiceModel.ts';
import { useMyModelsTableRowHandler } from '@/pages/Models/hooks/useMyModelsTableRowHandler';
import { useDrawerStore } from '@/store';

export const ModelsGridContainer = ({ models }: { models?: VoiceModel[] }) => {
  const handleRowClick = useMyModelsTableRowHandler();
  const { isOpenDrawer } = useDrawerStore();
  return (
    <div
      className={twMerge(
        'grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4',
        isOpenDrawer && 'md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2'
      )}
    >
      {models?.map((model) => (
        <ModelCard key={model.voice_id} model={model} onCardClick={handleRowClick} />
      ))}
    </div>
  );
};
