import React, { ChangeEvent } from 'react';
import { Control, useController } from 'react-hook-form';
import { Textarea as TextareaM } from '@mantine/core';

import { INPUT_WRAPPER_ORDER } from '../input/constant.ts';
import inputStyles from '../input/input.module.css';
import styles from './text-area.module.css';

const CLASSES = {
  wrapper: inputStyles.wrapper,
  input: `${inputStyles.input} ${styles.input}`,
  label: inputStyles.label,
  error: inputStyles.error,
  description: inputStyles.description
};

interface SelectProps {
  name: string;
  label?: string | React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  placeholder?: string;
  description?: string;
  minRows?: number;
  maxRows?: number;
}

export const TextAreaField = (props: SelectProps) => {
  const { name, label, control, placeholder = '', description, maxRows = 2, minRows = 1 } = props;
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: ''
  });

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    field.onChange(event);
  };

  return (
    <TextareaM
      {...field}
      value={field.value ? field.value : ''}
      label={label}
      classNames={CLASSES}
      placeholder={placeholder}
      description={description}
      onChange={handleChange}
      error={fieldState.error ? fieldState.error.message : null}
      inputWrapperOrder={INPUT_WRAPPER_ORDER}
      size="lg"
      minRows={minRows}
      maxRows={maxRows}
    />
  );
};
