import { ReactElement, ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { getTabsActiveClasses } from './utils';

export interface TabProps {
  className?: string;
  label: string;
  children: ReactNode;
  name?: string;
}

export interface TabsProps {
  className?: string;
  children: Array<ReactElement<TabProps | Element> | null>;
  variant?: 'pill' | 'text' | 'square';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  onTabClick?: (tabName: string) => void;
}

const Tab = ({ className = '', children, ...rest }: TabProps) => (
  <div className={twMerge('mt-4 text-white', className)} {...rest}>
    {children}
  </div>
);

const Tabs = ({
  className = '',
  children,
  variant = 'pill',
  size = 'sm',
  onTabClick
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const { classes, activeClasses, inactiveClasses } = getTabsActiveClasses({ variant, size });

  return (
    <div className={twMerge('w-full', className)}>
      <div className="flex flex-wrap gap-2">
        {children.map((child, index) => {
          if (!child?.props.children) {
            return null;
          }

          const handleClick = () => {
            setActiveTab(index);

            if (onTabClick) {
              onTabClick((child.props as TabProps).name || '');
            }
          };

          return (
            <button
              key={index}
              className={twMerge(
                'flex-0 whitespace-nowrap py-2 font-bold border uppercase focus:outline-none',
                classes,
                activeTab === index ? activeClasses : inactiveClasses
              )}
              onClick={handleClick}
            >
              {(child.props as TabProps).label}
            </button>
          );
        })}
      </div>

      {children[activeTab]}
    </div>
  );
};

export { Tab, Tabs };
