import { ChangeEvent, FC, useRef } from 'react';
import { ButtonLib, Icon, Typo } from '@/components';
import { ALLOWED_SONG_EXTS } from '@/constants';

interface FileUploadDropBoxProps {
  onUpload: (files: FileList) => void;
  isLoading?: boolean;
  uploadProgress?: number;
  acceptFileExtensions?: string;
}

export const FileUploadDropBox: FC<FileUploadDropBoxProps> = ({
  onUpload,
  acceptFileExtensions = ALLOWED_SONG_EXTS.join(','),
  isLoading = false,
  uploadProgress
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadBoxFile = () => {
    if (isLoading) {
      return;
    }

    fileInputRef.current?.click();
  };

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (isLoading) {
      return;
    }

    const input = event.target;
    const files = input.files;

    if (!files) {
      return;
    }
    onUpload(files);
    input.value = '';
  };

  return (
    <div className="cursor-pointer border border-hookybase-300 border-dashed rounded-xl flex items-center justify-center flex-col gap-6 h-48 mb-6 relative">
      <Typo.p className="text-hookybase-900 dark:text-white">Drag and drop your files here</Typo.p>

      <ButtonLib
        onClick={handleUploadBoxFile}
        loading={isLoading}
        className="z-10"
        variant="outline"
        background="secondary"
        disabled={Boolean(uploadProgress && uploadProgress > 0 && uploadProgress < 100)}
        size="xs"
      >
        {!uploadProgress || uploadProgress === 0 ? (
          <>OR CHOOSE FILE</>
        ) : (
          <>
            <Icon icon="Spinner" className="animate-spin w-6 h-6 mr-2" /> {uploadProgress}%
          </>
        )}
      </ButtonLib>

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleUploadFile}
        multiple={true}
        accept={acceptFileExtensions}
        className="bg-transparent absolute left-0 right-0 top-0 bottom-0 w-full h-full opacity-0 cursor-pointer"
        title=""
        disabled={isLoading}
      />
    </div>
  );
};
