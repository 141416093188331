import { useState } from 'react';
import { ThemeContext } from './ThemeContext';

export interface IThemeProviderProps {
  children: React.ReactNode;
}

// Create a ThemeProvider component to provide the context value to child components
export const ThemeProvider: React.FC<IThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<'light' | 'dark'>(() => {
    const theme = localStorage.getItem('theme');
    return theme ? (theme as 'light' | 'dark') : 'dark';
  });

  function setThemeAndStore(theme: 'light' | 'dark') {
    localStorage.setItem('theme', theme);
    setTheme(theme);
  }

  return (
    <ThemeContext.Provider value={{ theme, setTheme: setThemeAndStore }}>
      <div className={theme == 'light' ? 'light' : 'dark'}>{children}</div>
    </ThemeContext.Provider>
  );
};
