import { Tab, Tabs } from '@/components/atoms/tabs/Tabs';
import { Vocals } from './components/vocals';
import { Licenses } from './components/licenses';

import { TAB_NAME } from '@/constants/song';
import { useSongStore } from '@/store';

export const VoiceAI = () => {
  const { songData } = useSongStore();
  return (
    <Tabs className="relative mt-2 md:mt-4" variant="square">
      <Tab name={TAB_NAME.vocalAIVocal} label="Vocals" data-testid="vocals">
        <Vocals />
      </Tab>
      {songData.requires_license ? (
        <Tab name={TAB_NAME.vocalAILicenses} label="Licenses" data-testid="licenses">
          <Licenses />
        </Tab>
      ) : null}
    </Tabs>
  );
};
