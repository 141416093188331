import { ButtonLib } from '@/components';
import { CollaborationsSongProps, SingleSongStatus } from '@/models/Song';
import { COLLABORATOR_STATUS, STATUS } from '@/constants/song';
import { ButtonBackgroundProps, ButtonVariantProps } from '@/components';
import { useDenyCollaboration, useApproveCollaboration } from '@/hooks';

import styles from './colloborant-status-button.module.css';

interface StatusButtonProps {
  data: CollaborationsSongProps;
  size?: 'xs' | 'lg';
}

const STATUS_COLOR: { [k in SingleSongStatus]: ButtonBackgroundProps } = {
  draft: 'secondary',
  denied: 'fourth',
  upload_for_approval: 'fourth',
  awaiting_approval: 'secondary',
  change_artist: 'primary',
  released: 'third',
  release_song: 'third',
  release_scheduled: 'secondary',
  taken_down: 'secondary',
  archived: 'secondary'
};

const STATUS_VARIANT: { [k in SingleSongStatus]: ButtonVariantProps } = {
  draft: 'outline',
  denied: 'filled',
  upload_for_approval: 'filled',
  awaiting_approval: 'outline',
  change_artist: 'filled',
  released: 'filled',
  release_song: 'filled',
  release_scheduled: 'outline',
  taken_down: 'outline',
  archived: 'outline'
};

export const CollaborationsStatusButton = (props: StatusButtonProps) => {
  const { size = 'xs' } = props;
  const { status, collaboration_status, id } = props.data;

  const { mutate: denySong, isLoading: isDenyLoading } = useDenyCollaboration();
  const { mutate: approveSong, isLoading: isApproveLoading } = useApproveCollaboration();

  const handleApprove = () => {
    approveSong(id);
  };

  const handleDeny = () => {
    denySong(id);
  };

  const background = STATUS_COLOR[status];
  const variant = STATUS_VARIANT[status];
  const isDisabled = isDenyLoading || isApproveLoading;

  if (collaboration_status === COLLABORATOR_STATUS.pending) {
    return (
      <div className={styles.wrapper}>
        <ButtonLib
          onClick={handleDeny}
          variant="filled"
          background="fourth"
          size={size}
          loading={isDenyLoading}
          disabled={isDisabled}
        >
          DENY
        </ButtonLib>

        <ButtonLib
          onClick={handleApprove}
          background="third"
          size={size}
          loading={isApproveLoading}
          disabled={isDisabled}
        >
          APPROVE
        </ButtonLib>
      </div>
    );
  }

  return (
    <div className={`${styles.base} ${styles[size]} ${styles[variant]} ${styles[background]}`}>
      {STATUS[status]}
    </div>
  );
};
