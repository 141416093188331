import { forwardRef } from 'react';
import { RefCallBack } from 'react-hook-form';
import { getAudioProgressBarWidth } from '@/utils/getAudioProgressBarWidth.ts';

import styles from './slider-base.module.css';

interface SliderProps {
  value: number;
  onChange: (value: number) => void;
  thumbAlwaysShown?: boolean;
  min?: number;
  max?: number;
  step?: number;
  ref?: RefCallBack;
  maxValue?: number;
  disabled?: boolean;
}

export const Slider = forwardRef<HTMLInputElement, SliderProps>((props, ref) => {
  const {
    value,
    onChange,
    min = 0,
    max = 100,
    step = 1,
    thumbAlwaysShown = false,
    maxValue,
    ...rest
  } = props;

  const handleChange = (value: number) => {
    if (maxValue && value > maxValue) {
      onChange(maxValue);

      return;
    }

    onChange(value);
  };

  return (
    <div className={styles.slider}>
      <div className={styles.progressBarBackground}>
        <div
          className={styles.progressBar}
          style={{ width: getAudioProgressBarWidth(value, max) }}
        />
      </div>

      <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="shape" clipPathUnits="objectBoundingBox">
            <path d="M0.026,0.001 C0.185,0.212,0.345,0.291,0.504,0.334 C0.664,0.369,0.823,0.362,0.982,0.163 C0.992,0.151,0.999,0.292,1,0.478 C1,0.664,0.994,0.825,0.985,0.837 C0.944,0.789,0.904,0.747,0.863,0.722 C0.604,0.569,0.345,0.666,0.086,0.925 C0.066,0.946,0.046,0.97,0.026,0.999 C0.013,1,0.001,0.81,0,0.535 C-0.001,0.235,0.012,-0.022,0.026,0.001" />
          </clipPath>
        </defs>
      </svg>

      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onInput={(e) => {
          const target = e.target as HTMLInputElement;
          handleChange(parseFloat(target.value));
        }}
        className={`${styles.sliderRangeInput} ${thumbAlwaysShown ? styles.isAlwaysVisible : ''}`}
        {...rest}
        ref={ref}
      />
    </div>
  );
});
