import { toast, ToastOptions } from 'react-toastify';

const DEFAULT_ERROR_CONTENT = 'Something wrong happened!';
const DEFAULT_SUCCESS_CONTENT = 'Completed successfully!';
const DEFAULT_WARNING_CONTENT = 'Warning!';

/**
 * A collection of notification functions that display messages using Ant Design's `message` component.
 * @namespace
 */
export const notifications = {
  /**
   * Display an error notification message.
   * @param {string} [content=DEFAULT_ERROR_CONTENT] - The content of the error message.
   * @param options
   * @function
   */
  error: (content: string = DEFAULT_ERROR_CONTENT, options?: ToastOptions<never> | undefined) =>
    toast.error(content, options),

  /**
   * Display a success notification message.
   * @param {string} [content=DEFAULT_SUCCESS_CONTENT] - The content of the success message.
   * @function
   */
  success: (content: string = DEFAULT_SUCCESS_CONTENT) => toast.success(content),

  /**
   * Display a warning notification message.
   * @param {string} [content=DEFAULT_WARNING_CONTENT] - The content of the warning message.
   * @function
   */
  warning: (content: string = DEFAULT_WARNING_CONTENT) => toast.warning(content)
};
