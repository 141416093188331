import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useApi } from '@/hooks';
import { ISong } from '@/models/Song';
import { useSongStore } from '@/store';
import { updateSongCache, SongQueryData } from '@/utils/updateSongCache.ts';
import { notifications } from '@/utils/notifications';

export const useUpdateSongData = (
  isNeedRefetch?: boolean
): UseMutationResult<ISong, AxiosError, ISong> => {
  const api = useApi({});
  const queryClient = useQueryClient();
  const { songData, setSongData, sortOptions, limit, isRequireLicense } = useSongStore();

  return useMutation<ISong, AxiosError, ISong>({
    mutationFn: async (song: ISong): Promise<ISong> => {
      const result = await api.put<ISong>(`/v1/songs/${song.id}`, song);

      return result.data;
    },
    onSuccess: (updatedSong) => {
      if (songData.id === updatedSong.id) {
        setSongData(updatedSong);
      }
      if (isNeedRefetch) {
        void queryClient.invalidateQueries({
          queryKey: ['songs', sortOptions.sortBy, sortOptions.sortOrder, limit, isRequireLicense]
        });

        return;
      }

      queryClient.setQueryData<unknown>(
        ['songs', sortOptions.sortBy, sortOptions.sortOrder, limit, isRequireLicense],
        (oldData: SongQueryData) => updateSongCache(oldData, updatedSong)
      );
    },
    onError: () => {
      notifications.error("Couldn't update song");
    }
  });
};
