import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SortOptionsProps } from '@/models/common.ts';
import { useSongStore } from '@/store';
import { useGetCollaborations, useInfiniteScroll } from '@/hooks';
import { Table, TableSortData, NoDataComponent } from '@/components';
import { RELEASED_SONGS_COLUMN } from './columns.tsx';
import { useMyModelsTableRowHandler } from '@/pages/Models/hooks/useMyModelsTableRowHandler.ts';
import { VoiceModel, VoiceModelStatus } from '@/models/VoiceModel.ts';
import { useModelContext } from '@/pages/Models/hooks/useModelContext.ts';
import { ModelsGridContainer } from './components/ModelsGridContainer';
import { ROUTES } from '@/routes/routes.tsx';

const ModelsShared = () => {
  const handleRowClick = useMyModelsTableRowHandler();
  const navigate = useNavigate();
  const { sortOptions, setSortOptions } = useSongStore();
  const { isGrid } = useModelContext();
  const { infinityRef: ref, isIntersecting: inView } = useInfiniteScroll();
  const {
    data: collaborations = { pages: [] },
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useGetCollaborations(sortOptions);

  useEffect(() => {
    if (inView && hasNextPage) {
      void fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, hasNextPage]);

  const collaborationsList = collaborations.pages.flat();

  const handleSort = (options: SortOptionsProps) => {
    setSortOptions(options);
  };

  const sortData: TableSortData = {
    onSort: handleSort,
    sortOptions
  };

  // TODO: TESTING
  const models: VoiceModel[] =
    collaborationsList?.map((song, index) => ({
      voice_id: song.id,
      avatar_url: song.image_url,
      name: song.name,
      audio_preview: {
        url: 'https://assets.dev.hooky.co/artists/preview/SaraPhillips.mp3',
        duration: 60,
        file_size: 2056
      },
      status: (index % 3 === 0
        ? 'archived'
        : index % 2 === 0
          ? 'request_submitted'
          : 'published') as VoiceModelStatus,
      created_at: song.created_at,
      artist_name: song.voices[0].name,
      visibility: 'private',
      royalty_percent: 20,
      approval_days: 14,
      auto_approve: false
    })) || [];

  const onTrainModelClick = () => {
    navigate(ROUTES.TRAIN_MODEL);
  };

  return models.length === 0 ? (
    <NoDataComponent
      onClick={onTrainModelClick}
      buttonTitle="TRAIN A MODEL"
      description="You currently have no models. Train a model now!"
    />
  ) : isGrid ? (
    <ModelsGridContainer models={models} />
  ) : (
    <Table
      columns={RELEASED_SONGS_COLUMN}
      data={models}
      sortData={sortData}
      onRowClick={handleRowClick}
      infinityRef={ref}
      isLoading={isFetching}
    />
  );
};

export default ModelsShared;
