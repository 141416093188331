import { useState } from 'react';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import ImageEditor from '@uppy/image-editor';
import Compressor from '@uppy/compressor';
import { ISong } from '@/models/Song';
import { useGetAccessToken } from '@/hooks';
import { useModalStore } from '@/store';
import { API_URL } from '@/config';
import { notifications } from '@/utils/notifications';
import { ModalFooterBtn, Modal } from '@/components';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/image-editor/dist/style.min.css';
import './ModalCropImage.css';

export const CROP_AVATAR_MODAL = 'CROP_AVATAR_MODAL';
const ALLOWED_IMAGE_EXTS = ['.jpg', '.png', '.jpeg'];
const MAX_SIZE = 512000;

export const ModalCropImage = ({
  modalTitle,
  onProgressChange,
  endpoint,
  fileFieldName: fieldName,
  onUploadSuccess
}: {
  endpoint: string;
  fileFieldName: 'file' | 'image';
  modalTitle?: string;
  onProgressChange: (progress: number) => void;
  onUploadSuccess: (responseBody?: ISong) => void;
}) => {
  const accessToken = useGetAccessToken();
  const { closeModal } = useModalStore();
  const isCropDisabled = false;
  const [uppy] = useState(() =>
    new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        maxFileSize: MAX_SIZE,
        allowedFileTypes: ALLOWED_IMAGE_EXTS
      },
      locale: {
        strings: {
          dropPasteFiles: 'Drag and drop your file here %{browseFiles}',
          browseFiles: 'or choose file'
        }
      }
    })
      .use(ImageEditor, {
        actions: {
          revert: true,
          rotate: true,
          granularRotate: true,
          flip: true,
          zoomIn: true,
          zoomOut: true,
          cropSquare: false,
          cropWidescreen: false,
          cropWidescreenVertical: false
        },
        cropperOptions: {
          aspectRatio: 1,
          croppedCanvasOptions: {
            imageSmoothingQuality: 'high'
          }
        }
      })
      .use(XHRUpload, {
        endpoint: `${API_URL}${endpoint}`,
        fieldName,
        allowedMetaFields: [],
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .use(Compressor)
  );

  uppy.getPlugin('XHRUpload')?.setOptions({
    endpoint: `${API_URL}${endpoint}`
  });

  uppy.on('upload-progress', (_file, progress) => {
    const uploadProgress = (progress.bytesUploaded / progress.bytesTotal) * 100;
    onProgressChange(uploadProgress);
  });

  const cancelCropImage = () => {
    closeModal();
  };

  const uploadCroppedFile = async () => {
    try {
      closeModal();
      const res = await uppy.upload();
      if (res.successful.length > 0) {
        onUploadSuccess(res.successful[0].response?.body as ISong | undefined);
        setTimeout(() => {
          onProgressChange(0);
        }, 3000);
        notifications.success('Image uploaded successfully.');
      } else if (res.failed.length > 0) {
        notifications.error('Could not upload image');
        onProgressChange(0);
      }
      const files = uppy.getFiles();
      uppy.removeFile(files[0].id);
    } catch (error) {
      onProgressChange(0);
      notifications.error('Could not upload image');
    }
  };

  const footerBtns: ModalFooterBtn[] = [
    {
      key: 'Cancel',
      background: 'secondary',
      variant: 'outline',
      onClick: cancelCropImage,
      content: 'CANCEL'
    },
    {
      key: 'Update',
      disabled: isCropDisabled,
      loading: false,
      onClick: uploadCroppedFile,
      content: 'CONFIRM'
    }
  ];

  return (
    <Modal isCentered={true} footerBtns={footerBtns} name={CROP_AVATAR_MODAL} title={modalTitle}>
      <Dashboard
        hideUploadButton={true}
        hideCancelButton={true}
        proudlyDisplayPoweredByUppy={false}
        theme="dark"
        uppy={uppy}
        note="File types: JPG, JPEG, PNG. Upload limit: 500kb"
      />
    </Modal>
  );
};
