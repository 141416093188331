import { SVGAttributes } from 'react';

const DropboxIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3447_10153)">
        <path
          d="M6.97674 1.5L0 6.02093L4.85581 9.92791L11.8326 5.57442L6.97674 1.5ZM0 13.7233L6.97674 18.3L11.8326 14.2256L4.85581 9.92791L0 13.7233ZM11.8326 14.2256L16.7442 18.3L23.6651 13.7791L18.8651 9.92791L11.8326 14.2256ZM23.6651 6.02093L16.7442 1.5L11.8326 5.57442L18.8651 9.92791L23.6651 6.02093ZM11.8884 15.1186L6.97674 19.193L4.91163 17.7977V19.3605L11.8884 23.5465L18.8651 19.3605V17.7977L16.7442 19.193L11.8884 15.1186Z"
          fill="#007EE5"
        />
      </g>
      <defs>
        <clipPath id="clip0_3447_10153">
          <rect width="24" height="22.3256" fill="white" transform="translate(0 1.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

DropboxIcon.displayName = 'DropboxIcon';

export default DropboxIcon;
