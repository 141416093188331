import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useGetAccount, usePutAccount } from '@/hooks';
import { EditableIAccountPart } from '@/models/Account.ts';
import { setServerFormError } from '@/utils/serverTestValidation.ts';
import { ButtonLib, InputField, RichAvatar } from '@/components';
import { useModalStore } from '@/store';
import { DELETE_ACCOUNT_MODAL } from '../index.ts';

import styles from '../../../account.module.css';

const SCHEMA = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  username: Yup.string().required('Username is required')
});

export const ProfileForm: FC = () => {
  const { data: account } = useGetAccount();
  const { mutate: updateProfile, isLoading, error } = usePutAccount();

  const { handleSubmit, control, reset, formState, setError } = useForm<EditableIAccountPart>({
    resolver: yupResolver(SCHEMA)
  });

  const { openModal } = useModalStore();

  useEffect(() => {
    reset({
      first_name: account?.first_name,
      last_name: account?.last_name,
      email: account?.email,
      username: account?.username
    });
  }, [account, reset]);

  useEffect(() => {
    if (error) {
      setServerFormError(error, setError);
    }
  }, [error, setError]);

  const handleUpdateProfile = handleSubmit((values: EditableIAccountPart) => {
    updateProfile(values);
  });

  const handleResetChanges = () => {
    reset({
      first_name: account?.first_name,
      last_name: account?.last_name,
      email: account?.email,
      username: account?.username
    });
  };

  const handleOpenDeleteModal = () => {
    openModal({ name: DELETE_ACCOUNT_MODAL });
  };

  return (
    <form onSubmit={handleUpdateProfile}>
      <div className={styles.formContent}>
        <div className={styles.formInputWrapper}>
          <InputField name="first_name" label="First Name" placeholder="John" control={control} />

          <InputField name="last_name" label="Last Name" placeholder="Doe" control={control} />

          <InputField
            name="email"
            label="Contact Email"
            placeholder="john.doe@mail.com"
            control={control}
          />

          <InputField
            name="username"
            label="Username"
            placeholder="john.doe@mail.com"
            control={control}
          />
        </div>
        <RichAvatar />
      </div>

      <div className={styles.formFooter}>
        <button type="button" onClick={handleOpenDeleteModal}>
          Delete account
        </button>

        <div className={styles.formActions}>
          <ButtonLib
            background="secondary"
            variant="outline"
            onClick={handleResetChanges}
            disabled={!formState.isDirty}
          >
            Cancel
          </ButtonLib>

          <ButtonLib type="submit" loading={isLoading} disabled={!formState.isDirty}>
            Save
          </ButtonLib>
        </div>
      </div>
    </form>
  );
};
