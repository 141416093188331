export enum APPROVAL_STATUS {
  pending = 'pending',
  denied = 'denied',
  approved = 'approved',
  revoked = 'revoked',
  rejected = 'denied,revoked'
}

export const STATUSES = [
  {
    value: APPROVAL_STATUS.pending,
    label: 'PENDING'
  },
  {
    value: APPROVAL_STATUS.approved,
    label: 'APPROVED'
  },
  {
    value: APPROVAL_STATUS.rejected,
    label: 'DENIED'
  }
];
