import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks';
import { Plan } from '@/models/Plan.ts';

export const useGetPlans = (isYearly = false) => {
  const api = useApi({});

  return useQuery<Plan[], AxiosError>({
    queryKey: ['plans', isYearly],
    queryFn: async (): Promise<Plan[]> => {
      const response = await api.get<Plan[]>(`/v1/plans`);

      if (!response.data) {
        return [];
      }

      return response.data;
    }
  });
};

export const isNumber = (value: string | number): value is number => typeof value === 'number';
