import { HTMLProps } from 'react';
import { useGetAccount } from '@/hooks';
import { ModalCropAvatar } from '@/components/molecules/modalCropAvatar/ModalCropAvatar';

interface RichAvatarProps {
  avatarClassName?: HTMLProps<HTMLElement>['className'];
}

export const RichAvatar = ({ avatarClassName }: RichAvatarProps) => {
  const { data, refetch } = useGetAccount();
  const onSuccess = () => {
    void refetch();
  };

  return (
    <ModalCropAvatar
      fileFieldName="file"
      endpoint="/v1/account/avatar"
      onUploadSuccess={onSuccess}
      modalTitle="UPLOAD AVATAR"
      defaultSrcUrl={data?.avatar}
      circular={true}
      avatarClassName={avatarClassName}
    />
  );
};
