import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@/hooks';
import { useSongStore } from '@/store';
import { SongTrackProps } from '@/models/Song';
import { notifications } from '@/utils/notifications';

export const usePostSongTrack = () => {
  const api = useApi({ isFileIncluded: true });

  const {
    songData: { id: songId }
  } = useSongStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (formData: FormData): Promise<SongTrackProps[]> => {
      const response = await api.post<SongTrackProps[]>(`/v1/songs/${songId}/tracks`, formData);

      return response.data;
    },
    onSuccess: (tracks) => {
      if (tracks?.length) {
        void queryClient.setQueryData(['tracks', songId], (oldTracks?: SongTrackProps[]) => {
          if (oldTracks?.length) {
            return oldTracks.concat(tracks);
          }
          return tracks;
        });
      }
    },
    onError: () => {
      notifications.error("Couldn't update tracks");
    }
  });
};
