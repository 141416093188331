import { useQueryClient } from '@tanstack/react-query';
import { useSongStore } from '@/store';
import { useSongPermission } from '@/hooks';
import defaultSongImage from '@/assets/default-song-image.png';
import { ISong } from '@/models/Song';
import { SongQueryData, updateSongCache } from '@/utils/updateSongCache';
import { ModalCropAvatar } from '@/components';

const getEndpoint = (songId: string) => `/v1/songs/${songId}/image`;

export const SongImageUploader = () => {
  const { hasReadPermission } = useSongPermission();
  const {
    songData,
    setSongData,
    sortOptions,
    isSongEditable,
    setIsSongEditable,
    limit,
    isRequireLicense
  } = useSongStore();

  const queryClient = useQueryClient();
  const onSuccess = (updatedSong?: ISong) => {
    if (updatedSong) {
      setSongData(updatedSong);

      if (updatedSong.status !== 'release_scheduled') {
        setIsSongEditable(true);
      }

      queryClient.setQueryData<unknown>(
        ['songs', sortOptions.sortBy, sortOptions.sortOrder, limit, isRequireLicense],
        (oldData: SongQueryData) => updateSongCache(oldData, updatedSong)
      );
    }
  };

  return (
    <ModalCropAvatar
      fileFieldName="image"
      endpoint={getEndpoint(songData.id)}
      onUploadSuccess={onSuccess}
      modalTitle="ADD ARTWORK"
      defaultSrcUrl={songData?.image_url || defaultSongImage}
      disabled={hasReadPermission || !isSongEditable}
    />
  );
};
