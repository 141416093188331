import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { PlanNames } from '@/models/Plan.ts';

interface SubscriptionConfirmPlanProps {
  amount: number;
  name: PlanNames;
  interval: 'month' | 'year';
}

export interface SubscriptionModalOptions {
  selected: SubscriptionConfirmPlanProps & { id: string };
  current: SubscriptionConfirmPlanProps;
}

interface ModalOptionProps {
  name: string;
  [key: string]: string | number | SubscriptionModalOptions | undefined | boolean;
}

export type State = {
  modalOption: ModalOptionProps;
};

type Actions = {
  openModal: (modalOption: ModalOptionProps) => void;
  closeModal: () => void;
};

const INITIAL_VALUE = { name: '' };

export const useModalStore = create<State & Actions>()(
  immer(
    devtools((set) => ({
      modalOption: INITIAL_VALUE,
      openModal: (modalOption: ModalOptionProps) => set({ modalOption }),
      closeModal: () => set({ modalOption: INITIAL_VALUE })
    }))
  )
);
