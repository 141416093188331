import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { ISong, CollaborationsSongProps } from '@/models/Song';
import { PlaySongCell, SongStatusButton, CollaborationsStatusButton } from '@/components';
import { DEFAULT_DATE_FORMAT } from '@/constants';

import { ItemMenuCell } from './components';

const columnHelper = createColumnHelper<ISong>();
// eslint-disable-next-line  react-refresh/only-export-components
export const SONG_COLUMN = [
  columnHelper.accessor('name', {
    header: 'TITLE',
    cell: (info) => {
      const { original } = info.row;

      const data = {
        image_url: original.image_url,
        title: original.name,
        subtitle: original.display_artist,
        master_url: original.master_url,
        id: original.id
      };

      return <PlaySongCell data={data} />;
    },
    size: 45
  }),
  columnHelper.accessor('created_at', {
    header: 'DATE',
    cell: (info) => (
      <div className="text-hookybase-200 dark:text-hookybase-300">
        {dayjs(info.getValue()).format(DEFAULT_DATE_FORMAT)}
      </div>
    ),
    size: 25
  }),
  columnHelper.accessor('status', {
    header: 'STATUS',
    cell: (info) => <SongStatusButton data={info.row.original} />,
    size: 25
  }),
  columnHelper.accessor('id', {
    header: ' ',
    cell: (info) => <ItemMenuCell data={info.row.original} />,
    size: 5
  })
];

export const COLLABORATION_COLUMN = [
  columnHelper.accessor('name', {
    header: 'TITLE',
    cell: (info) => {
      const { original } = info.row;

      const data = {
        image_url: original.image_url,
        title: original.name,
        subtitle: original.display_artist,
        master_url: original.master_url,
        id: original.id
      };

      return <PlaySongCell data={data} />;
    },
    size: 45
  }),
  columnHelper.accessor('created_at', {
    header: 'DATE',
    cell: (info) => (
      <div className="text-hookybase-200 dark:text-hookybase-300">
        {dayjs(info.getValue()).format(DEFAULT_DATE_FORMAT)}
      </div>
    ),
    size: 25
  }),
  columnHelper.accessor('status', {
    header: 'STATUS',
    cell: (info) => (
      <CollaborationsStatusButton data={info.row.original as CollaborationsSongProps} />
    ),
    size: 25
  })
];
