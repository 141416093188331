import { AxiosError } from 'axios';
import { FieldError } from 'react-hook-form';

interface FormError {
  response: {
    data: {
      [k: string]: string;
    };
  };
}

export function setServerFormError<T>(
  error: unknown,
  setFormError: (name: keyof T, error: FieldError) => void
) {
  if ((error as { response: AxiosError }).response.status === 422) {
    Object.entries((error as FormError).response.data).forEach(([key, value]) => {
      setFormError(key as keyof T, { type: 'custom', message: value });
    });
  }
}
