import { useApi } from '@/hooks';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { usePlatformRulesAgreementStore, useTopNotificationBannerQueueStore } from '@/store';
import { notifications } from '@/utils/notifications';

export const useDeleteTracks = (): UseMutationResult<string, AxiosError, void> => {
  const api = useApi({});
  const queryClient = useQueryClient();
  const { removeBanner } = useTopNotificationBannerQueueStore();
  const { setHasAgreedTerms } = usePlatformRulesAgreementStore();

  return useMutation<string, AxiosError>({
    mutationFn: async (): Promise<string> => {
      const result = await api.delete('/v1/temporary-tracks');

      return result.data;
    },
    onSuccess: () => {
      void queryClient.setQueryData(['convert_track_status'], () => []);
      removeBanner('BANNER_DRAFT_PROJECT');
      notifications.success('Temporary tracks have been removed successfully');
      setHasAgreedTerms(false);
    },
    onError: () => {
      notifications.error("Something went wrong. Couldn't remove temporary tracks");
    }
  });
};
