import { FC } from 'react';
import { Control, useController } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { NumberInput } from '@mantine/core';

import { Slider } from './Slider.tsx';
import styles from './slider-base.module.css';

interface SliderControlProps {
  name: string;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  max?: number;
  thumbAlwaysShown?: boolean;
  valueUnits?: string;
  description?: string;
  descriptionTop?: string;
  maxValue?: number;
  maxLength?: number;
  defaultValue?: number;
}

export const SliderField: FC<SliderControlProps> = ({
  control,
  name,
  label,
  max,
  thumbAlwaysShown,
  valueUnits,
  description,
  descriptionTop,
  maxValue = 100,
  maxLength = 3,
  defaultValue = 0
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue
  });

  const handleChange = (value: number | string) => {
    if (maxValue && +value > maxValue) {
      field.onChange(maxValue);

      return;
    }

    field.onChange(value ? value : 0);
  };

  return (
    <div className="flex flex-col items-center-center">
      <div className="relative">
        <span
          className={twMerge(
            'text-sm font-semibold relative',
            !descriptionTop && 'absolute top-0 left-0'
          )}
        >
          {label}
        </span>
        {descriptionTop ? (
          <p className="mt-3 text-sm text-hookybase-200 mb-6">{descriptionTop}</p>
        ) : null}
      </div>
      <div className="flex flex-col items-center">
        <div className="flex flex-col justify-between items-end w-full max-w-[27rem]">
          {valueUnits ? (
            <div className={styles.inputBox}>
              <NumberInput
                className={styles.enterInput}
                onChange={handleChange}
                value={field.value}
                maxLength={maxLength}
                error={Boolean(fieldState.error)}
                allowNegative={false}
                allowLeadingZeros
                hideControls
                disabled={maxValue === 0}
              />
              <span>{valueUnits}</span>
            </div>
          ) : null}

          <Slider
            {...field}
            disabled={maxValue === 0}
            max={max}
            maxValue={maxValue}
            thumbAlwaysShown={thumbAlwaysShown}
          />
          {description ? (
            <p className="mt-4 self-start text-xs text-hookybase-200">{description}</p>
          ) : null}
          {fieldState.error ? (
            <p className="self-start text-xs text-hookyred-500">{fieldState.error.message}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};
