import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '@/hooks';
import { notifications } from '@/utils/notifications';
import { useApprovalStore } from '@/store';
import { APPROVAL_STATUS } from '@/pages/Approvals/constants';

interface DenyRequest {
  id: string;
  values: {
    reason: string;
    description?: string;
  };
}

export const useDenySong = () => {
  const api = useApi({});
  const queryClient = useQueryClient();
  const { limit, status } = useApprovalStore();

  return useMutation({
    mutationFn: async ({ id, values }: DenyRequest): Promise<void> => {
      const response = await api.post(`/v1/approvals/${id}/deny`, values);

      return response.data;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['approvals', limit, status]
      });
      void queryClient.invalidateQueries({
        queryKey: ['approvals', limit, APPROVAL_STATUS.denied]
      });
    },
    onError: () => {
      notifications.error("Couldn't deny song");
    }
  });
};
