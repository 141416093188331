export const StatusCell = ({ children }: { children: string }) => {
  return (
    <div
      data-content="cell-split"
      className="flex justify-center items-center dark:text-white text-hookybase-300 text-xs leading-6 bg-transparent border border-hookybase-300 rounded-full"
    >
      {children}
    </div>
  );
};
