import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { Approval } from '@/models/Approval';
import { DEFAULT_LOADING_LIMIT_ITEM } from '@/constants';
import { APPROVAL_STATUS } from '@/pages/Approvals/constants';

export type State = {
  approval: Approval;
  limit: number;
  status: string;
};

type Actions = {
  setApprovalData: (approval: Approval) => void;
  setApprovalStatus: (status: string) => void;
};

const INITIAL_VALUE: State = {
  approval: {} as Approval,
  limit: DEFAULT_LOADING_LIMIT_ITEM,
  status: APPROVAL_STATUS.pending
};

export const useApprovalStore = create<State & Actions>()(
  immer(
    devtools((set) => ({
      ...INITIAL_VALUE,
      setApprovalData: (approval) => set({ approval }),
      setApprovalStatus: (status) => set({ status })
    }))
  )
);
