export enum ROUTES {
  ACCOUNT = '/account',
  ACCOUNT_HOME = '/account/my-account',
  ACCOUNT_PROFILE = '/account/profile',
  ACCOUNT_SUBSCRIPTION = '/account/subscription',
  ACCOUNT_PAYMENT_DETAILS = '/account/payment-details',
  ACCOUNT_SECURITY = '/account/security',
  ACCOUNT_CHANGE_PASSWORD = '/account/security/change-password',
  ACCOUNT_DELETE = '/account/delete',
  APPROVALS = '/approvals',
  ARTISTS = '/artists',
  CREATE = '/create',
  DASHBOARD = '/',
  PROJECTS = '/projects',
  PROJECTS_COLLABORATIONS = '/projects/collaborations',
  PROJECTS_LIBRARY = '/projects/library',
  MODELS = '/models',
  MY_MODELS = '/models/my-models',
  MODELS_SHARED = '/models/shared',
  COLLABORATIONS = '/collaborations',
  RELEASES = '/releases',
  TRAIN_MODEL = '/train-model',
  SITE_TERMS = '/terms',
  PRIVACY_POLICY = '/terms/privacy-policy',
  TERMS_OF_USE = '/terms/terms-of-use',
  SUBSCRIPTIONS_TERMS = '/terms/subscription-terms',
  COOKIE_POLICY = '/terms/cookie-policy',
  NOT_AUTHORIZED = '/not-authorized'
}
