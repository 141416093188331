import { ReactNode } from 'react';
import { LoadingOverlay } from '@mantine/core';

import { ButtonLib } from '@/components';
import { useGetCardDetail } from '@/hooks';
import { AmexIcon, LockIcon, MasterCardIcon, VisaIcon } from '@/assets/icons';

import { PAYMENT_CONTENT_MODE } from '../../constants';
import styles from '../../../account.module.css';

interface ViewPaymentCardProps {
  onChangeMode: (mode: string) => void;
}

const CART_ICON: { [k: string]: ReactNode } = {
  Visa: <VisaIcon />,
  MasterCard: <MasterCardIcon />,
  'American Express': <AmexIcon />
};
const LOADING_OVERLAY_PROPS = { color: 'var(--mantine-color-dark-6)', backgroundOpacity: 1 };

export const ViewPaymentCard = (props: ViewPaymentCardProps) => {
  const { onChangeMode } = props;
  const { data, isLoading } = useGetCardDetail();

  const handleChangeMode = () => {
    onChangeMode(PAYMENT_CONTENT_MODE.EDIT);
  };

  if (isLoading) {
    return <LoadingOverlay visible overlayProps={LOADING_OVERLAY_PROPS} />;
  }

  return (
    <>
      <div className={styles.titleSmall}>Saved card</div>

      <div className={styles.viewCard}>
        <span className={styles.viewCardInfo}>
          {data?.brand ? CART_ICON[data.brand] : null}

          <span>
            •••• •••• •••• {data?.last4} | {data?.exp_month}/{data?.exp_year}
          </span>
        </span>

        <LockIcon />
      </div>

      <div className={`${styles.formActions} ${styles.formFooter}`}>
        <div />

        <ButtonLib
          background="secondary"
          variant="outline"
          data-mode={PAYMENT_CONTENT_MODE.EDIT}
          onClick={handleChangeMode}
        >
          CHANGE CARD
        </ButtonLib>
      </div>
    </>
  );
};
