import React from 'react';
import { Control, useController } from 'react-hook-form';
import { NumberInput } from '@mantine/core';

import styles from './input.module.css';
import { INPUT_WRAPPER_ORDER } from './constant.ts';

interface NumberInputProps {
  name: string;
  label?: string | React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  placeholder?: string;
  description?: string;
  hideNumberControl?: boolean;
  maxLength?: number;
}

const CLASSES = {
  root: styles.root,
  wrapper: styles.wrapper,
  input: styles.input,
  label: styles.label,
  error: styles.error,
  description: styles.description
};

export const NumberInputField = (props: NumberInputProps) => {
  const {
    name,
    label,
    control,
    hideNumberControl = true,
    maxLength,
    placeholder = '',
    description
  } = props;
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: ''
  });

  const handleChange = (value: number | string) => {
    field.onChange(value);
  };

  return (
    <NumberInput
      {...field}
      value={field.value ? field.value : null}
      label={label}
      classNames={CLASSES}
      placeholder={placeholder}
      description={description}
      onChange={handleChange}
      hideControls={hideNumberControl}
      maxLength={maxLength}
      error={fieldState.error ? fieldState.error.message : null}
      inputWrapperOrder={INPUT_WRAPPER_ORDER}
      size="lg"
    />
  );
};
