import { FC } from 'react';
import { ButtonLib, Typo } from '@/components';

interface NoDataComponentProps {
  buttonTitle: string;
  description: string;
  onClick: () => void;
  disabled?: boolean;
}

export const NoDataComponent: FC<NoDataComponentProps> = ({
  buttonTitle,
  onClick,
  disabled = false,
  description
}) => {
  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col items-center max-w-sm mb-1">
        <Typo.h4 className="mb-4 font-semibold">Let’s create something new</Typo.h4>

        <Typo.p className="mb-4 dark:text-hookybase-200">{description}</Typo.p>

        <ButtonLib
          background="secondary"
          variant="outline"
          size="xs"
          type="button"
          disabled={disabled}
          onClick={onClick}
        >
          {buttonTitle}
        </ButtonLib>
      </div>
    </div>
  );
};
