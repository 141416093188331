import { VoiceModelStatus, VoiceModelVisibility, VoiceModelTrack } from '@/models/VoiceModel.ts';

export const TAB_NAME = {
  MODEL_INFO: 'MODEL_INFO',
  SHARED: 'SHARED'
};

export const MODEL_STATUS: Record<VoiceModelStatus, string> = {
  published: 'Published',
  request_submitted: 'Request Submitted',
  archived: 'Archived'
};

export const getVisibilityColor = (visibility: VoiceModelVisibility) => {
  switch (visibility) {
    case 'private':
      return 'dark:text-hookygreen-500';
    case 'public':
      return 'dark:text-hookyyellow-500';
  }
};

export const getVoiceModelTracksMeta = async (tracks: FileList): Promise<VoiceModelTrack[]> => {
  return new Promise((metaDataResolve, reject) => {
    try {
      const promises: Promise<VoiceModelTrack>[] = [];
      [...tracks].forEach((file) => {
        const audio = new Audio(URL.createObjectURL(file));
        promises.push(
          new Promise((resolve) => {
            audio.addEventListener('loadedmetadata', () => {
              resolve({
                file,
                metadata: {
                  duration: audio.duration,
                  size: file.size,
                  name: file.name
                }
              });
            });
          })
        );
      });

      Promise.all(promises).then((results) => {
        metaDataResolve(results);
      });
    } catch (error) {
      reject(error);
    }
  });
};
