import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks';
import { Subscription } from '@/models/Subscription.ts';

export const useGetSubscription = () => {
  const api = useApi({});

  return useQuery<Subscription, AxiosError>({
    queryKey: ['subscription'],
    queryFn: async (): Promise<Subscription> => {
      const response = await api.get<Subscription>(`/v1/subscription`);

      return response.data;
    },
    retry: 5
  });
};
