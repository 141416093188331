import React from 'react';
import { twMerge } from 'tailwind-merge';

export interface LogoProps {
  className?: string;
  expand?: boolean;
  noSpacing?: boolean;
}

export const Logo: React.FC<LogoProps> = ({
  className = '',
  expand = false,
  noSpacing = false
}) => {
  return expand ? (
    <svg
      data-testid="expanded-logo"
      className={twMerge(
        'w-full text-hookybase-500 dark:text-hookybase-100',
        !noSpacing && 'px-5 mt-3 mb-7',
        className
      )}
      viewBox="0 0 116 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_196_8777)">
        <path
          d="M13.43 31.5728V17.8322C13.43 15.1643 12.3866 13.2963 10.04 13.2963C9.47515 13.2963 8.64925 13.4743 7.99735 13.8297L3.21658 16.5421V31.5722H0V0.00012207H3.21598V14.0078L8.56225 10.1833C8.95315 10.0498 9.43165 10.0053 9.77904 10.0053C14.4728 10.0053 16.646 12.8512 16.646 16.7647V31.5728H13.43Z"
          fill="currentColor"
        />
        <path
          d="M91.3573 31.5727L82.969 22.5009H82.3606V31.5727H79.1446V6.10352e-05H82.3606V19.5215H82.795L91.1404 10.4942H95.2257L85.7071 20.8109L95.6601 31.5721H91.3573V31.5727Z"
          fill="currentColor"
        />
        <path
          d="M102.613 40.9999L105.699 31.5727H104.221L97.2237 10.4948H100.57L106.394 28.6378H106.829L112.653 10.4948H116L105.916 40.9999H102.613Z"
          fill="currentColor"
        />
        <path
          d="M73.3948 22.3958C73.3948 14.6394 67.2492 8.35236 59.6681 8.35236C49.5344 8.35236 46.3704 18.9287 41.1129 18.9287C37.1895 18.9287 34.9933 14.0405 29.7389 14.0405C25.2282 14.0405 21.5718 17.813 21.5718 22.3965C21.5718 26.98 25.2282 30.7815 29.7389 30.7815C34.9933 30.7815 37.1895 25.8933 41.1129 25.8933C46.3698 25.8933 49.5344 36.4696 59.6681 36.4696C67.2492 36.4696 73.3948 30.1529 73.3948 22.3971V22.3958Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_196_8777">
          <rect width="116" height="41" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      data-testid="collapsed-logo"
      className={twMerge('w-full px-0 text-hookybase-500 dark:text-hookybase-100', className)}
      viewBox="0 0 53 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_196_8772)">
        <g mask="url(#mask0_196_8772)">
          <path
            d="M52.9998 13.9855C52.9998 6.26155 46.7147 0 38.9617 0C28.5977 0 25.3617 10.5323 19.9852 10.5323C15.9724 10.5323 13.7262 5.66445 8.35255 5.66445C3.73994 5.66445 0.000183105 9.42148 0.000183105 13.9855C0.000183105 18.5496 3.73946 22.3356 8.35255 22.3356C13.7262 22.3356 15.9724 17.4677 19.9852 17.4677C25.3617 17.4677 28.5977 28 38.9617 28C46.7147 28 52.9998 21.7095 52.9998 13.9855Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_196_8772">
          <rect width="53" height="28" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
