import { FC, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import { useMantineColorScheme, Tooltip, useMantineTheme, Avatar } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { ROUTES } from '@/routes/routes';
import { ThemeContext } from '@/providers/ThemeContext';
import { useGetAccount, useGetSubscription } from '@/hooks';
import { IAccount } from '@/models/Account';
import { useNavStore } from '@/store';
import { Menu, Icon, MenuOptionProps, ButtonLib } from '@/components';

import styles from './user-bar.module.css';
import { HELP_CENTER_URL } from '@/config';

export interface Notification {
  id: string;
  title: string;
  description: string;
  date: string;
}

export interface UserBarProps {
  notifications?: Notification[];
  isHideWelcomeMessage?: boolean;
  handleBackClick?: () => void;
  isBackArrowShown?: boolean;
}

// TODO - Create context providers for user and notifications

const getUserName = (accountData?: IAccount): string => {
  if (accountData) {
    return accountData.display_name || `${accountData.first_name} ${accountData.last_name}`;
  }
  return '';
};

export const UserBar: FC<UserBarProps> = ({
  isHideWelcomeMessage,
  handleBackClick,
  isBackArrowShown = false
}) => {
  const auth = useAuth();
  const { breakpoints } = useMantineTheme();
  const md = useMediaQuery(`(min-width: ${breakpoints.md})`) ?? false;
  const isNavOpen = useNavStore((state) => state.isNavOpen);
  const setIsNavOpen = useNavStore((state) => state.setIsNavOpen);
  const { data: accountData, isLoading, isSuccess } = useGetAccount();
  const { data: subscription, error } = useGetSubscription();
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setColorScheme } = useMantineColorScheme();

  const isNoCredits = subscription && subscription.remaining_credits === 0;

  useEffect(() => {
    setColorScheme(theme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      error?.response?.status === 404 &&
      pathname !== ROUTES.ACCOUNT_SUBSCRIPTION &&
      pathname !== ROUTES.ACCOUNT_PAYMENT_DETAILS
    ) {
      navigate(ROUTES.ACCOUNT_SUBSCRIPTION);
    }
  }, [error, navigate, pathname]);

  const menuOptions: MenuOptionProps[] = [
    {
      label: getUserName(accountData).toUpperCase(),
      isLabel: true
    },
    {
      label: 'Account',
      onClick: () => navigate(ROUTES.ACCOUNT)
    },
    {
      label: 'Security',
      onClick: () => navigate(ROUTES.ACCOUNT_CHANGE_PASSWORD)
    },
    {
      label: 'Help',
      onClick: () => window.open(HELP_CENTER_URL, '_blank')
    },
    // TODO: Add color theme handler option here (post MVP)
    {
      hasDivider: true,
      label: 'Sign Out',
      onClick: () => auth.signOutRedirect()
    }
  ];

  const toggleNavBar = () => {
    setIsNavOpen(!isNavOpen);
  };

  const onBuyMoreCredits = () => {
    if (isNoCredits) {
      navigate(ROUTES.ACCOUNT_SUBSCRIPTION);
    }
  };

  return (
    <div className="w-full flex justify-between items-center text-hookybase-300 text-xs pt-4 md:pt-6 mb-4 px-4 md:mb-6 md:px-8">
      {isHideWelcomeMessage ? (
        <div />
      ) : (
        <>
          {isLoading && <Icon icon="mdi:loading" className="animate-spin" />}
          {isSuccess && (md || !(isBackArrowShown && !md)) && (
            <div className="uppercase">
              Welcome back{' '}
              <span className="text-black dark:text-white">{getUserName(accountData)}</span>
            </div>
          )}
          {isBackArrowShown && !md && (
            <button type="button" onClick={handleBackClick}>
              <Icon size="xl" icon="ArrowBack" />
            </button>
          )}
        </>
      )}

      <div className="group icons flex gap-2">
        {subscription && accountData && !accountData.admin ? (
          <Tooltip label={isNoCredits ? 'Buy More credits' : 'Time remaining'}>
            <div className={styles.creditsBtn}>
              <ButtonLib
                leftSection={<Icon size="sm" icon="Lock" />}
                data-disabled={!isNoCredits}
                size="xs"
                onClick={onBuyMoreCredits}
              >
                {subscription.plan_credits === -1 ? 'Infinity' : subscription.remaining_credits}
              </ButtonLib>
            </div>
          </Tooltip>
        ) : null}
        {isSuccess && (
          <Menu options={menuOptions}>
            <button type="button">
              {accountData?.avatar ? (
                <Avatar size="24px" src={accountData?.avatar} alt={accountData.display_name} />
              ) : (
                <Icon size="xl" icon="Account" />
              )}
            </button>
          </Menu>
        )}
        <button className="block md:hidden h-[1.875rem]" type="button">
          <Icon size="xl" icon={isNavOpen ? 'Close' : 'Menu'} onClick={toggleNavBar} />
        </button>
      </div>
    </div>
  );
};
