import { ModalFooterBtn, Modal } from '@/components';
import { useModalStore } from '@/store';

export const DELETE_ACCOUNT_CONFIRMED_MODAL = 'DELETE_ACCOUNT_CONFIRMED_MODAL';

export const DeleteAccountConfirmedModal = () => {
  const { closeModal } = useModalStore();
  const handleDeleteAccountConfirmed = () => {
    closeModal();
  };
  const footerBtns: ModalFooterBtn[] = [
    {
      key: 'confirm',
      onClick: handleDeleteAccountConfirmed,
      content: 'CLOSE'
    }
  ];
  return (
    <Modal
      name={DELETE_ACCOUNT_CONFIRMED_MODAL}
      title="Delete Account Confirmed"
      footerBtns={footerBtns}
      size="750px"
      isCentered
    >
      <p>
        Your account deletion request has been submitted, a member of the Hooky team will process
        your request
      </p>
    </Modal>
  );
};
