import { Typo } from '@/components';

import styles from '../site-terms.module.css';

export const CookiePolicy = () => {
  return (
    <div className="mb-4">
      <div className={styles.titleBox}>
        <Typo.h1 className="mb-2">Hooky Cookies Policy</Typo.h1>
      </div>

      <div className={`${styles.box} ${styles.content}`}>
        <p>
          At Hooky ("we," "us," "our"), we believe in being clear and open about how we collect and
          use data related to you. In the spirit of transparency, this Cookies Policy provides
          detailed information about how and when we use cookies on our website and platform.
        </p>

        <h2>What are cookies?</h2>

        <p>
          Cookies are small text files that are stored on your computer or mobile device when you
          visit a website. They are widely used to make websites work, or work more efficiently, as
          well as to provide reporting information and assist with service or advertising
          personalization.
        </p>

        <h2>Why does Hooky use cookies?</h2>

        <p>Hooky uses cookies and similar technologies for several purposes, including:</p>

        <ul>
          <li>
            <p>
              <b>Technical: </b> To enable the operation of our platform, such as web session
              management, navigation, and enhancing your user experience.
            </p>
          </li>

          <li>
            <p>
              <b>Preferences: </b> To remember your settings and preferences, like your language
              choice and communication preferences.
            </p>
          </li>

          <li>
            <p>
              <b>Analytics: </b> To collect information about how you interact with our services,
              which helps us improve our platform and services.
            </p>
          </li>

          <li>
            <p>
              <b>Marketing: </b> To deliver advertisements more relevant to you and your interests,
              as well as to limit the number of times you see an advertisement and measure the
              effectiveness of advertising campaigns.
            </p>
          </li>
        </ul>

        <h2>Types of cookies we use</h2>

        <ul>
          <li>
            <p>
              <b>Strictly Necessary Cookies:</b> These cookies are essential for you to browse our
              website and use its features.
            </p>
          </li>

          <li>
            <p>
              <b>Performance and Analytics Cookies:</b> These cookies collect information about how
              you use our website, for instance, which pages you visit most often.
            </p>
          </li>

          <li>
            <p>
              <b>Functionality Cookies:</b> These cookies allow our website to remember choices you
              make and provide enhanced, more personal features.
            </p>
          </li>

          <li>
            <p>
              <b>Advertising Cookies:</b> These cookies are used to make advertising messages more
              relevant to you and your interests.
            </p>
          </li>
        </ul>

        <h2>Your choices regarding cookies</h2>

        <p>
          You have the right to decide whether to accept or reject cookies. You can set or amend
          your web browser controls to accept or refuse cookies. If you choose to reject cookies,
          you may still use our website though your access to some functionality and areas of our
          website may be restricted.
        </p>

        <p>
          For more information on how you can customize your browser's cookie settings, please see
          the links below:
        </p>

        <ul>
          <li>
            <p>
              <b>Google Chrome:</b>
              {` `}
              <a href="https://support.google.com/chrome/answer/95647" target="_blank">
                Delete, allow and manage cookies in Chrome - Computer - Google Chrome Help
              </a>
            </p>
          </li>

          <li>
            <p>
              <b>Mozilla Firefox:</b>
              {` `}
              <a
                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                target="_blank"
              >
                Enhanced Tracking Protection in Firefox for desktop | Firefox Help
              </a>
            </p>
          </li>

          <li>
            <p>
              <b>Apple Safari:</b>
              {` `}
              <a
                href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                target="_blank"
              >
                Clear cookies in Safari on Mac
              </a>
            </p>
          </li>

          <li>
            <p>
              <b>Microsoft Internet Explorer:</b>
              {` `}
              <a
                href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                target="_blank"
              >
                Delete and manage cookies - Microsoft Support
              </a>
            </p>
          </li>
        </ul>

        <h2>Changes to this Cookies Policy</h2>

        <p>
          We may update this Cookies Policy from time to time in response to changing legal,
          technical, or business developments. When we update our Cookies Policy, we will take
          appropriate measures to inform you, consistent with the significance of the changes we
          make.
        </p>

        <h2>Contact us</h2>

        <p>
          If you have any questions or concerns about our use of cookies or this Cookies Policy,
          please contact us at <a href="mailto:support@hooky.co">support@hooky.co</a>.
          <br />
          Thank you for choosing Hooky.
        </p>
      </div>
    </div>
  );
};
