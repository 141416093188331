import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { ISong } from '@/models/Song';
import { SortOptionsProps } from '@/models/common';
import { SORT_ORDERS, DEFAULT_LOADING_LIMIT_ITEM } from '@/constants';
import { TAB_NAME } from '@/constants/song';

export type State = {
  songData: ISong;
  sortOptions: SortOptionsProps;
  songActiveTab: string;
  isSongEditable: boolean;
  isRequireLicense: boolean;
  limit: number;
};

type Actions = {
  setSongData: (song: ISong) => void;
  setSortOptions: (options: SortOptionsProps) => void;
  setActiveSongTab: (tabName: string) => void;
  setIsSongEditable: (value: boolean) => void;
  setIsRequireLicense: (value: boolean) => void;
};

const INITIAL_VALUE: State = {
  songData: {} as ISong,
  sortOptions: {
    sortBy: 'status',
    sortOrder: SORT_ORDERS.ASC
  },
  songActiveTab: TAB_NAME.vocalAIVocal,
  isSongEditable: true,
  isRequireLicense: true,
  limit: DEFAULT_LOADING_LIMIT_ITEM
};

export const useSongStore = create<State & Actions>()(
  immer(
    devtools((set) => ({
      ...INITIAL_VALUE,
      setSongData: (song) => set({ songData: song }),
      setSortOptions: (options) => set({ sortOptions: options }),
      setActiveSongTab: (tabName) => set({ songActiveTab: tabName }),
      setIsSongEditable: (isSongEditable) => set({ isSongEditable }),
      setIsRequireLicense: (isRequireLicense) => set({ isRequireLicense })
    }))
  )
);
