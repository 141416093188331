import { ChangeEvent } from 'react';
import { Control, useController } from 'react-hook-form';
import { PasswordInput } from '@mantine/core';
import { Icon } from '@iconify/react';
import { twMerge } from 'tailwind-merge';

import styles from '../input/input.module.css';

interface InputPasswordProps {
  name: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  placeholder?: string;
  isCriteriaVisible?: boolean;
}

const REQUIREMENTS = [
  {
    label: 'Min 8 characters',
    regx: /(?=.{8,})/
  },
  {
    label: 'Min 1 uppercase',
    regx: /(?=.*[A-Z])/
  },
  {
    label: 'Min 1 lowercase',
    regx: /(?=.*[a-z])/
  },
  {
    label: 'Min 1 number',
    regx: /(?=.*[0-9])/
  },
  {
    label: 'Min 1 special character',
    regx: /(?=.*[!@#$%^&*])/
  }
];

const CLASSES = {
  wrapper: styles.wrapper,
  input: styles.input,
  label: styles.label,
  error: styles.error
};

export const InputPasswordField = (props: InputPasswordProps) => {
  const { name, label, control, placeholder = '', isCriteriaVisible } = props;
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: ''
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.trim();

    field.onChange(event);
  };

  return (
    <div>
      <PasswordInput
        {...field}
        label={label}
        classNames={CLASSES}
        placeholder={placeholder}
        size="lg"
        onChange={handleChange}
        error={fieldState.error ? fieldState.error.message : null}
        visibilityToggleIcon={visibilityToggleIcon}
      />

      {isCriteriaVisible ? <PasswordRequirements value={field.value} /> : null}
    </div>
  );
};

function visibilityToggleIcon({ reveal }: { reveal: boolean }) {
  return <Icon icon={reveal ? 'mdi:eye' : 'mdi:eye-off'} className="text-2xl" />;
}

function PasswordRequirements({ value }: { value: string }) {
  if (!value) {
    return null;
  }

  return (
    <div className="mt-4 rounded bg-gray-200 dark:bg-hookybase-400 text-xs leading-[1.375rem] px-4 pt-2 pb-3">
      <div className="text-hookybase-200 mb-1">Password requirements</div>

      <ul className="mt-3">
        {REQUIREMENTS.map((requirement, index) => {
          const isValid = requirement.regx.test(value);

          return (
            <li
              key={index}
              className={twMerge(
                'flex items-center p-0 text-white',
                isValid ? 'text-hookygreen-500' : 'text-hookyred-500'
              )}
            >
              <Icon
                icon={isValid ? 'mdi:check' : 'radix-icons:dot-filled'}
                className="flex-0 text-base mr-2"
              />
              <div className="flex-1 min-w-0">{requirement.label}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
