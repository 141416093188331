import { FC } from 'react';
import { Progress } from '@mantine/core';
import styles from './ProgressBar.module.css';

interface ProgressBarProps {
  value: number;
}

const CLASSES = {
  root: styles.root,
  section: styles.section
};

export const ProgressBar: FC<ProgressBarProps> = ({ value }) => (
  <div className="flex flex-col">
    <Progress value={value} classNames={CLASSES} />
    <p className="text-end text-xs text-hookybase-300">
      COMPLETE&nbsp;<span className="text-hookyyellow-500">{value}%</span>
    </p>
  </div>
);
