import { SVGAttributes } from 'react';

const ClockIcon = ({ fill = 'currentColor', ...rest }: SVGAttributes<SVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...rest}>
      <path
        fill={fill}
        d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m.5 11H11V7h1.5v4.3l3.7-2.1l.8 1.3z"
      />
    </svg>
  );
};

ClockIcon.displayName = 'ClockIcon';

export default ClockIcon;
