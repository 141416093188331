import { Typo } from '@/components';

import styles from '../site-terms.module.css';
import { Link } from 'react-router-dom';
import { ROUTES } from '@/routes/routes.tsx';

export const TermsService = () => {
  return (
    <div className="mb-4">
      <div className={styles.titleBox}>
        <Typo.h1 className="mb-2">Terms of Service</Typo.h1>
      </div>

      <div className={`${styles.box} ${styles.content}`}>
        <p>EFFECTIVE DATE: 4 June, 2024</p>

        <p>
          Please read these Hooky Terms of Service (the “<b>Terms</b>”) carefully before using the
          Hooky Platform and Services (as defined below). These Terms constitute a legal agreement
          between Hooky AI, Inc. (“<b>Hooky</b>,” “<b>We</b>,” or “<b>Us</b>”) and you (referred to
          herein as “You”) with respect to the use of the Hooky Platform and Services. These Terms
          govern your access to and use of the Hooky Platform and Services. By creating an account
          through the Hooky Platform, you accept and agree to be bound by all of the terms and
          conditions set forth in these Terms. If you do not agree to be bound by these Terms, then
          you may not use the Hooky Platform and Services and you are prohibited from accessing,
          using, or transacting on the Hooky Platform and Services.
        </p>

        <p>
          BY CREATING AN ACCOUNT, AND/OR USING THE HOOKY PLATFORM AND SERVICES, OR OTHERWISE
          AFFIRMATIVELY AGREEING TO THESE TERMS, YOU AGREE TO BE BOUND BY THESE TERMS AND YOU
          REPRESENT AND WARRANT TO HOOKY THAT YOU HAVE READ THESE TERMS, UNDERSTAND THEM, AND AGREE
          TO BE BOUND BY THEM.
        </p>

        <p>
          <b>
            <u>NOTICE REGARDING ARBITRATION AND DISPUTE RESOLUTION</u>
          </b>
          : YOU AND HOOKY AGREE THAT ANY FUTURE DISPUTES BETWEEN YOU AND HOOKY WILL BE RESOLVED BY
          BINDING, INDIVIDUAL ARBITRATION, UNLESS YOU OPT-OUT IN ACCORDANCE WITH SECTION 17. UNLESS
          YOU OPT-OUT OF ARBITRATION, YOU ARE WAIVING YOUR RIGHT TO A TRIAL BY JURY OR TO
          PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION, CLASS
          ARBITRATION, OR REPRESENTATIVE PROCEEDING, AND YOU WILL ONLY BE PERMITTED TO SEEK RELIEF
          (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS. IF YOU
          WISH TO OPT OUT OF ARBITRATION, FOLLOW THE OPT-OUT PROCEDURE SPECIFIED IN SECTION 17
          BELOW.
        </p>

        <ul>
          <li>
            <b>Description of the Hooky Platform and Services</b>. Hooky utilizes generative
            artificial intelligence (AI) tools, methods and technologies (“
            <b>Hooky AI Technology</b>”) and owns and operates a proprietary generative artificial
            intelligence (AI) platform (the “Hooky Platform”), which enables you, in connection with
            sound recordings supplied by you, including without limitation any and all of the
            component tracks or stems supplied by you (each, a “<b>Creator Recording</b>”), to
            enhance, augment and otherwise modify your Creator Recordings. This includes the
            opportunity for you to engage with participating voice artists (each, a “
            <b>Voice Artist</b>”) for the purpose of augmenting and enhancing your Creator
            Recordings with the distinct and recognizable characteristics of a Voice Artist’s voice
            through use of the AI voice model of the Voice Artist as made available on the Hooky
            Platform to create a new, final, complete augmented musical sound recording (each, a “
            <b>New AI Voice Artist Track</b>”), the process for which (including engagement with and
            required approval from the appliable Voice Artist) is further described below. Among
            other tools, methods and/or technologies made available via the Hooky Platform, you will
            also have the ability to augment and enhance your Creator Recordings utilizing stock
            voices or voices that otherwise do not require engagement and approval for use of the
            voice (each such voice, a “<b>Stock Voice</b>”). You may also be given the opportunity
            to serve as a Voice Artist on the Hooky Platform; the terms for which are set forth in a
            separate agreement that you would enter into via the Hooky Platform if you are
            interested in serving as a Voice Artist (such agreement, the “
            <b>Voice Artist Agreement</b>”). The Hooky AI Technology, the Hooky Platform, and the
            foregoing related services provided by Hooky are referred to herein as the “
            <b>Hooky Platform and Services</b>”.
          </li>

          <li>
            <b>Updates to these Terms</b>. We may change, modify or amend these Terms from time to
            time. We will notify you of material changes to these Terms by posting the amended terms
            on the Hooky Platform at least thirty (30) days before the effective date of the
            changes. In addition, you will be required to affirmatively accept the new Terms the
            first time you access your User Account (as defined in Section 5 below) after the new
            Terms take effect. If you do not agree with the proposed changes, you should discontinue
            your use of the Hooky Platform and Services prior to the time the new Terms take effect.
            If you continue using the Hooky Platform and Services after the new Terms take effect,
            you will be bound by the modified Terms.
          </li>

          <li>
            <b>Privacy Policy</b>. In connection with your use of the Hooky Platform and Services,
            please review our Privacy Policy, located at{' '}
            <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>, to understand how we use
            information we collect from you when you access, visit, or use the Hooky Platform and
            Services. The Privacy Policy is part of and is governed by these Terms and by agreeing
            to these Terms, you agree to be bound by the terms of the Privacy Policy and agree that
            we may use information collected from you in accordance with its terms.
          </li>

          <li>
            <b>Affirmative Representations Regarding Your Use of the Hooky Platform and Services</b>
            . When you use the Hooky Platform and Services, you represent that: (a) the information
            you submit to the Hooky Platform is truthful and accurate; (b) your use of the Hooky
            Platform and Services does not violate any applicable laws or regulations; and (c) you
            are at least eighteen (18) years of age (or the age of majority in your jurisdiction, if
            older), and otherwise have legal capacity to legally enter into these Terms.
          </li>

          <li>
            <b>Account Creation</b>. In order to access and use the Hooky Platform and Services, you
            must create a User account (a “<b>User Account</b>”) and related log-in credentials. By
            providing Hooky with the requested information in connection with the creation of your
            User Account, you represent and warrant that the applicable information is true and
            accurate. You shall inform Hooky if any applicable information changes following the
            time you create your User Account. Hooky shall have the right to refuse to create a User
            Account if Hooky determines in its discretion that you are not eligible to use the Hooky
            Platform and Services. You are responsible for keeping your account details secure.
            Hooky assumes no responsibility or liability for any loss that you may sustain due to
            the compromise of your User Account or related log-in credentials. In the event you
            believe your User Account has been compromised, please contact Hooky customer support
            immediately at <b>security@hooky.co</b>
          </li>

          <li>
            <b>Engagement with Voice Artists.</b>

            <ul>
              <li>
                <i>
                  <b>Approval of New AI Voice Artist Tracks</b>. The Hooky Platform lists the Voice
                  Artists who are participating in the Hooky Platform and Services and allows you to
                  select the Voice Artist(s) that you would like to use for the creation of an
                  applicable New AI Voice Artist Track. At the time you select the applicable Voice
                  Artist, you will be provided with the terms that will be applicable to your use of
                  that Voice Artist’s voice if the New AI Voice Artist Track is approved by the
                  applicable Voice Artist in accordance with this Section 6(a), such as the
                  allocation of copyright ownership splits, royalty splits, and Voice Artist name
                  for purposes of credit and identification. Once you have selected an applicable
                  Voice Artist, the Hooky Platform will then allow you to create a New AI Voice
                  Artist Track through the following process: (i) you upload your voice demo
                  recording, (ii) the Hooky Platform runs the voice demo recording through the AI
                  voice model for the selected Voice Artist and produces an AI generated audio voice
                  stem file that incorporates the distinct and recognizable characteristics of the
                  Voice Artist’s voice (each AI generated audio voice stem file, an “
                  <b>AI Voice Stem File</b>”), (iii) you download the AI Voice Stem File and
                  reupload the AI Voice Stem File along with all of your component tracks or stems
                  (e.g., drums, percussion instruments, bass, rhythm , guitar, etc.) to create the
                  New AI Voice Artist Track that includes the AI Voice Stem File. Once you have
                  finalized your New AI Voice Artist Track, you must upload it to your User Account
                  for review and approval by the applicable Voice Artist. At the time you upload the
                  New AI Voice Artist Track to your User Account, you will be provided with the
                  agreement that will apply to the ownership, exploitation and distribution of the
                  applicable New AI Voice Artist Track, which will include the terms previously
                  provided at the time you selected the applicable Voice Artist (including without
                  limitation copyright ownership splits), as well as additional terms governing your
                  rights and obligations with respect to the New AI Voice Artist Track (each, a “
                  <b>New Track Agreement</b>”). You will have an opportunity to review the New Track
                  Agreement, and, if you agree to the terms of the New Track Agreement, you must
                  acknowledge your agreement prior to the New AI Voice Artist Track being submitted
                  to the Voice Artist for approval. Once you have acknowledged your agreement to the
                  New Track Agreement, the New AI Voice Artist Track will be submitted to the Voice
                  Artist for approval. If the New AI Voice Artist Track is approved by or on behalf
                  of the applicable Voice Artist, the applicable New Track Agreement will become
                  effective, and you will be notified through your User Account.
                </i>
              </li>

              <li>
                <b>Distribution of New AI Voice Artist Tracks</b>. Following the effectiveness of
                the New Track Agreement, Hooky will have the sole and exclusive right to distribute
                the New AI Voice Artist Track through distribution aggregators and digital service
                providers as further set forth in the New Track Agreement. You will not have any
                rights to use or exploit the New AI Voice Artist Track except as expressly permitted
                under the terms of the New Track Agreement. To the extent expressly permitted in
                accordance with the terms of the New Track Agreement, (A) you will have the right to
                distribute the New AI Voice Artist Track through your social media profile pages,
                provided, that (i) each social media profile page must be identified by you and
                associated with your User Account prior to any applicable distribution, and (ii) you
                may not monetize any posts containing the New AI Voice Artist Track on the
                applicable social media profile pages; and (B) you will also have the right to post
                the New AI Voice Artist Track to YouTube, so long as you take any action necessary
                and as may be directed by Hooky from time to time to authorize and otherwise enable
                Hooky to monetize any such posts; and, any claiming and/or monetization of posts on
                YouTube not in accordance with the foregoing will be deemed a breach of these Terms.
              </li>

              <li>
                <b>No Guarantee of Voice Artist Engagement</b>. The Hooky Platform and Services are
                provided as a means of potentially connecting you with Voice Artists for purposes of
                creating the New AI Voice Artist Tracks using the Hooky Platform and Services.
                However, Hooky is not an agent or representative of any Voice Artist, and Hooky has
                no authority, right, or ability to control the decisions of any Voice Artist, or to
                require that any Voice Artist approves a New AI Voice Artist Track. You acknowledge
                and agree that Hooky shall not have any obligation or liability to you with respect
                to any Voice Artist’s approval or rejection of any New AI Voice Artist Track, or any
                Voice Artist’s performance, or failure to perform, pursuant to any New Track
                Agreement. Further, Hooky does not represent, warrant, or guarantee that any
                particular Voice Artist will use the Hooky Platform or that any Voice Artist will
                meet your requirements.
              </li>

              <li>
                <b>Rejected New AI Voice Artist Tracks</b>. If a Voice Artist rejects an applicable
                New AI Voice Artist Track, you may not use that New AI Voice Artist Track for any
                purpose, and you shall immediately destroy any and all copies of the applicable New
                AI Voice Artist Track within your possession or control that is not stored on the
                Hooky Platform. Any failure to do so shall be deemed a material breach of these
                Terms and a violation of publicity rights of the Voice Artist and other proprietary
                rights. In addition, Hooky shall not use the applicable New AI Voice Artist Track
                for any purpose, except for purposes of submitting it to third party content control
                and rights management companies to ensure your compliance with this Section 6(d),
                and that the applicable New AI Voice Artist Track is not otherwise being wrongfully
                exploited. However, notwithstanding rejection by an applicable Voice Artist, you may
                elect to submit the applicable Creator Recording to an alternative Voice Artist
                through the Hooky Platform and repeat the process set forth in Section 6(a).
              </li>

              <li>
                <b>AI Voice Stem Files</b>. You are not permitted to use any AI Voice Stem File
                other than in connection with the creation of the applicable New AI Voice Artist
                Track that you then submit to the applicable Voice Artist for approval via the Hooky
                Platform as set forth and permitted herein. After creation and uploading of the New
                AI Voice Artist Track, you shall immediately destroy any applicable AI Voice Stem
                File within your possession or control that is not stored on the Hooky Platform. Any
                failure to do so, and any other use by you of the AI Voice Stem File, shall be
                deemed a material breach of these Terms and a violation of publicity rights of the
                Voice Artist and other proprietary rights.
              </li>
            </ul>
          </li>

          <li>
            <b>Fees and Payments</b>. You may be able to use certain features of the Hooky Platform
            and Services free of charge. However, in order to access paid features of the Hooky
            Platform and Services, you must pay Hooky the fees specified at the time you elect to
            sign up for the applicable features (the “<b>Fees</b>”) in accordance with the payment
            terms specified at that time. If you sign up for features that require the payment of
            Fees, you must keep a valid payment method on file with us to pay for all incurred and
            recurring Fees. Hooky will charge applicable Fees to any valid payment method that you
            authorize (“<b>Authorized Payment Method</b>”), and Hooky will continue to charge the
            Authorized Payment Method for applicable Fees until you have terminated your User
            Account, and any and all outstanding Fees have been paid in full. Unless otherwise
            indicated, all Fees and other charges are in U.S. dollars, and all payments will be in
            U.S. currency. Fees are not refundable. If we are not able to process payment of Fees
            using an Authorized Payment Method, we may make subsequent attempts to process payment
            using any Authorized Payment Method. If we are unable to successfully process payment of
            Fees using an Authorized Payment Method within twenty-eight (28) days of our initial
            attempt, we may suspend and revoke access to your User Account. Your User Account will
            be reactivated upon your payment of any outstanding Fees, plus the Fees applicable to
            your next billing cycle. You may not be able to access your User Account during any
            period of suspension. All Fees payable by you to Hooky under these Terms will be paid
            free and clear of any deductions or withholdings whatsoever. Other than taxes charged by
            Hooky to you and remitted to the appropriate tax authorities on your behalf, any
            deductions or withholdings that are required by law will be borne by you and paid
            separately to the relevant taxation authority. Hooky will be entitled to charge the full
            amount of Fees stipulated under these Terms to your Authorized Payment Method ignoring
            any such deduction or withholding that may be required.
          </li>

          <li>
            <b>Prohibited Uses</b>. In connection with your use of the Hooky Platform and Services,
            you agree and represent that:
            <ul>
              <li>
                You will not copy, transmit, distribute, sell, resell, license, decompile, reverse
                engineer, disassemble, modify, publish, create derivative works from, perform,
                display, incorporate into another website, or in any other way exploit any part of
                the Hooky Platform and Services or any derivative works thereof, in whole or in part
                for commercial or non-commercial purposes;
              </li>

              <li>
                You will not frame or display the Hooky Platform and Services (or any portion
                thereof) as part of any other website or any other work of authorship without prior
                written permission;
              </li>

              <li>
                You will not violate any law, contract, intellectual property, or other third-party
                rights, and will not engage in any fraudulent or illegal activity, including illegal
                gambling, money laundering, or fraud;
              </li>

              <li>
                You will not publish or make available any pornographic or inappropriate material,
                racist content, or use the Hooky Platform and Services for any other use that would
                contradict or violate these Terms or applicable law;
              </li>

              <li>
                You will not impersonate any other person or entity, provide false or misleading
                identification or address information, or invade or violate the privacy, personal,
                publicity, intellectual property or proprietary rights, of any person or entity;
              </li>

              <li>
                You will not engage in spidering or harvesting, or participate in the use of
                software, including spyware, designed to collect data from the Hooky Platform and
                Services, or use any means to scrape or crawl any part of the Hooky Platform and
                Services
              </li>

              <li>
                You will not access or use the Hooky Platform and Services for purposes of obtaining
                information to build a similar or competitive website, application or service.
              </li>
            </ul>
          </li>

          <li>
            <b>Our Management of the Service; User Misconduct</b>

            <ul>
              <li>
                <b>Our Right to Manage the Service</b>. We reserve the right, but do not undertake
                the obligation to: (i) monitor or review the Hooky Platform and Services for
                violations of these Terms and for compliance with our policies; (ii) report to law
                enforcement authorities and/or take legal action against anyone who violates these
                Terms; (iii) refuse, restrict access to or the availability of, or remove, delete,
                edit or disable (to the extent technologically feasible) the Hooky Platform and
                Services or any portion thereof; (iv) manage the Hooky Platform and Services in a
                manner designed to protect our, our users’, and third parties’ rights and property
                or to facilitate the proper functioning of the Hooky Platform and Services; and/or
                (v) terminate or block your use of the Hooky Platform and Services for violating
                these Terms.
              </li>

              <li>
                <b>
                  Our Right to Terminate Users. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS
                  OR ANY REMEDY WE MAY HAVE UNDER LAW OR IN EQUITY, WE RESERVE THE RIGHT TO, IN OUR
                  SOLE DISCRETION, AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
                  HOOKY PLATFORM AND SERVICES TO ANY PERSON FOR ANY REASON OR FOR NO REASON AT ALL,
                  INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY OR
                  COVENANT CONTAINED IN THESE TERMS, OR OF ANY APPLICABLE LAW OR REGULATION.
                </b>
              </li>
            </ul>
          </li>

          <li>
            <b>Third Party Sites</b>. The Hooky Platform may contain links to websites operated by
            third parties, such as third party social media services (“
            <b>Third Party Sites</b>”); however, we do not own or operate the Third Party Sites, and
            we have not reviewed, and cannot review, all of the material, including products or
            services, made available through Third Party Sites. The availability of these links on
            the Hooky Platform does not represent, warrant or imply that we endorse any Third Party
            Sites or any materials, opinions, products or services available on them. Third party
            materials accessed through or used by means of the Third Party Sites may also be
            protected by copyright and other intellectual property laws.
            <b>
              THESE TERMS DO NOT APPLY TO THIRD PARTY SITES. BEFORE VISITING A THIRD PARTY SITE
              THROUGH LINKS OR OTHER MEANS PROVIDED ON OR THROUGH THE HOOKY PLATFORM, YOU SHOULD
              REVIEW THE THIRD PARTY SITE’S TERMS AND CONDITIONS AND PRIVACY POLICY, AND INFORM
              YOURSELF OF THE REGULATIONS, POLICIES AND PRACTICES OF THESE THIRD PARTY SITES
            </b>
            .
          </li>

          <li>
            <b>Intellectual Property.</b>

            <ol type="a">
              <li>
                <b>Ownership of the Hooky Platform and Services</b>. Hooky shall own and retain all
                right, title and interest in and to the Hooky Platform and Services, and all
                intellectual property rights arising therefrom or related thereto. Upon Hooky’s
                confirmation that it has accepted your request to create a User Account, Hooky shall
                grant, and hereby grants to you, a non-exclusive, non-transferable, and limited
                license, subject to these Terms, to access and use the Hooky Platform and Services
                for the purposes set forth herein and for such other approved purposes as permitted
                by Hooky from time to time. Any other use of the Hooky Platform and Services is
                expressly prohibited. Except for the foregoing express, limited license, all other
                rights in the Hooky Platform and Services are reserved by Hooky.
              </li>

              <li>
                <b>Ownership of Creator Recordings</b>. You shall own and retain all right, title
                and interest in and to the Creator Recordings you provide in connection with the
                Hooky Platform and Services, and all intellectual property rights arising therefrom
                or related thereto. Upon submission by you of any Creator Recording to the Hooky
                Platform, you shall grant, and you hereby grant to Hooky, a non-exclusive,
                non-transferable, sublicensable license, to access and use the applicable Creator
                Recording (including without limitation the embodied musical composition) as
                necessary for Hooky to exercise its rights and perform its obligations as set forth
                in these Terms. Except for the foregoing license, all other rights in the Creator
                Recordings are reserved by you. For the avoidance of doubt, and notwithstanding
                anything to the contrary in these Terms, AI Voice Stem Files and New AI Voice Artist
                Tracks are <u>not</u> Creator Recordings.
              </li>

              <li>
                <b>Ownership of AI Voice Stem Files</b>. As between you and Hooky, Hooky shall own
                and retain all right, title and interest in and to the AI Voice Stem Files,
                including all intellectual property rights therein; provided that, Hooky shall not
                be permitted to use or exploit the applicable AI Voice Stem File other than in
                connection with the process for generating the New AI Voice Artist Track or to
                otherwise monitor unlawful use of the AI Voice Stem File. You have no right to use
                any AI Voice Stem Files for any purpose whatsoever other than solely in connection
                with the creation of the applicable New AI Voice Artist Track via the Hooky Platform
                as permitted by these Terms.
              </li>

              <li>
                <b>Ownership of Approved New AI Voice Artist Tracks</b>. Ownership of the sound
                recording rights in the New AI Voice Artist Tracks that are approved by the
                applicable Voice Artist shall be allocated between you and the applicable Voice
                Artist (or the Voice Artist’s representative) in accordance with the applicable New
                Track Agreement. Ownership of the rights in the musical compositions embodied in the
                New AI Voice Artist Tracks shall be retained by you.
              </li>

              <li>
                <b>
                  Ownership of modified versions of Creator Recordings that are not New AI Voice
                  Artist Tracks
                </b>
                . For any versions of Creator Recordings modified by your use of the Hooky Platform
                and Services that are not New AI Voice Artist Tracks and do not include the voice of
                any Voice Artist, you (i) shall retain any and all right, title and interest in and
                to such modified versions of the Creator Recordings, and all intellectual property
                rights arising therefrom or related thereto, including for example, any such
                modified versions of your Creator Recordings that include Stock Voices, and (ii)
                shall have the right to distribute such recordings off of the Hooky Platform in your
                discretion (subject to your representations and warranties herein and your
                compliance with applicable laws).
              </li>
            </ol>
          </li>

          <li>
            <b>Intellectual Property Infringement Policy</b>. If you believe that content
            distributed by Hooky or made available through the Hooky Platform and Services infringes
            your intellectual property rights, please contact us at <b>security@hooky.co</b>, and
            provide the following information:
            <ul>
              <li>
                Your name or the name of the owner of the intellectual property right being
                asserted.
              </li>

              <li>
                Your name or the name of the owner of the intellectual property right being
                asserted.
              </li>

              <li>
                Identification of the intellectual property right you claim is being violated.
              </li>

              <li>
                Identification of the material that you believe violates the applicable intellectual
                property right (such as a URL for the webpage for where the material is posted).
              </li>

              <li>
                An explanation of how the use or material complained of violates the applicable
                intellectual property right.
              </li>

              <li>
                Information reasonably sufficient to permit us to contact you, such as your address,
                telephone number, and email address.
              </li>

              <li>
                A statement that you have a good faith belief that the use described above is not
                authorized by the rights owner, its agent, or the law; that the information
                contained in the notice is accurate, and that you are authorized to act on behalf of
                the owner of the intellectual property right at issue.
              </li>
            </ul>
            Further, by providing us with the above information, you acknowledge and agree that your
            information and a copy of your notification may be provided to the user responsible for
            uploading the file you claim is infringing, and other third parties for review and
            processing.
          </li>

          <li>
            <b>Term and Termination. </b>

            <ol>
              <li>
                <b>Term</b>. These Terms shall remain in effect for the period of time that you
                maintain an active User Account, unless sooner terminated as set forth in these
                Terms.
              </li>

              <li>
                <b>Termination by You</b>. You may terminate your use of the Hooky Platform and
                Services and these Terms by deactivating your User Account via the accounts section
                of the Hooky Platform. Notwithstanding any deactivation of your User Account, any
                applicable New Track Agreements in effect as of the date of deactivation will remain
                in effect in accordance with their respective terms.
              </li>

              <li>
                <b>Termination by Hooky</b>. Hooky may terminate your access to the Hooky Platform
                and Services, and these Terms, immediately upon notice to you if (i) you breach any
                terms or conditions of these Terms or use the Hooky Platform and Services other than
                in accordance with these Terms, (ii) Hooky is required to do so by a subpoena, court
                order, or binding order of a court or other government authority, or (iii) Hooky
                reasonably suspects you of using the Hooky Platform and Services in connection with
                illegal, unauthorized, or improper activity.
              </li>

              <li>
                <b>Additional Rights</b>. In addition to the foregoing termination rights, if you
                breach these Terms, Hooky reserves all rights and remedies available at law and in
                equity for any such breach. You acknowledge and agree that Hooky shall not be liable
                to you or to any third party for any modification or termination of the termination
                of the Hooky Platform and Services, or suspension or termination of your access to
                the Hooky Platform and Services, except to the extent otherwise expressly set forth
                herein.
              </li>
            </ol>
          </li>

          <li>
            <b>Representations and Warranties</b>. You represent and warrant that: (a) you possess
            the right and authority to enter into these Terms and to grant the rights, licenses,
            consents and waivers granted in these Terms and the applicable New Track Agreements; (b)
            you own or control all right, title and interest in and to the Creator Recordings
            (including without limitation, the embodied musical compositions); and (c) your
            acceptance of and performance of these Terms (and each New Track Agreement), and Hooky’s
            exercise of the rights and licenses granted by you in these Terms, will not violate any
            third-party rights, any duties or obligations you may have with respect to third
            parties, or the provisions of any agreement to which you are a party.
          </li>

          <li>
            <b>
              Warranty Disclaimers. EXCEPT AS EXPRESSLY SET FORTH HEREIN OR AS MAY NOT BE LIMITED BY
              LAW, THE HOOKY PLATFORM AND SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
              BASIS. EXCEPT AS EXPRESSLY SET FORTH HEREIN, HOOKY MAKES NO REPRESENTATIONS OR
              WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY WARRANTY OF ACCURACY,
              COMPLETENESS, NONINFRINGEMENT, PERFORMANCE, MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, COMMERCIAL UTILITY, SCOPE, OR TITLE WITH RESPECT THERETO. HOOKY DOES NOT
              GUARANTEE ANY RESULTS OR COMMERCIAL OUTCOME IN CONNECTION WITH THE USE OF THE HOOKY
              PLATFORM AND SERVICES.
            </b>
          </li>

          <li>
            <b>Indemnification</b>

            <ol>
              <li>
                <b>By You</b>. You will defend, indemnify, and hold harmless Hooky and each of
                Hooky’s affiliates, the successors and assigns of each of the foregoing, the agents
                of each of the foregoing, and the officers, directors and employees of each of the
                foregoing, from and against any and all claims, demands, lawsuits or proceedings
                asserted or commenced by a third party (each a “Claim”), and all damages,
                liabilities, costs and expenses arising therefrom, including reasonable outside
                counsel fees and costs, to the extent the Claim (i) is based on allegations that, if
                true, would constitute a breach by you of these Terms or any New Track Agreement; or
                (ii) arises from any negligent act or omission or willful misconduct by you.
              </li>

              <li>
                <b>By Hooky</b>. Hooky will defend you, indemnify you and hold you harmless from and
                against any and all Claims, and all damages, liabilities, costs and expenses arising
                therefrom, including reasonable outside counsel fees and costs, to the extent the
                Claim (i) is based on allegations that, if true, would constitute a breach by Hooky
                of these Terms, including any warranty, representation or covenant made in these
                Terms by Hooky; or (ii) arises from any negligent act or omission or willful
                misconduct by Hooky.
              </li>
            </ol>
          </li>

          <li>
            <b>Legal Disputes and Arbitration Agreement</b>
            <p>
              Please Read This Following Clause Carefully – It May Significantly Affect Your Legal
              Rights, Including Your Right to File a Lawsuit in Court
            </p>

            <ul>
              <li>
                <b>Initial Dispute Resolution</b>. Hooky can be contacted at privacy@hooky.co to
                address any concerns you may have regarding these Terms or the Hooky Platform and
                Services. Most concerns may be quickly resolved in this manner. Each of you and
                Hooky agree to use best efforts to settle any dispute, claim, question, or
                disagreement directly through consultation and good faith negotiations which shall
                be a precondition to either party initiating a lawsuit or arbitration.
              </li>

              <li>
                <b>Agreement to Binding Arbitration</b>. If you and Hooky do not reach an agreed
                upon solution within a period of thirty (30) days from the time informal dispute
                resolution is pursued pursuant to Section 17(a) above, then either party may
                initiate binding arbitration. All claims arising out of or relating to these Terms
                (including their formation, performance and breach), the parties’ relationship with
                each other and/or your use of the Hooky Platform and Services shall be finally
                settled by binding arbitration administered on a confidential basis by JAMS, in
                accordance with the JAMS Streamlined Arbitration Rules and Procedures, excluding any
                rules or procedures governing or permitting class actions. Each party will have the
                right to use legal counsel in connection with arbitration at its own expense. The
                parties shall select a single neutral arbitrator in accordance with the JAMS
                Streamlined Arbitration Rules and Procedures. The arbitrator, and not any federal,
                state or local court or agency, shall have exclusive authority to resolve all
                disputes arising out of or relating to the interpretation, applicability,
                enforceability or formation of these Terms, including, but not limited to, any claim
                that all or any part of these Terms is void or voidable. The arbitrator shall be
                empowered to grant whatever relief would be available in a court under law or in
                equity. The arbitrator’s award shall be in writing and provide a statement of the
                essential findings and conclusions, shall be binding on the parties and may be
                entered as a judgment in any court of competent jurisdiction. The interpretation and
                enforcement of these Terms shall be subject to the Federal Arbitration Act.
                <p>
                  Arbitration proceedings will be held in Los Angeles, California unless you are a
                  consumer, in which case you may elect to hold the arbitration at the JAMS office
                  closest to your residence. For purposes of this Section 17, a “consumer” means a
                  person using the Hooky Platform and Services for personal, family or household
                  purposes. Additionally, arbitrations may be conducted telephonically or via video
                  conference for disputes alleging damages less than $10,000.00. You and Hooky agree
                  that the state or federal courts of the State of California and the United States
                  sitting in Los Angeles, California have exclusive jurisdiction over any appeals
                  and the enforcement of an arbitration award.
                </p>
                <p>
                  The JAMS rules governing the arbitration may be accessed at
                  https://www.jamsadr.com/adr-rules-procedures. You either acknowledge and agree
                  that you have read and understand the JAMS Rules or waive your opportunity to read
                  the JAMS Rules and waive any claim that the JAMS Rules are unfair or should not
                  apply for any reason.
                </p>
                <p>
                  The parties understand that, absent this mandatory provision, they would have the
                  right to sue in court and have a jury trial. They further understand that, in some
                  instances, the costs of arbitration could exceed the costs of litigation and the
                  right to discovery may be more limited in arbitration than in court.
                </p>
              </li>

              <li>
                <b>Class Action and Class Arbitration Waiver</b>. You and Hooky each further agree
                that any court action or arbitration shall be conducted in our respective individual
                capacities only and not as a class action or other representative action, and you
                and Hooky each expressly waive our respective right to file a class action, class
                arbitration, or seek relief on a class basis. If any court or arbitrator determines
                that the class action and class arbitration waiver set forth in this Section is void
                or unenforceable for any reason or that an arbitration can proceed on a class basis,
                then the arbitration provision set forth above in Section 17(b) above shall be
                deemed null and void in its entirety and the parties shall be deemed to have not
                agreed to arbitrate disputes.
              </li>

              <li>
                <b>Exception - Small Claims Court Claims</b>. Notwithstanding the parties’ agreement
                to resolve all disputes through arbitration, either party may seek relief in a small
                claims court for disputes or claims within the scope of that court’s jurisdiction.
              </li>

              <li>
                <b>Exception – California Private Attorneys General Act (PAGA) Action</b>.
                Notwithstanding the parties’ agreement to resolve all disputes through arbitration,
                either party may seek relief in a court of law for a claim arising under
                California’s Private Attorneys General Act.
              </li>

              <li>
                <b>Exception – Intellectual Property Claims</b>. Notwithstanding the parties’
                agreement to resolve all disputes through arbitration, either party may bring an
                individual (non-class) action in a court of competent jurisdiction (which
                jurisdiction shall be exclusive), with respect to any dispute relating to a party’s
                intellectual property rights where the only relief sought is for injunctive or
                equitable relief for the alleged unlawful use of intellectual property.
              </li>

              <li>
                <b>Arbitration Fees</b>. If you are a consumer and you initiate arbitration, to the
                extent the filing fee for the arbitration exceeds Two Hundred and Fifty U.S. Dollars
                ($250.00), Hooky will pay the additional cost. If Hooky is required to pay the
                additional cost of the filing fees, you should submit a request for payment of fees
                to JAMS along with your form for initiating the arbitration, and Hooky will make
                arrangements to pay all necessary fees directly to JAMS. Hooky will also be
                responsible for paying all other fees or amounts payable to JAMS with respect to the
                arbitration, excluding costs incurred by you for legal counsel, travel and other
                out-of-pocket costs and expenses not constituting fees or amounts payable to JAMS.
                If you are not a consumer, each party will be responsible for applicable fees or
                other amounts payable to JAMS with respect to the arbitration.
              </li>

              <li>
                <b>30 Day Right to Opt-Out</b>. You have the right to opt-out and not be bound by
                the arbitration and class action waiver provisions set forth in Sections 17(a) and
                17(b) above by sending written notice of your decision to opt-out by emailing us at
                <b>privacy@hooky.co</b>. The notice must be sent within thirty (30) days of the date
                you create a User Account, otherwise you shall be bound to arbitrate disputes in
                accordance with the terms of those Sections. If you opt-out of these arbitration
                provisions, Hooky also will not be bound by these arbitration provisions.
              </li>

              <li>
                <b>Exclusive Venue for Litigation</b>. To the extent that the arbitration provisions
                set forth in Section 17(b) of these Terms do not apply or if you have opted out of
                arbitration, the parties agree that any litigation between them shall be filed
                exclusively in state or federal courts located in Los Angeles, California (except
                for small claims court actions which may be brought in the county where you reside).
                The parties expressly consent to exclusive jurisdiction in Los Angeles, California
                (State and Federal) for any litigation other than small claims court actions.
              </li>

              <li>
                <b>Consolidation of Arbitration Claims</b>. Although you and Hooky have agreed that
                no disputes may proceed as part of a class arbitration, the JAMS mediator may
                consolidate an individual arbitration filed pursuant to these Terms with other
                individual arbitration(s), at the request of either party, if the arbitrations share
                common issues of law or fact. The consolidation issue shall be determined by the
                arbitrator appointed for the earliest filed application. Any disputes over whether
                an arbitration claim should be consolidated with others, or which arbitrator shall
                hear any consolidated matter, shall be resolved by JAMS. If multiple individual
                arbitration proceedings are consolidated, JAMS and the arbitrator may treat the
                consolidated proceedings as one arbitration for purposes of assessing JAMS fees and
                the arbitrator’s compensation, and you consent and agree not to object to any
                reduction or elimination of JAMS fees or arbitrator compensation.
              </li>

              <li>
                <b>Independent Contractor</b>. In entering into these Terms, Hooky and you have and
                will have the status of independent contractors. Accordingly, there is no joint
                venture, partnership, agency or fiduciary relationship existing between the parties,
                and the parties do not intend to create any such relationship by these Terms.
              </li>

              <li>
                <b>Assignment</b>. Hooky may assign its rights under these Terms in whole or in part
                to any person or entity, and such rights may be assigned by any assignee thereof.
                Any purported assignment by you will be null and void. These Terms will be binding
                upon and inure to the benefit of the parties to these Terms and their heirs,
                executors, administrators, legal representatives, and, where permitted, successors
                and assigns.
              </li>

              <li>
                <b>
                  Limitation of Liability. TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                  SHALL HOOKY BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL,
                  INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT DAMAGES ARISING
                  FROM YOUR USE OF THE HOOKY PLATFORM AND SERVICES. NOTWITHSTANDING ANYTHING TO THE
                  CONTRARY CONTAINED IN THESE TERMS, HOOKY’S LIABILITY TO YOU IN RESPECT OF ANY LOSS
                  OR DAMAGE SUFFERED BY YOU AND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR
                  THE HOOKY PLATFORM AND SERVICES, WHETHER IN CONTRACT, TORT OR FOR BREACH OF
                  STATUTORY DUTY OR IN ANY OTHER WAY, SHALL NOT EXCEED $50.
                </b>
              </li>

              <li>
                <b>Notice to California Users</b>. Under California Civil Code Section 1789.3, users
                located in California are entitled to the following consumer rights notice: If a
                user has a question or complaint regarding the Service, please send an email to
                <b>support@hooky.co</b>. California residents may reach the Complaint Assistance
                Unit of the Division of Consumer Services of the California Department of Consumer
                Affairs by mail at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by
                telephone at +1 (916) 445-1254 or +1 (800) 952-5210.
              </li>

              <li>
                <b>Independent Contractors</b>. Nothing in these Terms shall be deemed to create an
                agency, partnership, joint venture, employer-employee or franchisor-franchisee
                relationship of any kind between us and any user.
              </li>

              <li>
                <b>Non-Waiver</b>. Our failure to exercise or enforce any right or provision of
                these Terms shall not operate as a waiver of the applicable right or provision.
              </li>

              <li>
                <b>Severability</b>. These Terms operate to the fullest extent permissible by law.
                If any provision or part of a provision of these Terms is unlawful, void, or
                unenforceable, that provision or part of the provision is deemed severable from
                these Terms and shall not affect the validity and enforceability of any remaining
                provisions.
              </li>

              <li>
                <b>No Modifications by Our Employees</b>. If any of our employees offers to modify
                the terms of these Terms, he or she is not acting as an agent for us or speaking on
                our behalf. You may not rely, and should not act in reliance on, any statement or
                communication from our employees or anyone else purporting to act on our behalf.
              </li>

              <li>
                <b>Priority</b>. To the extent that any provision of these Terms conflicts with any
                provision of a New Track Agreement and cannot reasonably be interpreted so that such
                provisions are consistent with each other, the terms set forth in the New Track
                Agreement shall apply.
              </li>
            </ul>
          </li>
        </ul>

        <p>
          <b>Contact Information</b>. If you have any questions or concerns about these Terms, or
          the Hooky Platform and Services, you may contact us at security@hooky.co.
        </p>
      </div>
    </div>
  );
};
