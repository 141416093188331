export const DENY_REASON_OPTIONS = [
  {
    value: 'do_not_like',
    label: "I don't like the mix"
  },
  {
    value: 'not_my_style',
    label: 'Not my style'
  },
  {
    value: 'poor_quality',
    label: 'Poor quality'
  },
  {
    value: 'offensive',
    label: 'Offensive'
  },
  {
    value: 'other',
    label: 'Other'
  }
];
