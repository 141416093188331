import React from 'react';

import { useAudioContext } from '@/providers';
import { Icon } from '@/components';

import styles from './play-button.module.css';

export interface PlayButtonProps {
  song: {
    image_url: string;
    title: string;
    subtitle: string;
    master_url: string;
    id: string;
  };
  classes?: string;
  'data-cell-hover'?: string;
}

export const PlayButton: React.FC<PlayButtonProps> = (props) => {
  const { song, classes = '' } = props;
  const { master_url } = song;

  const { isPlaying, setFile, togglePlay, trackInfo } = useAudioContext();

  const handlePlayClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (trackInfo?.id === song.id) {
      togglePlay();
    } else {
      setFile({
        audio: master_url,
        title: song.title,
        imageUrl: song.image_url,
        subtitle: song.subtitle,
        id: song.id
      });
    }
  };

  return (
    <button
      onClick={handlePlayClick}
      data-cell-hover={props['data-cell-hover']}
      className={`bg-hookyyellow-500 hover:bg-hookyyellow-600 rounded-full w-14 h-14 ${styles.button} ${classes}`}
      disabled={!master_url}
    >
      <Icon
        size="4xl"
        icon={trackInfo?.id === song.id && isPlaying ? 'mdi:pause' : 'mdi:play'}
        className="text-black w-11 h-11"
      />
    </button>
  );
};
