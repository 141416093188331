import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { useApi } from '@/hooks';
import { IGenre } from '@/models/Genre';
import { DefaultItemOption } from '@/models/common.ts';

export const useGetGenres = () => {
  const api = useApi({});

  return useQuery<DefaultItemOption[], AxiosError>({
    queryKey: ['genres'],
    queryFn: async (): Promise<DefaultItemOption[]> => {
      const response = await api.get<IGenre[]>(`/v1/genres`);

      return response.data.map((genre) => ({
        value: genre.id,
        label: genre.name
      }));
    }
  });
};
