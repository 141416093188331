import { ReactNode } from 'react';
import { AuthProviderProps, AuthProvider as OIDCAuthProvider, User, useAuth } from 'oidc-react';
import { useNavigate, useLocation } from 'react-router-dom';

import { ROUTES } from '@/routes/routes';
import {
  AUTH_AUTHORITY,
  AUTH_CLIENT_ID,
  AUTH_POST_LOGOUT_REDIRECT_URI,
  AUTH_REDIRECT_URI,
  AUTH_SCOPE
} from '@/config';

interface IAuthProvider {
  children: ReactNode;
}

export const AuthProvider = ({ children }: IAuthProvider) => {
  const navigate = useNavigate();
  const location = useLocation();

  const oidcConfig: AuthProviderProps = {
    onBeforeSignIn: () => {
      return location.pathname;
    },
    onSignIn: async (response: User | null) => {
      if (!response) {
        throw new Error('No response');
      }
      navigate(response.state || ROUTES.DASHBOARD, {
        replace: true
      });
    },
    authority: AUTH_AUTHORITY,
    clientId: AUTH_CLIENT_ID,
    postLogoutRedirectUri: AUTH_POST_LOGOUT_REDIRECT_URI,
    responseType: 'code',
    redirectUri: AUTH_REDIRECT_URI,
    scope: AUTH_SCOPE
  };

  return (
    <OIDCAuthProvider {...oidcConfig}>
      <AuthAccessController>{children}</AuthAccessController>
    </OIDCAuthProvider>
  );
};

interface IAuthAccessController {
  children: React.ReactNode;
}

const AuthAccessController = ({ children }: IAuthAccessController) => {
  const auth = useAuth();

  if (auth.isLoading) {
    return null;
  }

  return children;
};
