import { FC } from 'react';
import { Icon, Typo } from '@/components';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { MENU_SETTINGS } from '@/pages/Account/menu.tsx';

const linkTextClassName =
  'text-hookybase-300 dark:text-white hover:text-hookybase-600 dark:hover:text-white';

const liClassName = twMerge([
  'flex justify-between items-center p-2 mb-2',
  'rounded bg-hookybase-50 dark:bg-hookybase-500 text-hookybase-300 dark:text-hookybase-200 hover:text-hookybase-600 dark:hover:text-white',
  'hover:bg-hookybase-100 dark:hover:bg-hookybase-400 transition-colors duration-200'
]);

const AccountAsideSections: FC = () =>
  MENU_SETTINGS.map((groupSetting) => (
    <section key={groupSetting.key}>
      <Typo.h3 className="mb-2">{groupSetting.titleGroup}</Typo.h3>
      <ul>
        {groupSetting.items.map((setting) => (
          <li key={setting.key}>
            <Link to={setting.link} className={liClassName}>
              <div className={linkTextClassName}>{setting.title}</div>
              <Icon icon="ChevronRight" size="3xl" />
            </Link>
          </li>
        ))}
      </ul>
    </section>
  ));

export default AccountAsideSections;
