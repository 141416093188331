import { AgreedTermsUploadTrackModal, ArtistCard, PlatformRules } from '@/components';
import { Link } from 'react-router-dom';
import { useSongStore } from '@/store/useSongStore';
import { ROUTES } from '@/routes/routes';
import { useModalStore } from '@/store';
import { AGREED_TERMS_UPLOAD_TRACK_MODAL_NAME } from '@/constants/song.ts';

export const Licenses = () => {
  const { songData } = useSongStore();
  const { openModal } = useModalStore();

  const handleOpenTerms = () => {
    openModal({
      name: AGREED_TERMS_UPLOAD_TRACK_MODAL_NAME,
      readOnly: true,
      title: songData.name,
      artistName: songData.display_artist,
      split: `${songData.voices[0]?.royalty_percent}`
    });
  };

  const artist = {
    ...songData.voices[0],
    requires_license: songData.requires_license
  };

  return (
    <>
      {songData?.voices.length > 0 ? (
        <ArtistCard allowCreate={false} layout="horizontal" artist={artist} />
      ) : null}

      <div className="mt-6">
        <PlatformRules />

        <div className="flex justify-between items-center flex-wrap gap-x-8">
          <div
            className={`flex flex-wrap ${
              songData.status !== 'upload_for_approval' ? 'w-full my-4 gap-x-8' : ''
            }`}
          >
            <Link
              className="text-[0.875rem] w-[146px] text-end flex-none"
              target="_blank"
              to={ROUTES.TERMS_OF_USE}
            >
              See full service terms
            </Link>

            {songData.status !== 'upload_for_approval' ? (
              <button type="button" className="text-[0.875rem]" onClick={handleOpenTerms}>
                License term sheet agreement
              </button>
            ) : null}
          </div>
        </div>
      </div>

      <AgreedTermsUploadTrackModal />
    </>
  );
};
