import { useModalStore } from '@/store';
import { Typo, Modal, Icon, FileUploadDropBox } from '@/components';

interface ModalFileUploadDropBoxProps {
  modalName: string;
  modalTitle: string;
  onUpload: ({ voiceId, files }: { voiceId: string; files: FileList }) => void;
  isLoading?: boolean;
  uploadProgress?: number;
  acceptFileExtensions?: string;
  hasError?: boolean;
  hasRestriction?: boolean;
  closeOnClickOutside?: boolean;
  onClose?: () => void;
}

export const ModalFileUploadDropBox = ({
  modalName,
  modalTitle,
  onUpload,
  acceptFileExtensions,
  isLoading = false,
  uploadProgress,
  hasRestriction = false,
  hasError = false,
  closeOnClickOutside,
  onClose
}: ModalFileUploadDropBoxProps) => {
  const { modalOption } = useModalStore();

  const onFilesUpload = (files: FileList) => {
    onUpload({ files, voiceId: modalOption.voiceId as string });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      name={modalName}
      title={modalTitle}
      size="550px"
      isCentered
      closeOnClickOutside={closeOnClickOutside}
      onClose={handleClose}
    >
      {(hasError || hasRestriction) && (
        <div className="bg-hookyred-500 text-white rounded-xl p-4 mb-6 flex items-center">
          <Icon icon="Info" className="w-12 h-12 p-3" />
          <div className="flex-1 flex flex-col">
            <Typo.p className="text-sm text-white">
              {hasError
                ? 'An error has occurred while uploading your files.'
                : 'Sorry, this does not match our upload criteria.'}
            </Typo.p>
            {hasRestriction && (
              <Typo.p className="text-xs">
                Please upload a WAV, AIFF, or MP3 file of 100mb or less. The overall upload size
                must not exceed 2GB. Or check if this file is uploaded already
              </Typo.p>
            )}
          </div>
        </div>
      )}
      <FileUploadDropBox
        acceptFileExtensions={acceptFileExtensions}
        onUpload={onFilesUpload}
        isLoading={isLoading}
        uploadProgress={uploadProgress}
      />
      <Typo.p className="text-sm text-hookybase-200 mb-2">
        Keep your input audio clean, dry and free from background noise or instrumentals.
      </Typo.p>
      <Typo.p className="text-xs mb-1">
        <span className="text-hookybase-300 mr-2">File types:</span>
        <span className="text-hookybase-200">MP3, WAV or AIFF only</span>
      </Typo.p>
      <Typo.p className="text-xs text-hookybase-300">
        <span className="text-hookybase-300 mr-2">Upload limit:</span>
        <span className="text-hookybase-200">100mb per file / 2GB overall</span>
      </Typo.p>
    </Modal>
  );
};
