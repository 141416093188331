import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useApi, type Conversion } from '@/hooks';

export interface ConversionStatusProps {
  refetchInterval?: number | false;
  voiceId?: string;
  isReadyToFetch?: boolean;
}

export const useConvertTrackStatus = ({
  refetchInterval = false,
  voiceId,
  isReadyToFetch
}: ConversionStatusProps): UseQueryResult<Conversion[], Error> => {
  const api = useApi({});

  return useQuery<Conversion[], Error>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['convert_track_status'],
    queryFn: async (): Promise<Conversion[]> => {
      const response = await api.get<Conversion[]>('/v1/temporary-tracks/status');
      // TODO: Filter could be removed after BE implements restriction to forbid more than 1 project in working phase
      return voiceId ? response.data.filter((vocal) => vocal.voice.id === voiceId) : response.data;
    },
    enabled: isReadyToFetch,
    refetchInterval
  });
};
