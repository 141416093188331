import React from 'react';
import { Control, useController } from 'react-hook-form';
import { Select as SelectMantain } from '@mantine/core';

import { DefaultItemOption } from '@/models/common';

import { INPUT_WRAPPER_ORDER } from '../input/constant.ts';
import inputStyles from '../input/input.module.css';
import styles from './select.module.css';

const CLASSES = {
  root: inputStyles.root,
  wrapper: inputStyles.wrapper,
  input: inputStyles.input,
  label: inputStyles.label,
  error: inputStyles.error,
  description: inputStyles.description,
  option: styles.option
};

interface SelectProps {
  name: string;
  label?: string | React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  placeholder?: string;
  description?: string;
  options: DefaultItemOption[];
  disabled?: boolean;
  onChange?: (name: string, value: string | null, option: DefaultItemOption) => void;
}

export const SelectField = (props: SelectProps) => {
  const {
    name,
    label,
    control,
    options,
    placeholder = '',
    description,
    disabled,
    onChange
  } = props;
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: ''
  });

  const handleChange = (value: string | null, option: DefaultItemOption) => {
    if (onChange) {
      onChange(name, value, option);

      return;
    }

    field.onChange(value);
  };

  return (
    <SelectMantain
      {...field}
      label={label}
      classNames={CLASSES}
      placeholder={placeholder}
      description={description}
      data={options}
      onChange={handleChange}
      disabled={disabled}
      error={fieldState.error ? fieldState.error.message : null}
      inputWrapperOrder={INPUT_WRAPPER_ORDER}
      withCheckIcon={false}
      allowDeselect={false}
      size="lg"
    />
  );
};
