import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { NameCell } from '@/components';
import { DEFAULT_DATE_FORMAT } from '@/constants';

export type ShareDataProps = {
  displayName: string;
  email: string;
  createdAt: string;
  avatarUrl: string;
};

const columnHelper = createColumnHelper<ShareDataProps>();

export const SHARED_MODEL_COLUMN = [
  columnHelper.accessor('displayName', {
    header: 'NAME',
    cell: (info) => {
      const { original } = info.row;

      return (
        <NameCell
          avatarUrl={original.avatarUrl}
          title={original.displayName}
          subtitle={original.email}
        />
      );
    },
    size: 35
  }),
  columnHelper.accessor('createdAt', {
    header: 'SHARED',
    cell: (info) => (
      <div className="text-hookybase-200 dark:text-hookybase-300">
        {dayjs(info.getValue()).format(DEFAULT_DATE_FORMAT)}
      </div>
    ),
    size: 8
  })
];
