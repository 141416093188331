import { ReactNode, Suspense, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AxiosError } from 'axios';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Icon } from '@iconify/react';
import { ThemeContext } from '@/providers/ThemeContext.tsx';

// List of response statuses, in case of getting them no retry query is needed
const STATUS_LIST_NO_RETRY = [400, 401, 403, 404, 422];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: (count, error) => {
        const status = (error as unknown as AxiosError).response?.status;
        return !((status && STATUS_LIST_NO_RETRY.includes(status)) || count >= 2);
      }
    }
  }
});

export interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Suspense fallback={<Icon icon="mdi:loading" className="animate-spin" />}>
      <QueryClientProvider client={queryClient}>
        {import.meta.env.MODE !== 'development' && <ReactQueryDevtools initialIsOpen={false} />}
        <BrowserRouter>{children}</BrowserRouter>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme={theme}
        />
      </QueryClientProvider>
    </Suspense>
  );
};

export default AppProvider;
