import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { ROLES } from '@/models/User';

type State = {
  userRoles: ROLES[];
};

type Actions = {
  setUserRoles: (user: ROLES[]) => void;
};

const initialState: { userRoles: ROLES[] } = {
  userRoles: [ROLES.CREATORS]
};

export const useUserRolesStore = create<State & Actions>()(
  immer(
    devtools(
      persist(
        (set) => ({
          ...initialState,
          setUserRoles: (userRoles: ROLES[]) => set({ userRoles })
        }),
        {
          name: 'user-storage',
          storage: createJSONStorage(() => localStorage)
        }
      )
    )
  )
);
