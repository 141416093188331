import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useApi } from '@/hooks';
import { IVoice } from '@/models/Voice.ts';

export interface UseGetVoiceProps {
  voiceId?: string;
}

export const useGetVoice = ({ voiceId }: UseGetVoiceProps): UseQueryResult<IVoice, AxiosError> => {
  const api = useApi({});

  return useQuery<IVoice, AxiosError>({
    queryKey: [voiceId],
    queryFn: async (): Promise<IVoice> => {
      const response = await api.get<IVoice>(`/v1/voices/${voiceId}`);
      return response.data;
    },
    enabled: !!voiceId
  });
};
