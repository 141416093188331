import { FC, HTMLProps, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ISong } from '@/models/Song';
import { useModalStore } from '@/store';
import { Avatar, Loader, ModalCropImage, CROP_AVATAR_MODAL } from '@/components';

interface ModalCropAvatarProps {
  endpoint: string;
  onUploadSuccess: (responseBody?: ISong) => void;
  fileFieldName: 'file' | 'image';
  isLoading?: boolean;
  circular?: boolean;
  disabled?: boolean;
  modalTitle?: string;
  defaultSrcUrl?: string;
  avatarClassName?: HTMLProps<HTMLElement>['className'];
}

export const ModalCropAvatar: FC<ModalCropAvatarProps> = ({
  endpoint,
  onUploadSuccess,
  fileFieldName,
  modalTitle,
  defaultSrcUrl,
  isLoading = false,
  circular = false,
  disabled,
  avatarClassName = ''
}) => {
  const [progress, setProgress] = useState(0);
  const { openModal } = useModalStore();

  const onImageClick = () => {
    openModal({ name: CROP_AVATAR_MODAL });
  };

  return (
    <div>
      <ModalCropImage
        onUploadSuccess={onUploadSuccess}
        endpoint={endpoint}
        fileFieldName={fileFieldName}
        onProgressChange={setProgress}
        modalTitle={modalTitle}
      />
      {isLoading ? (
        <Loader className="p-0 m-0 flex items-center w-56 h-56 md:w-36 md:h-36 border-none" />
      ) : (
        <Avatar
          image={defaultSrcUrl}
          onClick={onImageClick}
          className={twMerge(
            'object-cover w-56 h-56 md:w-36 md:h-36 shadow-lg',
            avatarClassName,
            circular ? 'rounded-full' : 'rounded-lg'
          )}
          progress={progress}
          disabled={disabled}
        />
      )}
    </div>
  );
};
