import { ISong } from '@/models/Song.ts';

export interface SongQueryData {
  pageParams: unknown;
  pages: ISong[][];
}

const DEFAULT_OLD_DATA = {
  pageParams: undefined,
  pages: []
};

export function updateSongCache(oldData: SongQueryData = DEFAULT_OLD_DATA, newSong: ISong) {
  const { pages, ...rest } = oldData;

  const updatedData = pages.map((page) =>
    page.map((song) => {
      if (song.id === newSong.id) {
        return newSong;
      }

      return song;
    })
  );
  return {
    ...rest,
    pages: updatedData
  };
}
