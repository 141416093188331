import React from 'react';
import { Menu as MenuMantain, FloatingPosition } from '@mantine/core';

import style from './menu.module.css';

const CLASSES = {
  dropdown: style.dropdown,
  divider: style.divider,
  label: style.label,
  item: style.item
};

export interface MenuOptionProps {
  label: string;
  // TODO: Rewrite code to use MouseEvent instead of unknown
  onClick?: (event?: unknown) => void;
  isLabel?: boolean;
  hasDivider?: boolean;
  color?: string;
}

interface MenuProps {
  options: MenuOptionProps[];
  trigger?: 'click' | 'hover' | 'click-hover';
  position?: FloatingPosition;
  keepMounted?: boolean;
  children: React.ReactNode;
  onClose?: () => void;
}

export const Menu = (props: MenuProps) => {
  const {
    children,
    options,
    trigger = 'hover',
    position = 'bottom-end',
    keepMounted = false,
    onClose
  } = props;

  return (
    <MenuMantain
      trigger={trigger}
      onClose={onClose}
      offset={0}
      position={position}
      keepMounted={keepMounted}
      classNames={CLASSES}
    >
      <MenuMantain.Target>{children}</MenuMantain.Target>

      <MenuMantain.Dropdown>
        {options.map(({ isLabel, label, onClick, hasDivider, color }) => {
          if (isLabel) {
            return (
              <React.Fragment key={label}>
                {hasDivider ? <MenuMantain.Divider /> : null}
                <MenuMantain.Label>{label}</MenuMantain.Label>
              </React.Fragment>
            );
          }

          return (
            <React.Fragment key={label}>
              {hasDivider ? <MenuMantain.Divider /> : null}
              <MenuMantain.Item color={color} onClick={onClick}>
                {label}
              </MenuMantain.Item>
            </React.Fragment>
          );
        })}
      </MenuMantain.Dropdown>
    </MenuMantain>
  );
};
