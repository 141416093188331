import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@/hooks';
import { notifications } from '@/utils/notifications.ts';
import { useModalStore } from '@/store';

export const useDeleteVoices = () => {
  const api = useApi({});
  const queryClient = useQueryClient();
  const { closeModal } = useModalStore();

  return useMutation({
    mutationFn: async (id: string): Promise<string> => {
      const response = await api.delete(`/v1/voices/${id}`);

      return response.data;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['models']
      });
      notifications.success('Model has been successfully deleted');
    },
    onError: () => {
      notifications.error('Could not delete Model');
      closeModal();
    }
  });
};
