import { createColumnHelper, CellContext } from '@tanstack/react-table';

import { Collaborator } from '@/models/Participant';
import { ROLE_OPTIONS_AGGREGATED } from '@/constants/song';
import { NameCell, StatusCell } from '@/components';

import { ItemMenuCell } from '../common/itemMenuCell/ItemMenuCell.tsx';

const columnHelper = createColumnHelper<Collaborator>();

// eslint-disable-next-line react-refresh/only-export-components
export const COLUMNS = [
  columnHelper.accessor('name', {
    header: 'NAME',
    cell: (info) => {
      const { name, avatar_url, role } = info.row.original;
      return (
        <NameCell title={name} avatarUrl={avatar_url} subtitle={ROLE_OPTIONS_AGGREGATED[role]} />
      );
    },
    size: 50
  }),
  columnHelper.accessor('split', {
    header: 'SPLIT',
    cell: (info) => (
      <div data-content="cell-split" className="text-hookybase-200 dark:text-hookybase-300">
        {info.getValue()}
      </div>
    ),
    size: 16
  }),
  columnHelper.accessor('status', {
    header: 'STATUS',
    cell: (info) => <StatusCell>{info.getValue().toUpperCase()}</StatusCell>,
    size: 25
  }),
  columnHelper.accessor('id', {
    header: ' ',
    cell: (info: CellContext<Collaborator, string>) => (
      <ItemMenuCell id={info.row.original.id} isHidden={info.row.original.locked} />
    ),
    size: 1
  })
];
