import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '@/hooks';

import { useSongStore } from '@/store';
import { ReleaseFormValues } from '@/pages/Projects';
import { notifications } from '@/utils/notifications';

interface RequestData
  extends Omit<
    ReleaseFormValues,
    'hasUPS' | 'hasISRC' | 'id' | 'status' | 'copyright_year' | 'phono_copyright_year'
  > {
  phono_copyright_year: number;
  copyright_year: number;
  advisory: string;
  song_id: string;
  territory: string | null;
  name: string;
  generate_isrc: boolean;
  generate_upc: boolean;
}

export const useCreateRelease = () => {
  const api = useApi({});
  const queryClient = useQueryClient();
  const { sortOptions, limit, isRequireLicense } = useSongStore();

  return useMutation({
    mutationFn: async (song: RequestData): Promise<RequestData> => {
      const result = await api.post<RequestData>(`/v1/releases`, song);

      return result.data;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['songs', sortOptions.sortBy, sortOptions.sortOrder, limit, isRequireLicense]
      });

      notifications.success('Your project has been scheduled for release');
    }
  });
};
