import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  CheckboxFieldBase,
  DatePickerField,
  InputField,
  NumberInputField,
  TextAreaField,
  Tooltip
} from '@/components';
import { useGetSongReleases } from '@/hooks';
import { useSongStore } from '@/store';
import { TODAY } from '@/constants';

import styles from './components.module.css';

const currentYear = TODAY.getFullYear();

export const ReleaseInfo = () => {
  const { songData } = useSongStore();

  const { data, isSuccess } = useGetSongReleases(songData.id);
  const { control, getValues, reset } = useFormContext();

  useEffect(() => {
    if (isSuccess) {
      const values = getValues();

      if (data) {
        const formValue = {
          ...values,
          subtitle: data.subtitle,
          hasISRC: Boolean(data.isrc),
          hasUPS: Boolean(data.upc_ean),
          rights_holder: data.rights_holder,
          catalog_number: data.catalog_number,
          release_date: new Date(data.release_date),
          phono_copyright_owner: data.phono_copyright_owner,
          phono_copyright_year: `${data.phono_copyright_year || currentYear}`,
          label_name: data.label_name,
          copyright_owner: data.copyright_owner,
          copyright_year: `${data.copyright_year || currentYear}`
        };

        reset(formValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <div className={styles.form}>
      <DatePickerField
        name="release_date"
        label="Release date"
        description="*required"
        control={control}
        placeholder="MM/DD/YYYY*"
      />

      <div className="block text-sm font-semibold text-hookybase-500 dark:text-white w-full mb-2">
        <div className="mb-4">
          Territory
          <Tooltip
            iconBoxClasses="ml-1"
            tooltipContent="PLEASE NOTE: Some releases may not be distributed to all countries based on language, explicit content, licensing, and other various issues that are outside of Hooky’s control."
          />
        </div>

        <CheckboxFieldBase label="Worldwide" checked disabled />
      </div>

      <InputField
        name="upc_ean"
        placeholder="Enter UPC/EAN barcode"
        description="*required"
        control={control}
        disabled
      />

      <InputField
        name="isrc"
        placeholder="Enter ISRC code"
        description="*required"
        control={control}
        disabled
      />

      <div className={styles.yearFields}>
        <TextAreaField
          name="copyright_owner"
          label={
            <div className="flex items-center gap-1">
              Copyright notice
              <Tooltip tooltipContent="Please enter your copyright notice." />
            </div>
          }
          description="*required"
          control={control}
        />

        <NumberInputField
          name="copyright_year"
          label="Copyright year"
          placeholder="Enter year"
          description="*required"
          maxLength={4}
          control={control}
        />
      </div>

      <div className={styles.yearFields}>
        <TextAreaField
          name="phono_copyright_owner"
          label={
            <div className="flex items-center gap-1 w-full">
              Privacy notice
              <Tooltip tooltipContent="Please enter your privacy notice." />
            </div>
          }
          description="*required"
          control={control}
        />

        <NumberInputField
          name="phono_copyright_year"
          label="Privacy year"
          placeholder="Enter year"
          description="*required"
          maxLength={4}
          control={control}
        />
      </div>

      <TextAreaField
        name="rights_holder"
        label={
          <div className="flex items-center gap-1">
            Rights holder/Licensor
            <Tooltip tooltipContent="Please enter your rights holder and licensor information. This should not include a year." />
          </div>
        }
        description="*required"
        control={control}
      />

      <InputField
        name="catalog_number"
        label="Catalog number"
        placeholder="Enter number"
        control={control}
      />
    </div>
  );
};
