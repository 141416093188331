import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type State = {
  hasAgreedTerms: boolean;
};

type Actions = {
  setHasAgreedTerms: (hasAgreedTerms: boolean) => void;
};

export const usePlatformRulesAgreementStore = create<State & Actions>()(
  immer(
    devtools((set) => ({
      hasAgreedTerms: false,
      setHasAgreedTerms: (hasAgreedTerms: boolean) => set({ hasAgreedTerms })
    }))
  )
);
