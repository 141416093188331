import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export interface TypoProps {
  children: ReactNode;
  className?: string;
}

export interface LinkProps {
  children: ReactNode;
  className?: string;
  to: string;
  state?: Record<string, string | Record<string, string>>;
}

export const Typo = {
  h1: ({ children, className = '' }: TypoProps) => (
    <h1
      className={twMerge(
        'text-black dark:text-white text-3xl md:text-4xl font-bold uppercase',
        className
      )}
    >
      {children}
    </h1>
  ),
  h2: ({ children, className = '' }: TypoProps) => (
    <h2
      className={twMerge('text-black dark:text-white text-3xl font-semibold uppercase', className)}
    >
      {children}
    </h2>
  ),
  h3: ({ children, className = '' }: TypoProps) => (
    <h3 className={twMerge('text-black dark:text-white text-xl font-medium uppercase', className)}>
      {children}
    </h3>
  ),
  h4: ({ children, className = '' }: TypoProps) => (
    <h4 className={twMerge('text-black dark:text-white text-lg font-medium uppercase', className)}>
      {children}
    </h4>
  ),
  h5: ({ children, className = '' }: TypoProps) => (
    <h5
      className={twMerge(
        'text-black dark:text-hookybase-200 text-sm font-bold uppercase',
        className
      )}
    >
      {children}
    </h5>
  ),
  h6: ({ children, className = '' }: TypoProps) => (
    <h6 className={twMerge('text-hookybase-200 text-xs font-bold uppercase', className)}>
      {children}
    </h6>
  ),
  p: ({ children, className = '' }: TypoProps) => (
    <p className={twMerge('text-black dark:text-white text-base', className)}>{children}</p>
  ),
  Link: ({ to, state, children, className = '' }: LinkProps) => (
    <Link
      className={twMerge(
        ' text-black dark:text-white hover:text-hookyyellow-500 cursor-pointer border-b border-dotted hover:border-solid border-white text-sm md:text-base',
        className
      )}
      to={to}
      state={state}
    >
      {children}
    </Link>
  )
};
