import React from 'react';
import { useMantineTheme } from '@mantine/core';

import { IconButton } from '@/components';
import { ArrowRightCircleIcon } from '@/assets/icons';

import { PAYMENT_CONTENT_MODE } from '../../constants';
import styles from '../../../account.module.css';

interface TitlePaymentCardProps {
  mode: string;
  onChangeMode: (mode: string) => void;
}

export const TitlePaymentCard = (props: TitlePaymentCardProps) => {
  const { mode, onChangeMode } = props;

  const { colors } = useMantineTheme();

  const handleChangeMode = (event: React.MouseEvent<HTMLButtonElement>) => {
    const modeName = (event.target as HTMLButtonElement).getAttribute('data-mode') || '';

    onChangeMode(modeName);
  };

  if (mode === PAYMENT_CONTENT_MODE.EDIT) {
    return (
      <div className={styles.titleChangeBox}>
        <IconButton size="md" data-mode={PAYMENT_CONTENT_MODE.VIEW} onClick={handleChangeMode}>
          <ArrowRightCircleIcon color={colors.dark[2]} />
        </IconButton>

        <h2 className={styles.titleMedium}>CHANGE CARD</h2>
      </div>
    );
  }

  return (
    <div className={styles.titleViewBox}>
      <h2 className={styles.titleMedium}>MY CARD</h2>
    </div>
  );
};
