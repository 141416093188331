type StyleProps = {
  variant: 'pill' | 'text' | 'square';
  size: 'xs' | 'sm' | 'md' | 'lg';
};

export const getTabsActiveClasses = ({ variant, size }: StyleProps) => {
  let classes = '';

  switch (variant) {
    case 'text':
      classes = 'rounded-none border-0 pr-6 ';
      break;
    case 'square':
      classes = 'rounded-md border px-6 ';
      break;
    case 'pill':
    default:
      classes = 'rounded-full border px-6';
      break;
  }

  switch (size) {
    case 'xs':
      classes += ' text-xs';
      break;
    case 'md':
      classes += ' text-md';
      break;
    case 'lg':
      classes += ' text-xl';
      break;
    case 'sm':
    default:
      classes += ' text-sm';
      break;
  }

  const activeClasses =
    variant === 'text'
      ? 'text-black dark:text-white'
      : 'bg-hookybase-100 text-black dark:bg-white text-hookybase-600 border-hookybase-100 dark:border-white';
  const inactiveClasses =
    variant === 'text'
      ? 'text-hookybase-200 border-hookybase-400 dark:text-hookybase-300 dark:hover:text-hookybase-100 focus:border-white dark:hover:border-white'
      : 'bg-hookybase-50 dark:bg-hookybase-400 dark:border-hookybase-400 text-hookybase-200 hover:text-hookybase-300 focus:border-white hover:border-hookybase-200 dark:hover:border-white';

  return { classes, activeClasses, inactiveClasses };
};
