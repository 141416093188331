import { ReactNode } from 'react';
import { MantineSize, Modal } from '@mantine/core';

import { useModalStore } from '@/store';
import { ButtonBackgroundProps, ButtonLib, ButtonVariantProps } from '@/components';

import styles from './modal-confirm.module.css';

export interface OneButtonProps {
  text: string;
  variant: ButtonVariantProps;
  background?: ButtonBackgroundProps;
  size?: MantineSize;
}

interface ModalConfirmProps {
  name: string;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  leftButtonText?: string;
  rightButtonText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConfirm: (data: any) => void;
  onClose?: () => void;
  isLoading?: boolean;
  oneButton?: OneButtonProps;
}

const CLASSES = {
  content: styles.content,
  title: styles.title,
  body: styles.body,
  close: styles.close
};

export const ModalConfirmDanger = (props: ModalConfirmProps) => {
  const {
    name,
    title,
    subtitle,
    rightButtonText = 'Yes',
    leftButtonText = 'No',
    onConfirm,
    onClose,
    isLoading,
    oneButton
  } = props;

  const { modalOption, closeModal } = useModalStore();

  if (modalOption.name !== name) {
    return null;
  }

  const handleClose = () => {
    if (onClose) {
      onClose();

      return;
    }

    closeModal();
  };

  const onConfirmClick = () => {
    onConfirm(modalOption);
  };

  return (
    <Modal
      opened={true}
      onClose={closeModal}
      title={title}
      size="450px"
      centered
      classNames={CLASSES}
    >
      {subtitle}

      <div className={styles.footer}>
        {oneButton ? (
          <ButtonLib
            variant={oneButton.variant || 'filled'}
            background={oneButton.background || 'primary'}
            size={oneButton.size}
            className="mx-auto"
            onClick={handleClose}
          >
            {oneButton.text}
          </ButtonLib>
        ) : (
          <>
            <ButtonLib
              variant="outline"
              background="secondary"
              className="min-w-[170px] mr-4"
              onClick={handleClose}
            >
              {leftButtonText}
            </ButtonLib>

            <ButtonLib
              variant="outline"
              background="fourth"
              className="min-w-[170px]"
              onClick={onConfirmClick}
              loading={isLoading}
            >
              {rightButtonText}
            </ButtonLib>
          </>
        )}
      </div>
    </Modal>
  );
};
