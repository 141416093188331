import React from 'react';
import { tv, type VariantProps } from 'tailwind-variants';

const button = tv({
  base:
    'pointer font-sans font-semibold uppercase p-0 rounded-full border text-center ' +
    'inline-flex items-center justify-center disabled:opacity-25',
  variants: {
    variant: {
      primary:
        'text-black bg-hookyyellow-500 border-hookyyellow-500 ' +
        'disabled:bg-hookybase-350 disabled:border-hookybase-350 disabled:text-hookybase-400 ' +
        'enabled:hover:text-black enabled:hover:bg-hookyyellow-600 enabled:hover:border-hookyyellow-600 ' +
        'active:text-black active:bg-hookyyellow-600 active:border-hookyyellow-600',
      primaryBlack:
        'text-hookyyellow-500 bg-black border-black ' +
        'enabled:hover:text-hookyyellow-500 enabled:hover:bg-hookybase-700 enabled:hover:border-hookybase-700 ' +
        'active:text-hookyyellow-500 active:bg-hookybase-700 active:border-hookybase-700',
      secondary:
        'text-hookybase-300 dark:text-white bg-transparent border-hookybase-300 dark:border-white ' +
        'enabled:hover:text-black enabled:hover:bg-hookyyellow-500 enabled:hover:border-hookyyellow-500 ' +
        'active:text-black active:bg-hookyyellow-500 active:border-hookyyellow-500',
      secondaryOutline:
        'text-hookybase-400 dark:text-white bg-transparent border-hookybase-300 ' +
        'enabled:hover:text-hookybase-500 dark:enabled:hover:text-white enabled:hover:bg-transparent enabled:hover:border-hookybase-600 dark:enabled:hover:border-hookybase-200 ' +
        'active:text-white active:bg-transparent active:border-hookybase-200',
      secondaryError:
        'text-white bg-hookyred-500 border-hookyred-500 ' +
        'enabled:hover:text-white enabled:hover:bg-hookyred-500 enabled:hover:border-hookyred-500 ' +
        'active:text-white active:bg-hookyred-500 active:border-hookyred-500',
      secondaryErrorOutline:
        'text-hookyred-500 bg-transparent border-hookyred-500 ' +
        'enabled:hover:text-hookyred-600 dark:enabled:hover:text-hookyred-600 enabled:hover:bg-transparent enabled:hover:border-hookyred-600 ' +
        'active:text-hookyred-600 active:bg-transparent active:text-hookyred-600 active:border-hookyred-600',
      secondarySuccess:
        'text-black bg-hookygreen-500 border-hookygreen-500 ' +
        'enabled:hover:text-black enabled:hover:bg-hookygreen-500 enabled:hover:border-hookygreen-500 ' +
        'active:text-black active:bg-hookygreen-500 active:border-hookygreen-500',
      tertiary:
        'border-none font-medium normal-case bg-transparent h-auto px-0 ' +
        'dark:text-white enabled:hover:text-hookyyellow-500 active:text-hookyyellow-500',
      thirdparty:
        'dark:text-white normal-case justify-start bg-transparent border-hookybase ' +
        'enabled:hover:text-white enabled:hover:bg-transparent enabled:hover:border-white ' +
        'active:text-white active:bg-transparent active:border-white'
    },
    size: {
      sm: 'h-6 px-9 text-xs',
      md: 'h-8 px-20 text-sm',
      lg: 'h-12 px-24 text-base',
      lg2: 'h-12 px-8 text-base',
      none: ''
    }
  },
  defaultVariants: {
    variant: undefined,
    size: 'none'
  }
});

type ButtonVariants = VariantProps<typeof button>;

interface ButtonProps extends ButtonVariants, React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, className, icon, variant, size, ...rest } = props;

  return (
    <button
      className={button({
        ...props,
        // "className" is incorrectly required in tailwind-variants's type definition
        className: className || ''
      })}
      {...rest}
    >
      {props.variant === 'thirdparty' && <div className="w-6 h-6 mr-6 ml-8">{props.icon}</div>}
      {props.children}
    </button>
  );
};
