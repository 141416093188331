import { SortOrder } from '@/models/common.ts';

export const SORT_ORDERS: SortOrder = {
  ASC: 'asc',
  DESC: 'desc'
};

export const DEFAULT_LOADING_LIMIT_ITEM = 25;

export const DRAWER_ID_CONTENT = 'drawer-content';

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

export const TODAY = new Date();

export const DENY_SONG_MODAL = 'deny_song';

export const UPLOAD_TRACK_MODAL = 'upload_track';
export const UPLOAD_VOCALS_MODAL = 'upload_vocal';
export const MODAL_CONFIRM_TEMP_TRACKS_DELETE = 'MODAL_CONFIRM_TEMP_TRACKS_DELETE';

// TODO: Temporary modal window, remove on post MVP
export const UPCOMING_FEATURE_INFO_MODAL = 'UPCOMING_FEATURE_INFO_MODAL';

export const ALLOWED_SONG_EXTS = [
  '.wav',
  '.aiff',
  '.aif',
  '.mp3',
  'audio/x-aiff',
  'audio/mpeg',
  'audio/x-wav'
];
