import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';

import { PlaySongCell } from '@/components';
import { DEFAULT_DATE_FORMAT } from '@/constants';

import { ItemMenuCell } from './components/ItemMenuCell';
import { VoiceModel } from '@/models/VoiceModel.ts';
import { getVisibilityColor, MODEL_STATUS } from '@/pages/Models/utils';

const columnHelper = createColumnHelper<VoiceModel>();

export const RELEASED_SONGS_COLUMN = [
  columnHelper.accessor('name', {
    header: 'TITLE',
    cell: (info) => {
      const { avatar_url, name, visibility, audio_preview, voice_id } = info.row.original;

      return (
        <PlaySongCell
          data={{
            image_url: avatar_url,
            title: name,
            subtitle: visibility,
            master_url: audio_preview?.url || '',
            id: voice_id
          }}
          subtitleColor={getVisibilityColor(visibility)}
          isCircle={true}
        />
      );
    },
    size: 35
  }),
  columnHelper.accessor('created_at', {
    header: 'DATE',
    cell: (info) => (
      <div className="text-hookybase-200 dark:text-hookybase-300">
        {dayjs(info.getValue()).format(DEFAULT_DATE_FORMAT)}
      </div>
    ),
    size: 8
  }),
  columnHelper.accessor('status', {
    header: 'STATUS',
    cell: (info) =>
      MODEL_STATUS[info.getValue()] ? (
        <div className="text-xs font-semibold uppercase text-center border border-hookybase-350 rounded-3xl py-1 px-4 max-w-[10rem]">
          {MODEL_STATUS[info.getValue()]}
        </div>
      ) : null,
    size: 10
  }),
  columnHelper.accessor('voice_id', {
    header: ' ',
    cell: (info) => <ItemMenuCell data={info.row.original} />,
    size: 1
  })
];
