import { useEffect } from 'react';
import { useUpdateMaster } from '@/hooks';
import { useModalStore } from '@/store';
import { UploadedObject } from '@/models/common';
import { ALLOWED_SONG_EXTS } from '@/constants';
import { UPLOAD_MASTER_MODAL_NAME } from '@/constants/song';
import { ModalFileUploadDropBox } from '@/components';

const MASTER_FILE_EXTENS = ALLOWED_SONG_EXTS.filter(
  (ext) => !['.mp3', 'audio/mpeg'].includes(ext)
).join(',');

export const UploadMasterModal = () => {
  const { mutate: updateMaster, isLoading, isSuccess, isError } = useUpdateMaster();
  const { closeModal } = useModalStore();

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess, closeModal]);

  const onMasterUpload = ({ files, voiceId }: UploadedObject) => {
    if (files) {
      const formData = new FormData();
      formData.append('file', (files as FileList)[0]);

      updateMaster({ voiceId: voiceId as string, file: formData });
    }
  };

  return (
    <ModalFileUploadDropBox
      modalName={UPLOAD_MASTER_MODAL_NAME}
      modalTitle="UPLOAD MASTER"
      isLoading={isLoading}
      onUpload={onMasterUpload}
      hasError={isError}
      acceptFileExtensions={MASTER_FILE_EXTENS}
    />
  );
};
