import { createColumnHelper } from '@tanstack/react-table';
import { Collaborator } from '@/models/Participant.ts';
import { ROLE_OPTIONS_AGGREGATED } from '@/constants/song';
import { NameCell, StatusCell } from '@/components';

import { ItemMenuCell } from '../common';

const columnHelper = createColumnHelper<Collaborator>();
// eslint-disable-next-line react-refresh/only-export-components
export const COLUMNS = [
  columnHelper.accessor('name', {
    header: 'NAME',
    cell: (info) => {
      const { avatar_url, name, role } = info.row.original;
      return (
        <NameCell avatarUrl={avatar_url} title={name} subtitle={ROLE_OPTIONS_AGGREGATED[role]} />
      );
    },
    size: 50
  }),
  columnHelper.accessor('split', {
    header: 'SPLIT',
    cell: (info) => (
      <div data-content="cell-split" className="text-hookybase-200 dark:text-hookybase-300">
        {info.getValue()}
      </div>
    ),
    size: 20
  }),
  columnHelper.accessor('status', {
    header: 'STATUS',
    cell: (info) => <StatusCell>{info.getValue().toUpperCase()}</StatusCell>,
    size: 25
  }),
  columnHelper.accessor('id', {
    header: ' ',
    cell: (info) => <ItemMenuCell id={info.row.original.id} />,
    size: 5
  })
];
