import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCookies } from 'react-cookie';

import { useApi } from '@/hooks';
import { useSongStore } from '@/store';
import { notifications } from '@/utils/notifications';

export const useApproveCollaboration = () => {
  const api = useApi({});
  const queryClient = useQueryClient();
  const { sortOptions } = useSongStore();
  const [cookies, setCookie] = useCookies(['invite_code']);

  return useMutation({
    mutationFn: async (id: string): Promise<void> => {
      const response = await api.post(
        `/v1/collaborations/${id}/approve?invite_code=${cookies.invite_code}`
      );

      setCookie('invite_code', '');

      return response.data;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['collaborations', sortOptions.sortBy, sortOptions.sortOrder, cookies.invite_code]
      });
    },
    onError: () => {
      notifications.error("Couldn't approve song");
    }
  });
};
