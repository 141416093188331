import { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { ROLES } from '@/models/User';
import { useUserRolesStore } from '@/store';

type PrivateRouteProps = {
  permissions: ROLES[];
  children: ReactNode;
};
export const PermissionRoute = ({ permissions, children }: PrivateRouteProps) => {
  const userRoles = useUserRolesStore((state) => state.userRoles);

  return userRoles.some((role) => permissions.includes(role)) ? (
    children || <Outlet />
  ) : (
    <Navigate to="/" />
  );
};
