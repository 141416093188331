const isNumber = (value: string | number): value is number => typeof value === 'number';

export const getPrice = (amount: string | number, interval?: 'month' | 'year') =>
  isNumber(amount) ? `$${amount}/${interval === 'year' ? 'year' : 'mo.'}` : amount;

export const convertByteToMb = (bytes?: number) =>
  `${Number(Math.round(Number(bytes) / 10485.76) + 'e-2')}MB`;

export const convertSecondsToMinutes = (seconds?: number) =>
  new Date(Number(seconds ?? 0) * 1000).toISOString().substring(14, 19);
