import { SVGAttributes } from 'react';

const VerifiedIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="8" y="10" width="15" height="12" fill="white" />
      <path
        d="M11.4667 30L8.93333 25.7333L4.13333 24.6667L4.6 19.7333L1.33333 16L4.6 12.2667L4.13333 7.33333L8.93333 6.26667L11.4667 2L16 3.93333L20.5333 2L23.0667 6.26667L27.8667 7.33333L27.4 12.2667L30.6667 16L27.4 19.7333L27.8667 24.6667L23.0667 25.7333L20.5333 30L16 28.0667L11.4667 30ZM14.6 20.7333L22.1333 13.2L20.2667 11.2667L14.6 16.9333L11.7333 14.1333L9.86667 16L14.6 20.7333Z"
        fill="#0072CE"
      />
    </svg>
  );
};

VerifiedIcon.displayName = 'Verified';

export default VerifiedIcon;
