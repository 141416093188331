import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type State = {
  isNavOpen: boolean;
};

type Actions = {
  setIsNavOpen: (isOpenDrawer: boolean) => void;
};

export const useNavStore = create<State & Actions>()(
  immer(
    devtools((set) => ({
      isNavOpen: false,
      setIsNavOpen: (isNavOpen: boolean) => set({ isNavOpen })
    }))
  )
);
