import { ChangeEvent, FC, ForwardedRef, forwardRef } from 'react';
import { Switch as SwitchMantine } from '@mantine/core';
import styles from './switch.module.css';

const getClasses = (isGray: boolean) => ({
  track: `${styles.track} ${isGray ? styles.trackGray : ''}`,
  thumb: `${styles.thumb} ${isGray ? styles.thumbGray : ''}`,
  trackLabel: styles.trackLabel,
  input: styles.input
});

interface SwitchProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  id?: string;
  disabled?: boolean;
  isGray?: boolean;
}

export const Switch: FC<SwitchProps> = forwardRef(
  ({ id, onChange, checked, disabled, isGray = false, ...field }, ref) => (
    <SwitchMantine
      {...(id && { id })}
      {...field}
      ref={ref as ForwardedRef<HTMLInputElement>}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      classNames={getClasses(isGray)}
    />
  )
);
