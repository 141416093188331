import { SortOrderOptionType } from '@/models/common.ts';
import { SORT_ORDERS } from '@/constants';
import { ArrowBackIcon } from '@/assets/icons';

import styles from './sort-menu.module.css';

export const getSortOrderIcon = (isActive: boolean, order: SortOrderOptionType) => {
  if (!isActive) {
    return null;
  }

  if (order === SORT_ORDERS.ASC) {
    return <ArrowBackIcon width="24px" height="24px" className={styles.arrowUp} />;
  }

  return <ArrowBackIcon width="24px" height="24px" className={styles.arrowDown} />;
};
