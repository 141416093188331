import { useUppyFileUploader } from '@/hooks';
import { ModalConfirmDanger, ModalFileUploadDropBox } from '@/components';
import { UPLOAD_VOCALS_MODAL } from '@/constants';
import { useModalStore } from '@/store';

const CANCEL_UPLOADING_MODAL = `${UPLOAD_VOCALS_MODAL}.CANCEL_UPLOADING_MODAL`;

export const ModalSongDropBoxUploader = () => {
  const { uploadProgress, uploadFiles, isUploading, cancelUploading } = useUppyFileUploader();
  const { openModal, closeModal } = useModalStore();

  const handleCancel = () => {
    if (cancelUploading) {
      void cancelUploading();

      closeModal();
    }
  };

  const handleClose = async () => {
    // eslint-disable-next-line
    await closeModal();

    if (isUploading) {
      openModal({
        name: CANCEL_UPLOADING_MODAL
      });

      return;
    }
  };

  const handleCloseConfirmModal = () => {
    openModal({
      name: UPLOAD_VOCALS_MODAL
    });
  };

  return (
    <>
      <ModalFileUploadDropBox
        modalName={UPLOAD_VOCALS_MODAL}
        modalTitle="UPLOAD VOCALS"
        onUpload={uploadFiles}
        closeOnClickOutside={!isUploading}
        uploadProgress={uploadProgress}
        onClose={handleClose}
      />

      <ModalConfirmDanger
        name={CANCEL_UPLOADING_MODAL}
        subtitle="Are you sure you would like to cancel your new song?
Please note: all work will be lost and will still impact your credits"
        title="Cancel song"
        onConfirm={handleCancel}
        onClose={handleCloseConfirmModal}
      />
    </>
  );
};
