import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@/hooks';
import { IParticipant, ParticipantsApiParams, ParticipantType } from '@/models/Participant.ts';
import { notifications } from '@/utils/notifications';

interface IParticipantBody {
  name: string;
  type: ParticipantType;
  role?: string;
  email?: string;
}

export const usePostParticipant = ({ workType = 'songs', workId }: ParticipantsApiParams) => {
  const api = useApi({});
  const queryClient = useQueryClient();

  return useMutation<IParticipant, AxiosError, IParticipantBody>({
    mutationFn: async (participant: IParticipantBody): Promise<IParticipant> => {
      const response = await api.post<IParticipant>(
        `/v1/${workType}/${workId}/participants`,
        participant
      );
      return response.data;
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ['participants', workType, workId, variables.type]
      });

      notifications.success(`New ${variables.type} is added successfully`);
    },
    onError: (_, variables) => {
      notifications.error(`Could not add new ${variables.type}`);
    }
  });
};
