import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { VoiceModel } from '@/models/VoiceModel.ts';
import { SortOptionsProps } from '@/models/common.ts';
import { SORT_ORDERS } from '@/constants';
// import { SORT_ORDERS, DEFAULT_LOADING_LIMIT_ITEM } from '@/utils';
// import { TAB_NAME } from '@/utils/song';

export type State = {
  modelData: VoiceModel | null;
  sortOptions: SortOptionsProps;
  // modelActiveTab: string;
  isModelEditable: boolean;
  // isRequireLicense: boolean;
  // limit: number;
};

type Actions = {
  setModelData: (model: VoiceModel | null) => void;
  setSortOptions: (options: SortOptionsProps) => void;
  // setActiveModelTab: (tabName: string) => void;
  setIsModelEditable: (value: boolean) => void;
  // setIsRequireLicense: (value: boolean) => void;
};

const INITIAL_VALUE: State = {
  modelData: null,
  sortOptions: {
    sortBy: 'status',
    sortOrder: SORT_ORDERS.ASC
  },
  // modelActiveTab: TAB_NAME.vocalAIVocal,
  isModelEditable: true
  // isRequireLicense: true,
  // limit: DEFAULT_LOADING_LIMIT_ITEM
};

export const useModelStore = create<State & Actions>()(
  immer(
    devtools((set) => ({
      ...INITIAL_VALUE,
      setModelData: (model) => set({ modelData: model }),
      setSortOptions: (options) => set({ sortOptions: options }),
      // setActiveModelTab: (tabName) => set({ modelActiveTab: tabName }),
      setIsModelEditable: (isModelEditable) => set({ isModelEditable })
      // setIsRequireLicense: (isRequireLicense) => set({ isRequireLicense })
    }))
  )
);
