import { ROUTES } from '@/routes/routes.tsx';

export interface MenuSetting {
  key: string;
  titleGroup: string;
  items: {
    key: string;
    link: string;
    title: string;
  }[];
}

export const MENU_SETTINGS: MenuSetting[] = [
  {
    key: '1',
    titleGroup: 'Profile',
    items: [
      {
        key: '11',
        link: ROUTES.ACCOUNT_HOME,
        title: 'My Account'
      },
      {
        key: '12',
        link: ROUTES.ACCOUNT_PROFILE,
        title: 'Edit Profile'
      }
    ]
  },
  {
    key: '2',
    titleGroup: 'Security',
    items: [
      {
        key: '21',
        link: ROUTES.ACCOUNT_CHANGE_PASSWORD,
        title: 'Change password'
      }
    ]
  },
  {
    key: '3',
    titleGroup: 'Subscription',
    items: [
      {
        key: '31',
        link: ROUTES.ACCOUNT_SUBSCRIPTION,
        title: 'Available Plans'
      }
    ]
  },
  {
    key: '4',
    titleGroup: 'Payment',
    items: [
      {
        key: '41',
        link: ROUTES.ACCOUNT_PAYMENT_DETAILS,
        title: 'Payment details'
      }
    ]
  }
];
