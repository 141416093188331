import { useState, useEffect } from 'react';
import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { useSongStore, useModalStore } from '@/store';
import {
  useGetSongTracks,
  usePostSongTrack,
  useSongPermission,
  useDownloadAllTracks
} from '@/hooks';
import { UPLOAD_TRACK_MODAL } from '@/constants';
import { CollaborationsSongProps } from '@/models/Song.ts';
import { ButtonLib, Table, ModalFileUploadDropBox } from '@/components';

import { COLUMNS } from './columns.tsx';

export const Vocals = () => {
  const [refetchInterval, setRefetchInterval] = useState<false | number>(false);
  const { songData, isSongEditable } = useSongStore();
  const songId = (songData as CollaborationsSongProps).song_id || songData.id;

  const { openModal, closeModal } = useModalStore();
  const theme = useMantineTheme();
  const md = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);
  const { hasReadPermission } = useSongPermission();
  const {
    isLoading: isTrackUploading,
    isError: isTrackUploadError,
    isSuccess: isTrackUploadSuccess,
    mutate: uploadTracks
  } = usePostSongTrack();

  const {
    data: tracks = [],
    isLoading,
    refetch
  } = useGetSongTracks({
    songId,
    refetchInterval
  });

  const { refetch: downloadAll, fetchStatus } = useDownloadAllTracks({
    songId
  });

  const handleOpenUpload = () => openModal({ name: UPLOAD_TRACK_MODAL });
  const handleUploadVocal = ({ files }: { files: FileList }) => {
    if (files) {
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append('tracks', file);
      });

      uploadTracks(formData);
    }
  };

  const handleDownloadAll = async () => {
    const result = await downloadAll();
    const data = result.data as string;
    const a = document.createElement('a');

    document.body.appendChild(a);
    a.setAttribute('href', data);
    a.setAttribute('download', data);
    a.setAttribute('target', '_blank');
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    if (isTrackUploadSuccess) {
      closeModal();
      void refetch();
    }
  }, [isTrackUploadSuccess, closeModal, refetch]);

  // If we're not waiting on any conversions, slow poll
  useEffect(() => {
    const processing = tracks.some((status) =>
      ['uploaded', 'queued', 'processing'].includes(status.status)
    );

    if (processing) {
      setRefetchInterval(2500);
    } else {
      setRefetchInterval(false);
    }
  }, [tracks]);

  const isDownloadingTraks = fetchStatus === 'fetching';

  return (
    <>
      {isSongEditable ? (
        <div className="relative xl:absolute flex flex-wrap gap-4 lg:top-0 lg:right-0">
          <ButtonLib
            background="secondary"
            variant="outline"
            size="sm"
            onClick={handleDownloadAll}
            disabled={isDownloadingTraks || !tracks.length}
            loading={isDownloadingTraks}
          >
            DOWNLOAD ALL
          </ButtonLib>

          {hasReadPermission || songData.status === 'release_song' ? null : (
            <ButtonLib
              background="secondary"
              variant="outline"
              disabled={songData.status === 'release_scheduled'}
              size="sm"
              onClick={handleOpenUpload}
            >
              UPLOAD VOCALS
            </ButtonLib>
          )}
        </div>
      ) : null}

      <Table
        state={{
          columnVisibility: {
            created_at: md,
            id: md
          }
        }}
        columns={COLUMNS}
        data={tracks}
        isLoading={isLoading}
      />

      <ModalFileUploadDropBox
        modalName={UPLOAD_TRACK_MODAL}
        modalTitle="UPLOAD TRACK"
        isLoading={isTrackUploading}
        onUpload={handleUploadVocal}
        hasError={isTrackUploadError}
        closeOnClickOutside={!isTrackUploading}
      />
    </>
  );
};
