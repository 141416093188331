import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type State = {
  isOpenDrawer: boolean;
  setDrawerState: (isOpenDrawer: boolean) => void;
};

type Actions = {
  setDrawerState: (isOpenDrawer: boolean) => void;
};

export const useDrawerStore = create<State & Actions>()(
  immer(
    devtools((set) => ({
      isOpenDrawer: false,
      setDrawerState: (isOpenDrawer: boolean) => set({ isOpenDrawer })
    }))
  )
);
