import { useApi } from '@/hooks';
import { useMutation } from '@tanstack/react-query';
import { IAccount, EditableIAccountPart } from '@/models/Account.ts';
import { notifications } from '@/utils/notifications';

export const usePutAccount = () => {
  const api = useApi({});

  return useMutation<EditableIAccountPart, Error, EditableIAccountPart>({
    mutationFn: async (form: EditableIAccountPart): Promise<IAccount> => {
      const result = await api.put<IAccount>('/v1/account', form);

      return result.data;
    },
    onSuccess: () => {
      notifications.success('User Profile is updated successfully');
    },
    onError: () => {
      notifications.error('Could not update User Profile');
    }
  });
};
