import AccountLayout from '@/layouts/AccountLayout.tsx';
import { DeleteAccountModal, ProfileForm, DeleteAccountConfirmedModal } from './components';

const AccountProfile = () => (
  <AccountLayout
    title="Edit Profile"
    subtitle="Updating to your name and email will reflect throughout the hooky platform"
  >
    <ProfileForm />
    <DeleteAccountModal />
    <DeleteAccountConfirmedModal />
  </AccountLayout>
);

export default AccountProfile;
