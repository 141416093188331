import { useNavigate } from 'react-router-dom';
import { SortOptionsProps } from '@/models/common';
import { ROUTES } from '@/routes/routes.tsx';
import { useModelStore } from '@/store';
import { useGetModels } from '@/hooks';
import { Table, TableSortData, NoDataComponent } from '@/components';
import { useMyModelsTableRowHandler } from '@/pages/Models/hooks/useMyModelsTableRowHandler';
import { useModelContext } from '@/pages/Models/hooks/useModelContext';
import { RELEASED_SONGS_COLUMN } from './columns.tsx';
import { ModelsGridContainer } from './components/ModelsGridContainer';

const Models = () => {
  const navigate = useNavigate();
  const handleRowClick = useMyModelsTableRowHandler();
  const { sortOptions, setSortOptions } = useModelStore();
  const { data: models, isFetching: isModelsFetching } = useGetModels();

  const { isGrid } = useModelContext();

  const handleSort = (options: SortOptionsProps) => {
    setSortOptions(options);
  };

  const sortData: TableSortData = {
    onSort: handleSort,
    sortOptions
  };

  const onTrainModelClick = () => {
    navigate(ROUTES.TRAIN_MODEL);
  };

  return !models || models.length === 0 ? (
    <NoDataComponent
      onClick={onTrainModelClick}
      buttonTitle="TRAIN A MODEL"
      description="You currently have no models. Train a model now!"
    />
  ) : isGrid ? (
    <ModelsGridContainer models={models} />
  ) : (
    <Table
      columns={RELEASED_SONGS_COLUMN}
      data={models}
      sortData={sortData}
      onRowClick={handleRowClick}
      isLoading={isModelsFetching}
    />
  );
};

export default Models;
