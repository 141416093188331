import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools } from 'zustand/middleware';

export type BannerType =
  | 'BANNER_DRAFT_PROJECT'
  | 'BANNER_CREDIT_LIMIT_REACHED'
  | 'BANNER_CREDIT_LIMIT_WARNING'
  | 'BANNER_FREEMIUM_PLAN';

type BannerListState = {
  bannerList: BannerType[];
  _dismissedBannerList: BannerType[];
};

type BannerListActions = {
  addBanner: (bannerId: BannerType) => void;
  removeBanner: (bannerId: BannerType) => void;
};

export const useTopNotificationBannerQueueStore = create<BannerListState & BannerListActions>()(
  immer(
    devtools((set) => ({
      bannerList: [] as BannerType[],
      _dismissedBannerList: [] as BannerType[],
      addBanner: (bannerId: BannerType) =>
        set((state) => {
          if (state.bannerList.includes(bannerId) || state._dismissedBannerList.includes(bannerId))
            return {};
          const list = [...state.bannerList];
          if (bannerId === 'BANNER_DRAFT_PROJECT') {
            list.unshift(bannerId);
          } else {
            list.push(bannerId);
          }
          return { bannerList: list };
        }),
      removeBanner: (bannerId: BannerType) =>
        set((state) => {
          const list = new Set(state._dismissedBannerList);
          list.add(bannerId);
          return {
            bannerList: state.bannerList.filter((banner) => banner !== bannerId),
            _dismissedBannerList: [...list]
          };
        })
    }))
  )
);
