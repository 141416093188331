import { useMemo } from 'react';
import { useGetPlans, useGetSubscription } from '@/hooks';

export const useGetCurrentPlan = () => {
  const { data: plans } = useGetPlans();
  const { data: currentSubscription } = useGetSubscription();

  return useMemo(() => {
    if (plans?.length && currentSubscription) {
      return plans.find((p) => p.id === currentSubscription.plan_id);
    }
  }, [plans, currentSubscription]);
};
