import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useApi } from '@/hooks';
import { ISong } from '@/models/Song';
import { useSongStore } from '@/store';
import { notifications } from '@/utils/notifications';
import { SongQueryData, updateSongCache } from '@/utils/updateSongCache.ts';

interface UpdateMasterProps {
  voiceId: string;
  file: FormData;
}

export const useUpdateMaster = (): UseMutationResult<ISong, AxiosError, UpdateMasterProps> => {
  const api = useApi({});
  const queryClient = useQueryClient();
  const { sortOptions, songData, setSongData, limit, isRequireLicense } = useSongStore();

  return useMutation<ISong, AxiosError, UpdateMasterProps>({
    mutationFn: async ({ voiceId, file }: UpdateMasterProps): Promise<ISong> => {
      const response = await api.post<ISong>(`/v1/songs/${voiceId}/master`, file);

      return response.data;
    },
    onSuccess: (data: ISong) => {
      if (songData.id) {
        setSongData(data);
      }

      queryClient.setQueryData<unknown>(
        ['songs', sortOptions.sortBy, sortOptions.sortOrder, limit, isRequireLicense],
        (oldData: SongQueryData) => updateSongCache(oldData, data)
      );
    },
    onError: () => {
      notifications.error("Couldn't upload master");
    }
  });
};
