import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { Icon } from '@/components';

interface LoaderProps {
  className?: string;
}

export const Loader: FC<LoaderProps> = ({ className }) => {
  return (
    <section
      className={twMerge(
        'border-t-2 border-hookybase-50 dark:border-hookybase-500 mt-8 pt-8 px-8 py-36 flex items-center justify-center',
        className
      )}
    >
      <Icon icon="Spinner" className="animate-spin w-16 h-16" />
    </section>
  );
};
