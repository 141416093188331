import React, { useEffect } from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useModalStore, useSongStore } from '@/store';
import { TODAY } from '@/constants';
import { ADVISOR_OPTION, TITLE_VERSIONS } from '@/constants/song';
import { RELEASE_MODAL_NAME } from './constants';

export interface ReleaseFormValues {
  status: string;
  id: string;
  subtitle: string;
  display_artist: string;
  audio_language: string;
  genre_id: string;
  advisory: string | boolean;
  upc_ean: string;
  isrc: string;
  copyright_owner: string;
  copyright_year: string;
  phono_copyright_owner: string;
  phono_copyright_year: string;
  rights_holder: string;
  catalog_number?: string;
  release_date: string;
  label_name: string;
  hasUPS: boolean;
  hasISRC: boolean;
}

interface ValidationParams {
  status: string;
  schema: Yup.StringSchema<string | undefined>;
  message: string;
  isForceValidation?: boolean;
  min?: {
    limit: number;
    message: string;
  };
  max?: {
    limit: number;
    message: string;
  };
}

function validationFunc({
  status,
  schema,
  message,
  min,
  max,
  isForceValidation
}: ValidationParams) {
  const isReleaseStatus = isForceValidation || status === 'release_scheduled';

  return isReleaseStatus
    ? schema
        .required(message)
        .when('status', (_, schema) => (min ? schema.min(min.limit, min.message) : schema))
        .when('status', (_, schema) => (max ? schema.max(max.limit, max.message) : schema))
    : schema
        .optional()
        .when('status', (_, schema, form) =>
          form.value && min ? schema.min(min.limit, min.message) : schema
        )
        .when('status', (_, schema, form) =>
          form.value && max ? schema.max(max.limit, max.message) : schema
        );
}

const currentYear = TODAY.getFullYear();

const getSchema = (isForceValidation: boolean, voiceModelName: string) =>
  Yup.object().shape({
    status: Yup.string().optional(),
    subtitle: Yup.string().optional(),
    display_artist: Yup.string()
      .when('status', ([status], schema) =>
        validationFunc({
          isForceValidation,
          status,
          schema,
          message: 'Display artist name is required',
          min: {
            limit: 3,
            message: 'Display artist must be at least 3 character'
          },
          max: {
            limit: 100,
            message: 'Max value 100 symbols'
          }
        })
      )
      .matches(new RegExp(`${voiceModelName} AI`), 'Display artist must contain Voice Model Name'),
    audio_language: Yup.string().when('status', ([status], schema) =>
      validationFunc({ isForceValidation, status, schema, message: 'Track language is required' })
    ),
    genre_id: Yup.string().when('status', ([status], schema) =>
      validationFunc({ isForceValidation, status, schema, message: 'Genre is required' })
    ),
    advisory: Yup.string().when('status', ([status], schema) =>
      validationFunc({ isForceValidation, status, schema, message: 'Advisory is required' })
    ),
    hasUPS: Yup.boolean().optional(),
    upc_ean: Yup.string().when('hasUPS', ([hasUPS], schema) => {
      return hasUPS
        ? schema
            .required('Please enter a valid UPC or EAN barcode number')
            .test(
              'upc_ean',
              'UPC barcodes are 12-digit numeric codes or EAN barcodes can be 8, 13, or 14 digits long.',
              (val) => [12, 8, 13, 14].includes(val.length)
            )
        : schema.optional();
    }),
    hasISRC: Yup.boolean().optional(),
    isrc: Yup.string().when('hasISRC', ([hasISRC], schema) => {
      return hasISRC
        ? schema.required('Please enter a valid ISRC code').max(12, 'Max value 12 symbols')
        : schema.optional();
    }),
    copyright_owner: Yup.string().when('status', ([status], schema) =>
      validationFunc({
        isForceValidation,
        status,
        schema,
        message: 'Copyright notice is required',
        min: {
          limit: 3,
          message: 'Copyright notice must be at least 3 character'
        },
        max: {
          limit: 500,
          message: 'Max value 500 symbols'
        }
      })
    ),
    copyright_year: Yup.string().when('status', ([status], schema) =>
      validationFunc({ isForceValidation, status, schema, message: 'Copyright year is required' })
    ),
    phono_copyright_owner: Yup.string().when('status', ([status], schema) =>
      validationFunc({
        isForceValidation,
        status,
        schema,
        message: 'Privacy notice is required',
        min: {
          limit: 3,
          message: 'Privacy notice must be at least 3 character'
        },
        max: {
          limit: 500,
          message: 'Max value 500 symbols'
        }
      })
    ),
    phono_copyright_year: Yup.string().when('status', ([status], schema) =>
      validationFunc({ isForceValidation, status, schema, message: 'Privacy year is required' })
    ),
    rights_holder: Yup.string().when('status', ([status], schema) =>
      validationFunc({
        isForceValidation,
        status,
        schema,
        message: 'Rights holder/Licensor is required',
        min: {
          limit: 3,
          message: 'Rights holder/Licensor must be at least 3 character'
        },
        max: {
          limit: 500,
          message: 'Max value 500 symbols'
        }
      })
    ),
    release_date: Yup.string().when('status', ([status], schema) =>
      validationFunc({
        isForceValidation,
        status,
        schema,
        message: 'Release date is required'
      })
    ),
    label_name: Yup.string().when('status', ([status], schema) =>
      validationFunc({
        isForceValidation,
        status,
        schema,
        message: 'Record label is required',
        min: {
          limit: 3,
          message: 'Record label must be at least 3 character'
        },
        max: {
          limit: 50,
          message: 'Max value 50 symbols'
        }
      })
    )
  });

export const ReleaseFormContext = ({ children }: { children: React.ReactNode }) => {
  const { songData } = useSongStore();
  const { modalOption } = useModalStore();
  const formContext = useForm<ReleaseFormValues>({
    resolver: yupResolver(
      getSchema(modalOption.name === RELEASE_MODAL_NAME, songData?.voices?.[0].name)
    ) as unknown as Resolver<ReleaseFormValues>
  });

  useEffect(() => {
    if (songData.id) {
      const formValue = {
        id: songData.id,
        subtitle: songData.subtitle || TITLE_VERSIONS[0].value,
        display_artist: songData.display_artist,
        audio_language: songData.audio_language || 'en',
        genre_id: songData.genre_id,
        advisory: songData.advisory === ADVISOR_OPTION.explicit,
        upc_ean: songData.upc_ean,
        isrc: '',
        copyright_owner: songData.copyright_owner || '',
        copyright_year: `${songData.copyright_year || currentYear}`,
        phono_copyright_owner: songData.phono_copyright_owner || '',
        phono_copyright_year: `${songData.phono_copyright_year || currentYear}`,
        rights_holder: songData.rights_holder || '',
        catalog_number: songData.catalog_number,
        release_date: songData.release_date,
        status: songData.status,
        hasISRC: false,
        hasUPS: false,
        label_name: songData.label_name,
        name: songData.name
      };

      formContext.reset(formValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [songData.id]);

  return <FormProvider {...formContext}>{children}</FormProvider>;
};
