import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { Modal as ModalCore, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import {
  ButtonLib,
  CheckboxFieldBase,
  DatePickerField,
  Icon,
  InputField,
  Modal,
  NumberInputField,
  SwitchForm,
  Tooltip,
  TextAreaField,
  Tab,
  Tabs,
  SongInfo,
  Splits,
  VoiceAI,
  SongImageUploader
} from '@/components';
import StepperBg from '@/assets/backgrounds/stepperBg';
import CheckIcon from '@/assets/icons/Check';
import { ReleaseFormValues } from '@/pages/Projects';
import { useSongStore, useModalStore } from '@/store';
import { useCreateRelease, useUpdateSongData } from '@/hooks';
import { AdvisoryType, ISong } from '@/models/Song';
import { TODAY } from '@/constants';
import { ADVISOR_OPTION } from '@/constants/song';

import { RELEASE_MODAL_NAME } from './constants';
import styles from './release-modal.module.css';

const TOMORROW_DATE = new Date(TODAY.setDate(TODAY.getDate() + 1));

export const ReleaseModal = () => {
  const { songData } = useSongStore();
  const { closeModal } = useModalStore();
  const theme = useMantineTheme();
  const md = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);

  const { control, setValue, handleSubmit, getValues, trigger, reset } = useFormContext();

  const [activeStep, setActiveStep] = useState(1);
  const [codes, setCodes] = useState({
    hasUPS: false,
    hasISRC: false
  });

  const { mutate: createRelease, isSuccess, isError } = useCreateRelease();
  const {
    mutate: updateSong,
    isSuccess: isSuccessUpdateSong,
    isError: isErrorUpdateSong
  } = useUpdateSongData();

  const handleCloseModal = () => {
    reset();
    setActiveStep(1);
  };

  const handleRelease = handleSubmit((data) => {
    const {
      hasUPS,
      hasISRC,
      advisory,
      upc_ean,
      isrc,
      copyright_year,
      phono_copyright_year,
      release_date,
      label_name,
      ...rest
    } = data as ReleaseFormValues;

    createRelease({
      ...rest,
      copyright_year: +copyright_year,
      phono_copyright_year: +phono_copyright_year,
      advisory: ((advisory as string) === 'true'
        ? ADVISOR_OPTION.explicit
        : ADVISOR_OPTION.none) as AdvisoryType,
      upc_ean: hasUPS ? upc_ean : '',
      isrc: hasISRC ? isrc : '',
      territory: 'ua', // TODO update value
      song_id: songData.id,
      name: songData.name,
      label_name: label_name,
      release_date: dayjs(release_date).toISOString(),
      generate_isrc: hasISRC,
      generate_upc: hasUPS
    });

    updateSong({
      ...rest,
      copyright_year: +copyright_year,
      phono_copyright_year: +phono_copyright_year,
      advisory: ((advisory as string) === 'true'
        ? ADVISOR_OPTION.explicit
        : ADVISOR_OPTION.none) as AdvisoryType,
      name: songData.name
    } as ISong);
  });

  useEffect(() => {
    if (isSuccess && isSuccessUpdateSong) {
      closeModal();
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleNextStep = async () => {
    if (activeStep === 1) {
      const requiredFields = [
        'catalog_number',
        'copyright_owner',
        'copyright_year',
        'phono_copyright_owner',
        'phono_copyright_year',
        'rights_holder',
        'release_date',
        'label_name'
      ];

      const [hasUPS, hasISRC] = getValues(['hasUPS', 'hasISRC']);

      if (hasUPS) {
        requiredFields.push('upc_ean');
      }

      if (hasISRC) {
        requiredFields.push('isrc');
      }

      const result = await trigger(requiredFields);

      if (result) {
        setActiveStep((prevState) => prevState + 1);
      }

      return;
    }

    if (activeStep === 2) {
      setActiveStep((prevState) => prevState + 1);

      void handleRelease();

      return;
    }
  };

  const handleSetPreviusStep = () => {
    setActiveStep((prevState) => prevState - 1);
  };

  const handleToggleSwitch = (name: string, value: boolean) => {
    setCodes((prevState) => ({
      ...prevState,
      [name]: value
    }));
    setValue(name, value);
  };

  return (
    <Modal
      name={RELEASE_MODAL_NAME}
      onClose={handleCloseModal}
      isCentered
      className={styles.modal}
      head={
        <div className={styles.headInner}>
          <h2 className={styles.modalTitle}>CREATE A RELEASE</h2>

          <ModalCore.CloseButton className={styles.closeBtn} />

          <div className={styles.stepper}>
            <StepperBg step={activeStep} />

            <div className={activeStep > 1 ? styles.active : ''}>
              <span>{activeStep > 1 ? <CheckIcon width="40px" height="40px" /> : 1}</span>
              RELEASE
            </div>

            <div className={activeStep > 2 ? styles.active : ''}>
              <span>{activeStep > 2 ? <CheckIcon /> : 2}</span>
              REVIEW
            </div>

            <div>
              <span>3</span>
              SUBMIT
            </div>
          </div>
        </div>
      }
      footer={
        <div className={styles.footer}>
          <div>
            {activeStep !== 1 && (
              <ButtonLib
                background="secondary"
                variant="outline"
                onClick={handleSetPreviusStep}
                disabled={isError || isErrorUpdateSong ? false : activeStep === 3}
              >
                BACK
              </ButtonLib>
            )}
          </div>

          <ButtonLib onClick={handleNextStep} disabled={activeStep === 3}>
            {activeStep === 1 ? 'NEXT' : 'SCHEDULE RELEASE'}
          </ButtonLib>
        </div>
      }
    >
      {activeStep !== 3 && (
        <div className={styles.modalContent}>
          {md ? (
            <div className={styles.songPreview}>
              <SongImageUploader />

              <div className={styles.songTitle}>{songData.name}</div>
              <div className={styles.songSubTitle}>{songData.display_artist}</div>
            </div>
          ) : null}

          {activeStep === 1 ? (
            <div className={styles.form}>
              <div>
                <div>
                  <span className="font-bold mr-1">UPC/EAN Barcode</span>
                  <Tooltip tooltipContent="Both UPC and EAN barcodes are used for inventory management, tracking sales, and for sales purposes. The choice between UPC and EAN often depends on the geographic region and industry standards." />
                </div>

                <SwitchForm
                  name="hasUPS"
                  leftBox="Generate for me"
                  rightBox="I have my own code"
                  onChange={handleToggleSwitch}
                  control={control}
                />

                {codes.hasUPS ? (
                  <InputField
                    name="upc_ean"
                    placeholder="Enter UPC/EAN barcode"
                    description="*required"
                    control={control}
                  />
                ) : null}
              </div>

              <div>
                <div>
                  <span className="font-bold mr-1">ISRC code</span>
                  <Tooltip tooltipContent="ISRC codes are unique identification codes used to correctly track and report on song usage. You can use our generator to create one or enter one manually, if entering a code manually please do NOT include dashes." />
                </div>

                <SwitchForm
                  name="hasISRC"
                  leftBox="Generate for me"
                  rightBox="I have my own code"
                  onChange={handleToggleSwitch}
                  control={control}
                />

                {codes.hasISRC ? (
                  <InputField
                    name="isrc"
                    placeholder="Enter ISRC code"
                    description="*required"
                    control={control}
                  />
                ) : null}
              </div>

              <InputField
                name="label_name"
                label="Record label"
                placeholder="Record label"
                description="*required"
                control={control}
              />

              <div className={styles.yearFields}>
                <TextAreaField
                  name="copyright_owner"
                  label={
                    <div className="flex items-center gap-1">
                      Copyright notice
                      <Tooltip tooltipContent="Please enter your copyright notice." />
                    </div>
                  }
                  description="*required"
                  control={control}
                />

                <NumberInputField
                  name="copyright_year"
                  label="Copyright year"
                  placeholder="Enter year"
                  description="*required"
                  maxLength={4}
                  control={control}
                />
              </div>

              <div className={styles.yearFields}>
                <TextAreaField
                  name="phono_copyright_owner"
                  label={
                    <div className="flex items-center gap-1 w-full">
                      Privacy notice
                      <Tooltip tooltipContent="Please enter your privacy notice." />
                    </div>
                  }
                  description="*required"
                  control={control}
                />

                <NumberInputField
                  name="phono_copyright_year"
                  label="Privacy year"
                  placeholder="Enter year"
                  description="*required"
                  maxLength={4}
                  control={control}
                />
              </div>

              <TextAreaField
                name="rights_holder"
                label={
                  <div className="flex items-center gap-1">
                    Rights holder/Licensor
                    <Tooltip tooltipContent="Please enter your rights holder and licensor information. This should not include a year." />
                  </div>
                }
                description="*required"
                control={control}
              />

              <InputField
                name="catalog_number"
                label="Catalog number"
                placeholder="Enter number"
                control={control}
              />

              <div>
                <div className="mb-1 text-sm font-semibold text-white">Territory</div>

                <CheckboxFieldBase label="Worldwide" checked disabled />
              </div>

              <DatePickerField
                name="release_date"
                label="Release date"
                description="*required"
                control={control}
                minDate={TOMORROW_DATE}
                placeholder="MM/DD/YYYY"
              />
            </div>
          ) : (
            <Tabs className="mt-8" variant="text" size="lg">
              <Tab label="Voice AI" data-testid="voice-ai">
                <VoiceAI />
              </Tab>
              <Tab label="Song Info" data-testid="song-info">
                <SongInfo />
              </Tab>
              <Tab label="Splits" data-testid="splits">
                <Splits />
              </Tab>
            </Tabs>
          )}
        </div>
      )}

      {activeStep === 3 && (
        <div className={styles.submit}>
          {isError || isErrorUpdateSong ? (
            <div>Something went wrong! Please try again.</div>
          ) : (
            <>
              <Icon icon="Spinner" className="animate-spin w-16 h-16" />
              <div>Hold tight!</div>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};
