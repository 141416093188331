export const API_URL = import.meta.env.VITE_API_URL;
export const AUTH_AUTHORITY = import.meta.env.VITE_ZITADEL_AUTHORITY;
export const AUTH_CLIENT_ID = import.meta.env.VITE_ZITADEL_CLIENTID;
export const AUTH_POST_LOGOUT_REDIRECT_URI =
  import.meta.env.VITE_ZITADEL_POST_LOGOUT_REDIRECT_URI || 'http://localhost:3000';
export const AUTH_REDIRECT_URI =
  import.meta.env.VITE_ZITADEL_REDIRECT_URI || 'http://localhost:3000';
export const AUTH_SCOPE = import.meta.env.VITE_ZITADEL_SCOPE || 'openid email profile';
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const HELP_CENTER_URL = 'https://hooky.zendesk.com/hc/en-us';
