export type SelectableParticipantRole =
  | 'featuredArtist'
  | 'producer'
  | 'mixer'
  | 'remixer'
  | 'engineer'
  | 'masteringEngineer'
  | 'lyricist'
  | 'publisher'
  | 'other';

type UnselectableParticipantRole = 'artistAi' | 'creator';

export type ParticipantRole = SelectableParticipantRole | UnselectableParticipantRole;

export type CollaboratorStatus = 'approved' | 'pending';

export enum ParticipantType {
  PARTICIPANT = 'participant',
  COLLABORATOR = 'collaborator'
}

export interface BaseParticipant {
  id: string;
  name: string;
  role: ParticipantRole;
  avatar_url?: string;
  locked?: boolean;
}

export interface PersonnelParticipant extends BaseParticipant {
  type: ParticipantType.PARTICIPANT;
}

export interface Collaborator extends BaseParticipant {
  type: ParticipantType.COLLABORATOR;
  email?: string;
  split: number;
  status: CollaboratorStatus;
}

export type IParticipant = PersonnelParticipant | Collaborator;

export interface ParticipantsApiParams {
  workId: string;
  workType: string;
  type?: ParticipantType;
  page?: number;
  limit?: number;
}
