import React from 'react';
import { tv, type VariantProps } from 'tailwind-variants';
import { Icon } from '@iconify/react';

const notification = tv({
  slots: {
    base: 'font-sans font-normal rounded-lg text-xs px-3 py-2 flex items-center justify-left w-full max-w-sm',
    icon: 'mr-3 font-normal text-base flex-0',
    content: 'min-w-0 flex-1 overflow-hidden'
  },
  variants: {
    variant: {
      success: {
        base: 'text-black bg-hookygreen-500',
        icon: 'text-black',
        content: 'text-black text-ellipsis whitespace-nowrap'
      },
      info: {
        base: 'text-white bg-hookyblue-300',
        icon: 'text-white',
        content: 'text-white'
      },
      error: {
        base: 'text-white bg-hookyred-500',
        icon: 'text-white',
        content: 'text-white text-ellipsis whitespace-nowrap'
      }
    }
  },
  defaultVariants: {
    variant: undefined
  }
});

type NotificationVariants = VariantProps<typeof notification>;

interface NotificationProps extends NotificationVariants, React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  className?: string;
}

export const Notification: React.FC<NotificationProps> = (props: NotificationProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, className, variant, ...rest } = props;

  const { base, icon, content } = notification({
    variant
  });

  return (
    <div
      className={base({
        // "className" is incorrectly required in tailwind-variants's type definition
        className: className || ''
      })}
      {...rest}
    >
      <Icon icon="mdi:information" className={icon()} />
      <div className={content()}>{children}</div>
    </div>
  );
};
