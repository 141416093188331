import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ModalFooterBtn, Modal, NameCell, SelectField, TextAreaField } from '@/components';
import { useModalStore } from '@/store';
import { useDenySong } from '@/hooks';

import { DENY_SONG_MODAL } from '@/constants';
import { DENY_REASON_OPTIONS } from './constants.ts';
import styles from './deny-modal.module.css';

interface ApprovalData {
  image_url: string;
  display_artist: string;
  songName: string;
  id: string;
}

interface DenyForm {
  reason: string;
  description?: string;
}

const SCHEMA = Yup.object().shape({
  reason: Yup.string().required('Reason is required'),
  description: Yup.string().when('reason', ([reason], schema) => {
    return reason === 'other'
      ? schema.required('Description is required').max(500, 'Max value 500 symbols')
      : schema.optional();
  })
});

export const ModalConfirmDenySong = () => {
  const { modalOption, closeModal } = useModalStore();
  const { image_url, display_artist, songName, id } = modalOption as unknown as ApprovalData;

  const { mutate: denySong, isLoading, isSuccess } = useDenySong();

  const { handleSubmit, control, formState, reset, watch } = useForm<DenyForm>({
    resolver: yupResolver(SCHEMA)
  });

  const isShowDescription = watch('reason') === 'other';

  const handleClose = () => {
    closeModal();
    reset();
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess, closeModal]);

  const handleDeny = handleSubmit((values) => {
    denySong({ id, values });
  });

  const footerBtn: ModalFooterBtn[] = [
    {
      key: 'cancel',
      background: 'secondary',
      variant: 'outline',
      onClick: handleClose,
      content: 'CANCEL'
    },
    {
      key: 'confirm',
      disabled: isLoading || !formState.isDirty,
      loading: isLoading,
      onClick: handleDeny,
      content: 'CONFIRM'
    }
  ];

  return (
    <Modal
      size="500px"
      name={DENY_SONG_MODAL}
      title="DENY SONG"
      footerBtns={footerBtn}
      onClose={handleClose}
      className={styles.modal}
      isCentered
    >
      <div className={styles.subtitle}>Please provide a reason why you are denying this song?</div>

      <div className={styles.artist}>
        <NameCell title={songName} subtitle={display_artist} avatarUrl={image_url} />
      </div>

      <form>
        <SelectField
          name="reason"
          label="Reason"
          placeholder="Please select"
          control={control}
          options={DENY_REASON_OPTIONS}
          description="*required"
        />

        {isShowDescription ? (
          <TextAreaField
            name="description"
            label="Description"
            placeholder="Please explain..."
            control={control}
          />
        ) : null}
      </form>
    </Modal>
  );
};
