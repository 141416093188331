import React, { useState } from 'react';
import { Menu } from '@mantine/core';

import { SORT_ORDERS } from '@/constants';
import { DefaultItemOption, SortOptionsProps, SortOrderOptionType } from '@/models/common';
import { FilterIcon } from '@/assets/icons';

import { getSortOrderIcon } from './utils';
import styles from './sort-menu.module.css';

export interface SortMenuProps {
  sortOptions: DefaultItemOption[];
  sortBy: string;
  sortOrder: SortOrderOptionType;
  onSort: (sortOptions: SortOptionsProps) => void;
}

const CLASSES = {
  dropdown: styles.menu,
  item: styles.item
};

export const SortMenu: React.FC<SortMenuProps> = (props) => {
  const { sortOptions, sortBy, sortOrder, onSort } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleToggleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const newSortBy = event.currentTarget.dataset.value || sortOptions[0].value;
    let newSortOrder: SortOrderOptionType;

    if (newSortBy !== sortBy && newSortBy === 'created_at') {
      newSortOrder = SORT_ORDERS.DESC;
    } else if (newSortBy !== sortBy) {
      newSortOrder = SORT_ORDERS.ASC;
    } else if (sortOrder === SORT_ORDERS.ASC) {
      newSortOrder = SORT_ORDERS.DESC;
    } else {
      newSortOrder = SORT_ORDERS.ASC;
    }

    onSort({
      sortOrder: newSortOrder,
      sortBy: newSortBy
    });
  };

  return (
    <Menu
      opened={isOpen}
      onOpen={handleToggleMenu}
      onClose={handleToggleMenu}
      position="bottom-end"
      classNames={CLASSES}
    >
      <Menu.Target>
        <button className={`${styles.menuBtn} ${isOpen ? styles.openMenu : ''}`}>
          <FilterIcon />
        </button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Sort by</Menu.Label>

        {sortOptions.map((option) => {
          const isActive = sortBy === option.value;

          return (
            <Menu.Item
              key={option.value}
              data-value={option.value}
              className={`${isActive ? styles.active : ''}`}
              onClick={handleClick}
              rightSection={getSortOrderIcon(isActive, sortOrder)}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
};
