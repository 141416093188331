import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import Uppy from '@uppy/core';
import AwsS3, { AwsS3UploadParameters } from '@uppy/aws-s3';
import { API_URL } from '@/config';
import { UploadedObject } from '@/models/common';
import { useModalStore } from '@/store';
import { useGetAccessToken, useApi } from '@/hooks';
import { ALLOWED_SONG_EXTS } from '@/constants';
import { notifications } from '@/utils/notifications';

interface UploadFileProps {
  content_type: string;
  file_size: number;
  id: string;
  name: string;
  type: 'AUDIO';
  status: string;
  signed_url: string;
}

export const useUppyFileUploader = () => {
  const navigate = useNavigate();
  const { closeModal } = useModalStore();
  const [uploadProgress, setUploadProgress] = useState(0);
  const voiceIdRef = useRef('');
  const platformUrlsRef = useRef<string[]>([]);
  const endpointRef = useRef('/temporary-tracks');
  const storedFileIdsRef = useRef<string[]>([]);
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasRestriction, setHasRestriction] = useState(false);

  const accessToken = useGetAccessToken();
  const api = useApi({});

  const setVoiceId = (id: string) => {
    voiceIdRef.current = id;
  };

  const [uppy] = useState(() =>
    new Uppy({
      autoProceed: true,
      restrictions: {
        maxFileSize: 100000000,
        maxTotalFileSize: 2000000000,
        allowedFileTypes: ALLOWED_SONG_EXTS
      }
    }).use(AwsS3, {
      getUploadParameters: async (file): Promise<AwsS3UploadParameters> => {
        try {
          const uploadResult: AxiosResponse<UploadFileProps> = await api.post('/v1/uploads', {
            name: file.name,
            content_type: file.type,
            file_size: file.size,
            type: 'AUDIO'
          });
          storedFileIdsRef.current.push(uploadResult.data.id);

          return {
            url: uploadResult.data.signed_url,
            method: 'PUT',
            headers: { content_type: file.type as string }
          };
        } catch (error) {
          const response = (error as unknown as AxiosError<string>).response;

          if (response?.status === 400) {
            notifications.error(response.data);

            return {} as AwsS3UploadParameters;
          }

          notifications.error(
            "Error. Couldn't upload file" + (storedFileIdsRef.current.length > 0 ? 's' : '')
          );
        }
        return {
          url: ''
        };
      }
    })
  );

  useEffect(() => {
    uppy.on('upload', () => {
      setHasError(false);
      setHasRestriction(false);
      setIsSuccess(false);
    });

    uppy.on('progress', (progress) => {
      if (!(hasError || hasRestriction)) {
        setUploadProgress(progress);
      }
    });

    uppy.on('complete', async () => {
      try {
        setHasRestriction(false);
        setHasError(false);
        setUploadProgress(0);
        closeModal();
        await api.post(`${API_URL}/v1${endpointRef.current}`, {
          upload_ids: storedFileIdsRef.current
        });
        setIsSuccess(true);
        if (voiceIdRef.current) {
          navigate(`/create/${voiceIdRef.current}`);
        }
        storedFileIdsRef.current = [];
        platformUrlsRef.current = [];
      } catch (e) {
        notifications.error('Error. Could not finish the uploading');
        setHasError(true);
        storedFileIdsRef.current = [];
      }
    });

    uppy.on('error', () => {
      storedFileIdsRef.current = [];
      setHasError(true);
      setUploadProgress(0);

      uppy.cancelAll();
    });

    uppy.on('restriction-failed', () => {
      storedFileIdsRef.current = [];
      setHasRestriction(true);
      setUploadProgress(0);

      uppy.cancelAll();
    });

    return () => {
      uppy.close();
      setHasRestriction(false);
      setHasError(false);
      setUploadProgress(0);
      storedFileIdsRef.current = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const uploadFiles = ({ files, voiceId, endpoint, platformUrls }: UploadedObject) => {
    if (!files) return;

    if (voiceId) {
      setVoiceId(voiceId);
    }

    if (endpoint) {
      endpointRef.current = endpoint;
    }

    if (platformUrls && platformUrls.length > 0) {
      platformUrlsRef.current = platformUrls;
    }

    if (files instanceof FileList) {
      for (const file of Array.from(files)) {
        uppy.addFile({
          name: file.name,
          type: file.type,
          size: file.size,
          data: file
        });
      }
    } else {
      for (const file of files) {
        uppy.addFile({
          name: file.file.name,
          type: file.file.type,
          size: file.file.size,
          data: file.file
        });
      }
    }
  };

  const cancelUploading = async () => {
    const fileId = uppy.getFiles()[0]?.id;

    uppy.removeFile(fileId);
  };

  return {
    uploadFiles,
    uploadProgress,
    isSuccess,
    isUploading: uploadProgress > 0 && uploadProgress < 100,
    cancelUploading
  };
};
