import { ReactNode, useState } from 'react';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable, Row } from '@tanstack/react-table';
import { Table as TableM } from '@mantine/core';

import { getSortOrderIcon, Icon } from '@/components/atoms';
import { SortOptionsProps } from '@/models/common';
import { SORT_ORDERS } from '@/constants';

import { getCellSize } from './utils.ts';
import styles from './table.module.css';

export interface TableSortData {
  sortOptions: SortOptionsProps;
  onSort: (sortOptions: SortOptionsProps) => void;
}

export interface TableProps<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[];
  data: T[];
  infinityRef?: (node: Element) => void;
  onRowClick?: (data?: Row<T>, onUnselectRow?: () => void) => void;
  sortData?: TableSortData;
  disabledColumnsSort?: string[];
  classes?: string;
  isLoading?: boolean;
  emptyContent?: ReactNode;
  emptyDataText?: string;
  state?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any;
  };
}

const CLASSES = {
  table: styles.table,
  thead: styles.thead,
  tbody: styles.tbody,
  tr: styles.tr,
  th: styles.th,
  td: styles.td
};

const DISABLED_COLUMNS_SORT = ['id'];

export const Table = <T,>(props: TableProps<T>) => {
  const {
    columns,
    data,
    classes,
    isLoading,
    onRowClick,
    sortData,
    disabledColumnsSort = DISABLED_COLUMNS_SORT,
    infinityRef,
    emptyContent,
    emptyDataText = 'You have no data',
    state
  } = props;
  const [selectedRow, setSelectedRow] = useState<Row<T> | null>(null);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    state
  });

  const handleUnselectRow = () => {
    setSelectedRow(null);
  };

  const handleRowClick = (row: Row<T>) => {
    if (row.id !== selectedRow?.id) {
      setSelectedRow(row);

      if (onRowClick) {
        onRowClick(row, handleUnselectRow);
      }
    } else {
      setSelectedRow(null);

      if (onRowClick) {
        onRowClick(undefined, handleUnselectRow);
      }
    }
  };

  return (
    <>
      <TableM classNames={CLASSES} className={classes} withRowBorders={false}>
        <TableM.Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableM.Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                if (sortData && !disabledColumnsSort.includes(header.id)) {
                  const { sortOptions, onSort } = sortData;
                  const isSortBy = sortOptions.sortBy === header.id;

                  const handleSort = () => {
                    onSort({
                      sortBy: header.id,
                      sortOrder:
                        isSortBy && sortOptions.sortOrder === SORT_ORDERS.ASC
                          ? SORT_ORDERS.DESC
                          : SORT_ORDERS.ASC
                    });
                  };

                  return (
                    <TableM.Th key={header.id} style={getCellSize(header.column.columnDef)}>
                      <span className={styles.sortCell} onClick={handleSort}>
                        {flexRender(header.column.columnDef.header, header.getContext())}

                        {getSortOrderIcon(isSortBy, sortOptions.sortOrder)}
                      </span>
                    </TableM.Th>
                  );
                }

                return (
                  <TableM.Th key={header.id} style={getCellSize(header.column.columnDef)}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableM.Th>
                );
              })}
            </TableM.Tr>
          ))}
        </TableM.Thead>

        {isLoading ? ( // check loading state with more data
          <TableM.Tbody>
            <TableM.Tr>
              <TableM.Td className={styles.loadingCell} colSpan={columns.length}>
                <Icon icon="mdi:loading" className="animate-spin" />
              </TableM.Td>
            </TableM.Tr>
          </TableM.Tbody>
        ) : (
          <TableM.Tbody>
            {table.getRowModel()?.rows.length > 0 ? (
              table.getRowModel()?.rows.map((row, index, array) => {
                const handleClick = () => handleRowClick(row);

                return (
                  <TableM.Tr
                    key={row.id}
                    ref={index === array.length - 1 ? (infinityRef as () => void) : null}
                    data-selected-row={selectedRow?.id === row.id}
                    onClick={handleClick}
                    data-row-item
                  >
                    {row.getVisibleCells().map((cell) => {
                      const cellData = { ...cell.getContext(), onSelectedRow: handleClick };

                      return (
                        <TableM.Td key={cell.id} style={getCellSize(cell.column.columnDef)}>
                          {flexRender(cell.column.columnDef.cell, cellData)}
                        </TableM.Td>
                      );
                    })}
                  </TableM.Tr>
                );
              })
            ) : (
              <TableM.Tr>
                <TableM.Td colSpan={columns.length}>
                  {emptyContent ? (
                    emptyContent
                  ) : (
                    <div className={styles.emptySate}>{emptyDataText}</div>
                  )}
                </TableM.Td>
              </TableM.Tr>
            )}
          </TableM.Tbody>
        )}
      </TableM>
    </>
  );
};
