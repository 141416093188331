import { useState } from 'react';
import { LoadingOverlay } from '@mantine/core';

import AccountLayout from '@/layouts/AccountLayout';
import { useGetCardDetail } from '@/hooks';

import { TitlePaymentCard, ViewPaymentCard, StripePaymentCard } from './components';
import { PAYMENT_CONTENT_MODE } from './constants';

const LOADING_OVERLAY_PROPS = { color: 'var(--mantine-color-dark-6)', backgroundOpacity: 1 };

export const PaymentDetails = () => {
  const { data, isLoading, refetch } = useGetCardDetail();
  const [mode, setMode] = useState(PAYMENT_CONTENT_MODE.VIEW);

  const handleChangeMode = (value: string, isNeedRefresh?: boolean) => {
    setMode(value);

    if (isNeedRefresh) {
      void refetch();
    }
  };

  return (
    <AccountLayout
      title="Payment Details"
      subtitle="Manage your monthly subscription payment details."
    >
      {isLoading ? <LoadingOverlay visible overlayProps={LOADING_OVERLAY_PROPS} /> : null}

      {data ? (
        <>
          <TitlePaymentCard mode={mode} onChangeMode={handleChangeMode} />

          {mode === PAYMENT_CONTENT_MODE.VIEW ? (
            <ViewPaymentCard onChangeMode={handleChangeMode} />
          ) : (
            <StripePaymentCard onChangeMode={handleChangeMode} />
          )}
        </>
      ) : (
        <StripePaymentCard onChangeMode={handleChangeMode} />
      )}
    </AccountLayout>
  );
};
