import { Tab, Tabs } from '@/components/atoms/tabs/Tabs';
import { Credits, General, ReleaseInfo } from './components';
import { useSongStore } from '@/store';

import { TAB_NAME } from '@/constants/song';

interface SongInfoProps {
  onSetActiveTab?: (tabName: string) => void;
}

export const SongInfo = (props: SongInfoProps) => {
  const { songData } = useSongStore();

  return (
    <Tabs className="relative mt-2 md:mt-4" variant="square" onTabClick={props.onSetActiveTab}>
      <Tab name={TAB_NAME.songInfoGeneral} label="General" data-testid="song-info-general">
        <General />
      </Tab>

      <Tab name={TAB_NAME.songInfoCredits} label="Credits" data-testid="song-info-credits">
        <Credits />
      </Tab>

      {songData.status === 'release_scheduled' ? (
        <Tab name={TAB_NAME.songInfoRelease} label="Release Info" data-testid="song-info-release">
          <ReleaseInfo />
        </Tab>
      ) : (
        <div />
      )}
    </Tabs>
  );
};
