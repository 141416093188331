import { NavLink } from 'react-router-dom';
import { Modal } from '@mantine/core';

import { useModalStore } from '@/store';
import { ROUTES } from '@/routes/routes.tsx';
import { useDeleteTracks } from '@/hooks';
import { MODAL_CONFIRM_TEMP_TRACKS_DELETE, UPLOAD_VOCALS_MODAL } from '@/constants';
import { ButtonLib } from '@/components';
import styles from './modal-confirm-delete-tracks.module.css';

interface ModalConfirmProps {
  temporaryTrackVoiceId?: string;
  isLoading?: boolean;
}

const CLASSES = {
  content: styles.content,
  title: styles.title,
  body: styles.body,
  close: styles.close
};

export const ModalConfirmDeleteTracks = (props: ModalConfirmProps) => {
  const { temporaryTrackVoiceId, isLoading } = props;
  const { mutate: deleteTracks } = useDeleteTracks();

  const { modalOption, closeModal, openModal } = useModalStore();

  if (modalOption.name !== MODAL_CONFIRM_TEMP_TRACKS_DELETE) {
    return null;
  }

  const onTemporaryTracksDeleteConfirmed = () => {
    openModal({ name: UPLOAD_VOCALS_MODAL, voiceId: modalOption.voiceId });
    deleteTracks();
  };

  return (
    <Modal opened onClose={closeModal} title="Warning" size="450px" centered classNames={CLASSES}>
      You currently have a draft project in process, if you want to create a new song your existing
      progress will be permanently deleted. Are you sure you want to proceed
      <div className="flex justify-end mt-8">
        <ButtonLib
          variant="outline"
          background="secondary"
          className="min-w-[170px] mr-4"
          onClick={closeModal}
        >
          No
        </ButtonLib>

        <ButtonLib
          variant="outline"
          background="fourth"
          className="min-w-[170px]"
          onClick={onTemporaryTracksDeleteConfirmed}
          loading={isLoading}
        >
          Yes
        </ButtonLib>
      </div>
      <div className="mt-8">
        <NavLink
          onClick={closeModal}
          className="text-white"
          to={`${ROUTES.CREATE}/${temporaryTrackVoiceId}`}
        >
          Resume Draft Project
        </NavLink>
      </div>
    </Modal>
  );
};
