import { useApi } from '@/hooks';
import { useMutation } from '@tanstack/react-query';
import { notifications } from '@/utils/notifications';

interface PasswordFormValues {
  current_password: string;
  new_password: string;
}

export const useAccountChangePassword = () => {
  const api = useApi({});

  return useMutation({
    mutationFn: async (form: PasswordFormValues): Promise<PasswordFormValues> => {
      const { data } = await api.put<PasswordFormValues>('/v1/account/password', form);

      return data;
    },
    onSuccess: () => {
      notifications.success('Your password has been changed');
    },
    onError: () => {
      notifications.error('Could not update user password');
    }
  });
};
