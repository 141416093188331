import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useApi } from '@/hooks';
import { IAccount } from '@/models/Account.ts';

export const useGetAccount = (): UseQueryResult<IAccount, AxiosError> => {
  const api = useApi({});

  return useQuery<IAccount, AxiosError>({
    queryKey: ['account'],
    queryFn: async (): Promise<IAccount> => {
      const { data } = await api.get<IAccount>('/v1/account');
      return data;
    }
  });
};
