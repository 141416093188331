import { FC, useRef } from 'react';
import { useMantineTheme } from '@mantine/core';

import { useSongStore } from '@/store';
import { useDeleteSongTrack } from '@/hooks';
import { ItemMenu, MenuOptionProps } from '@/components';

interface ItemMenuCellProps {
  id: string;
  output_url: string;
}

export const ItemMenuCell: FC<ItemMenuCellProps> = ({ id, output_url }) => {
  const { colors } = useMantineTheme();
  const { isSongEditable } = useSongStore();
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const { mutate: deleteTrack } = useDeleteSongTrack();

  const OPTIONS: MenuOptionProps[] = [
    {
      label: 'Download',
      onClick: () => {
        if (anchorRef.current) {
          anchorRef.current.click();
        }
      }
    },
    {
      label: 'Delete',
      color: colors.red[0],
      onClick: () => {
        deleteTrack({ id });
      }
    }
  ];

  return isSongEditable ? (
    <>
      <ItemMenu options={OPTIONS} />
      <a ref={anchorRef} href={output_url} style={{ position: 'absolute', visibility: 'hidden' }} />
    </>
  ) : null;
};
