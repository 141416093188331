import { useState, MouseEvent, SyntheticEvent } from 'react';
import { Tooltip as MantineTooltip } from '@mantine/core';
import { twMerge } from 'tailwind-merge';
import { VoiceModel } from '@/models/VoiceModel.ts';
import { useAudioContext } from '@/providers';
import { useTitleOverflowEffect } from '@/hooks';
import defaultSongImage from '@/assets/default-song-image.png';
import { Icon } from '@/components/atoms';
import { ItemMenuCell } from '../ItemMenuCell';
import { getVisibilityColor, MODEL_STATUS } from '../../utils';

export interface ModelCardProps {
  model: VoiceModel;
  onCardClick?: (data?: VoiceModel, onUnselectRow?: () => void) => void;
}

export const ModelCard = ({ model, onCardClick }: ModelCardProps) => {
  const { setFile, isPlaying, togglePlay, trackInfo } = useAudioContext();
  const { isTitleOverflow, titleRef } = useTitleOverflowEffect();
  const [displayedImageUrl, setDisplayedImageUrl] = useState(model.avatar_url);
  const [selectedCard, setSelectedCard] = useState<VoiceModel | null>(null);

  const handlePlayClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (trackInfo?.id === model.voice_id) {
      togglePlay();
    } else {
      setFile({
        title: `${model.name}'s preview`,
        imageUrl: model.avatar_url,
        subtitle: model.name || '',
        audio: model.audio_preview.url || '',
        id: model.voice_id || ''
      });
    }
  };

  const setStubOnError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    if (e.currentTarget.naturalWidth === 0) {
      setDisplayedImageUrl(defaultSongImage);
    }
  };

  const handleUnselectRow = () => {
    setSelectedCard(null);
  };

  const handleCardClick = () => {
    if (model.voice_id !== selectedCard?.voice_id) {
      setSelectedCard(model);

      if (onCardClick) {
        onCardClick(model, handleUnselectRow);
      }
    } else {
      setSelectedCard(null);

      if (onCardClick) {
        onCardClick(undefined, handleUnselectRow);
      }
    }
  };

  return (
    <div
      onClick={handleCardClick}
      id={model.voice_id}
      className="flex-col group/card max-w-[22rem] cursor-pointer dark:hover:bg-hookybase-500 hover:bg-hookybase-100 relative bg-hookybase-50 dark:bg-hookybase-600 rounded-lg shadow-lg p-4 flex gap-2"
    >
      <div className="relative">
        <div className="relative">
          <img
            src={displayedImageUrl}
            onError={setStubOnError}
            alt={model.name}
            className="w-full h-auto aspect-square rounded-full"
          />
        </div>

        {model.audio_preview.url.length > 0 ? (
          <button
            onClick={handlePlayClick}
            className="absolute bottom-[6%] right-[6%] opacity-0 group-hover/card:opacity-100 z-10 bg-hookyyellow-500 hover:bg-hookyyellow-600 rounded-full flex items-center justify-center shadow-lg w-16 h-16"
          >
            <Icon
              size="4xl"
              icon={`mdi:${isPlaying && trackInfo?.id === model.voice_id ? 'pause' : 'play'}`}
              className="text-black"
            />
          </button>
        ) : null}
      </div>

      <div className="mt-2 flex-1 flex flex-col justify-between">
        <div className="flex justify-between items-center">
          <MantineTooltip label={model.name} disabled={!isTitleOverflow}>
            <h2
              ref={titleRef}
              className="flex-0 block text-hookybase-500 dark:text-white font-bold text-ellipsis overflow-hidden whitespace-nowrap text-2xl"
            >
              {model.name}
            </h2>
          </MantineTooltip>
          <ItemMenuCell data={model} />
        </div>
        <p className={twMerge('text-sm capitalize', getVisibilityColor(model.visibility))}>
          {model.visibility}
        </p>
        <div className="flex justify-between items-end">
          <span className="text-sm w-40 font-semibold uppercase text-center border border-hookybase-350 rounded-2xl">
            {MODEL_STATUS[model.status]}
          </span>
          {model.created_at && (
            <span className="text-hookybase-300 text-sm mt-4">
              {new Date(model.created_at).toLocaleDateString()}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
