import React from 'react';
import { Slider } from '@mantine/core';
import { Icon } from '@/components';

import styles from './volume-slider.module.css';

interface VolumeSliderProps {
  className?: string;
  volume?: number;
  onChange: (value: number) => void;
}

const CLASSES = {
  root: styles.root,
  thumb: styles.thumb,
  bar: styles.bar
};

export const VolumeSlider: React.FC<VolumeSliderProps> = ({ volume, onChange }) => {
  const handleChange = (value: number) => {
    onChange(value);
  };

  return (
    <div className={styles.box}>
      <Slider
        value={volume}
        label={null}
        min={0}
        max={1}
        step={0.1}
        onChange={handleChange}
        classNames={CLASSES}
      />

      <button type="button">
        <Icon icon="Volume" className="w-6 h-6" />
      </button>
    </div>
  );
};
