import { MouseEvent, useMemo } from 'react';
import {
  ItemMenu,
  MenuOptionProps,
  ModalConfirmDanger,
  ModalSongDropBoxUploader
} from '@/components';
import { useMantineTheme } from '@mantine/core';
import { VoiceModel } from '@/models/VoiceModel.ts';
import { useConvertTrackStatus, useDeleteVoices } from '@/hooks';
import { MODAL_CONFIRM_TEMP_TRACKS_DELETE, UPLOAD_VOCALS_MODAL } from '@/constants';
import { useModalStore } from '@/store';

interface ItemMenuCellProps {
  data: VoiceModel;
  size?: {
    width: string;
    height: string;
  };
}

const MODAL_CONFIRM_VOICE_MODEL_DELETE = 'MODAL_CONFIRM_VOICE_MODEL_DELETE';

export const ItemMenuCell = ({ data, size }: ItemMenuCellProps) => {
  const { openModal, closeModal } = useModalStore();
  const { colors } = useMantineTheme();
  const { mutateAsync: deleteModel } = useDeleteVoices();
  // TODO: Think of creating a HOOK with lines 21-32 and 89. This part is using in Artist Card
  const { data: temporaryTracks } = useConvertTrackStatus({ refetchInterval: false });

  const temporaryTracksVoiceId = useMemo(() => {
    return temporaryTracks?.[0]?.voice.id;
  }, [temporaryTracks]);

  const handleUploadVocal = (voiceId: string) => {
    if (!temporaryTracksVoiceId || temporaryTracksVoiceId === voiceId) {
      openModal({ name: UPLOAD_VOCALS_MODAL, voiceId });
    } else {
      openModal({ name: MODAL_CONFIRM_TEMP_TRACKS_DELETE, voiceId });
    }
  };

  const buttonProps = {
    'data-cell-hover': 'show',
    onClick: (event: MouseEvent<HTMLButtonElement>) => event.stopPropagation()
  };

  const handleCreateSong = (event: unknown) => {
    (event as MouseEvent<HTMLButtonElement>).stopPropagation();
    handleUploadVocal(data.voice_id);
  };

  const handleDeleteModel = () => {
    openModal({
      name: MODAL_CONFIRM_VOICE_MODEL_DELETE,
      voice_id: data.voice_id
    });
  };

  const handleConfirmDeleteModel = async (data: unknown) => {
    await deleteModel((data as VoiceModel).voice_id);
    closeModal();
  };

  const options: MenuOptionProps[] = [
    ...(data.status === 'published'
      ? [
          {
            label: 'Create a song',
            onClick: handleCreateSong
          }
        ]
      : []),
    ...(data.visibility === 'private'
      ? [
          {
            label: 'Delete',
            color: colors.red[0],
            onClick: handleDeleteModel
          }
        ]
      : [])
  ];

  return options.length > 0 ? (
    <>
      <ItemMenu options={options} buttonProps={buttonProps} size={size} />
      <ModalSongDropBoxUploader />
      <ModalConfirmDanger
        name={MODAL_CONFIRM_VOICE_MODEL_DELETE}
        title="Danger"
        subtitle="Are you sure you would like to permanently delete this model?"
        onConfirm={handleConfirmDeleteModel}
        leftButtonText="No"
        rightButtonText="Yes"
      />
    </>
  ) : null;
};
