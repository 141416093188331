import { FC } from 'react';
import { useDeleteParticipant } from '@/hooks';
import { useSongStore } from '@/store';
import { ParticipantType, PersonnelParticipant } from '@/models/Participant.ts';
import { CancelIcon } from '@/assets/icons';

interface DeleteCellProps {
  data: PersonnelParticipant;
}

export const DeleteCell: FC<DeleteCellProps> = ({ data }) => {
  const { songData } = useSongStore();
  const { mutate } = useDeleteParticipant({
    workType: 'songs',
    workId: songData.id,
    type: ParticipantType.PARTICIPANT
  });

  if (data.locked) return null;

  const onDelete = () => {
    mutate(data.id);
  };

  return (
    <CancelIcon
      className="cursor-pointer text-hookybase-300"
      onClick={onDelete}
      width={24}
      height={24}
    />
  );
};
