import React from 'react';

import { Menu, MenuOptionProps } from '@/components';
import { MenuDotsIcon } from '@/assets/icons';

import styles from './item-menu.module.css';

export interface ItemMenuProps {
  options: MenuOptionProps[];
  onClose?: () => void;
  buttonProps?: {
    [k: string]: string | boolean | ((event: React.MouseEvent<HTMLButtonElement>) => void);
  };
  size?: {
    width: string;
    height: string;
  };
}

export const ItemMenu = (props: ItemMenuProps) => {
  const { buttonProps, onClose, options, size } = props;
  const { width, height } = size || { width: '24px', height: '24px' };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div data-content="cell-item-menu" className="flex justify-end">
      <Menu options={options} trigger="click" onClose={handleClose} keepMounted>
        <button type="button" className={styles.menuButton} {...buttonProps}>
          <MenuDotsIcon width={width} height={height} />
        </button>
      </Menu>
    </div>
  );
};
