import { useEffect, useState, useRef } from 'react';

export const useTitleOverflowEffect = () => {
  const [isTitleOverflow, setIsTitleOverflow] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollWidth = titleRef?.current?.scrollWidth || 0;
    const offsetWidth = titleRef?.current?.offsetWidth || 0;
    window.addEventListener('resize', () => {
      setIsTitleOverflow(scrollWidth > offsetWidth);
    });
    setIsTitleOverflow(scrollWidth > offsetWidth);
    return () =>
      window.removeEventListener('resize', () => {
        setIsTitleOverflow(scrollWidth > offsetWidth);
      });
  }, []);

  return { isTitleOverflow, titleRef };
};
