import { Control, useController } from 'react-hook-form';
import { DateInput } from '@mantine/dates';

import { CalendarIcon } from '@/assets/icons';
import { DEFAULT_DATE_FORMAT } from '@/constants';

import { INPUT_WRAPPER_ORDER } from '../input/constant';
import inputStyles from '../input/input.module.css';
import styles from './date-picker.module.css';

interface DatePickerFieldProps {
  name: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  placeholder?: string;
  description?: string;
  minDate?: Date;
}

const CLASSES = {
  wrapper: inputStyles.wrapper,
  input: `${inputStyles.input} ${styles.input}`,
  label: inputStyles.label,
  error: inputStyles.error,
  description: inputStyles.description,
  calendarHeader: styles.calendarHeader,
  day: styles.day,
  monthsListCell: styles.month,
  yearsListCell: styles.year,
  calendarHeaderControl: styles.controls,
  calendarHeaderLevel: styles.controlLevels
};

function dateValidation(date: string): Date | null {
  const timestamp = Date.parse(date);

  if (isNaN(timestamp)) {
    return null;
  }

  return new Date(timestamp);
}

export const DatePickerField = (props: DatePickerFieldProps) => {
  const { name, label, control, placeholder = '', description, minDate } = props;
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: null
  });

  const handleChange = (value: Date | null) => {
    field.onChange(value);
  };

  return (
    <DateInput
      {...field}
      label={label}
      classNames={CLASSES}
      placeholder={placeholder}
      onChange={handleChange}
      error={fieldState.error ? fieldState.error.message : null}
      description={description}
      minDate={minDate}
      dateParser={dateValidation}
      valueFormat={DEFAULT_DATE_FORMAT}
      inputWrapperOrder={INPUT_WRAPPER_ORDER}
      rightSection={<CalendarIcon />}
      size="lg"
    />
  );
};
