import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ROUTES } from '@/routes/routes';
import { useAccountChangePassword } from '@/hooks/api/account/useAccountChangePassword.ts';
import AccountLayout from '@/layouts/AccountLayout';
import { InputPasswordField, ButtonLib } from '@/components';
import { setServerFormError } from '@/utils/serverTestValidation';

import styles from '../../account.module.css';

interface PasswordFormValues {
  confirm_new_password: string;
  current_password: string;
  new_password: string;
}

const SCHEMA = Yup.object().shape({
  current_password: Yup.string().required('Current password is required'),
  new_password: Yup.string()
    .notOneOf(
      [Yup.ref('current_password')],
      'New password is the same as previous passwords. Please create another.'
    )
    .required('New password is required'),
  confirm_new_password: Yup.string()
    .oneOf([Yup.ref('new_password')], 'Passwords must match')
    .required('Confirm new password is required')
});
const ChangePassword: FC = () => {
  const navigate = useNavigate();

  const { mutate: updatePassword, isLoading, status, error } = useAccountChangePassword();

  const { handleSubmit, control, reset, formState, setError } = useForm<PasswordFormValues>({
    resolver: yupResolver(SCHEMA)
  });

  useEffect(() => {
    if (error) {
      setServerFormError(error, setError);
    }
  }, [error, setError]);

  useEffect(() => {
    if (status === 'success') {
      navigate(ROUTES.ACCOUNT);
    }
  }, [status, navigate]);

  const handleUpdatePassword = handleSubmit((form: PasswordFormValues) => {
    updatePassword({
      current_password: form.current_password,
      new_password: form.new_password
    });
  });

  const handleResetChanges = () => {
    reset();
  };

  return (
    <AccountLayout
      title="Change Password"
      subtitle="Passwords should be a min of 8 characters and include upper, lower case, number and special characters."
    >
      <form className={styles.formInputWrapper} onSubmit={handleUpdatePassword}>
        <InputPasswordField
          name="current_password"
          label="Current password"
          placeholder="Password"
          control={control}
        />

        <InputPasswordField
          name="new_password"
          label="New password"
          placeholder="Password"
          control={control}
          isCriteriaVisible
        />

        <InputPasswordField
          name="confirm_new_password"
          label="Repeat new password"
          control={control}
          placeholder="Password"
        />

        <div className={`${styles.formFooter} justify-end`}>
          <div className={styles.formActions}>
            <ButtonLib
              background="secondary"
              variant="outline"
              onClick={handleResetChanges}
              disabled={!formState.isDirty}
            >
              Cancel
            </ButtonLib>

            <ButtonLib type="submit" loading={isLoading} disabled={!formState.isDirty}>
              Save
            </ButtonLib>
          </div>
        </div>
      </form>
    </AccountLayout>
  );
};

export default ChangePassword;
