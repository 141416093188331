import { MouseEvent } from 'react';
import { useMantineTheme } from '@mantine/core';
import { ISong } from '@/models/Song.ts';
import { useModalStore } from '@/store';
import { usePostSongApproveRevoke } from '@/hooks';
import { AGREED_TERMS_UPLOAD_TRACK_MODAL_NAME, DELETE_SONG_MODAL_NAME } from '@/constants/song';
import { ItemMenu, MenuOptionProps } from '@/components';

interface ItemMenuCellProps {
  data: ISong;
}

export const ItemMenuCell = ({ data }: ItemMenuCellProps) => {
  const { colors } = useMantineTheme();
  const { mutate: revokeApprove } = usePostSongApproveRevoke();
  const { openModal } = useModalStore();

  const isEligibleForWithdrawApprove =
    data.status === 'release_song' || data.status === 'awaiting_approval';

  const isEligibleForReuploadMaster =
    isEligibleForWithdrawApprove || data.status === 'change_artist';

  const buttonProps = {
    'data-cell-hover': 'show',
    onClick: (event: MouseEvent<HTMLButtonElement>) => event.stopPropagation()
  };

  const handleConfirmDelete = (event: unknown) => {
    (event as MouseEvent<HTMLButtonElement>).stopPropagation();

    openModal({ name: DELETE_SONG_MODAL_NAME, songId: data.id });
  };

  const handleWithdrawFromApproval = (event: unknown) => {
    (event as MouseEvent<HTMLButtonElement>).stopPropagation();
    revokeApprove(data.id);
  };

  const handleReuploadMaster = () => {
    openModal({
      name: AGREED_TERMS_UPLOAD_TRACK_MODAL_NAME,
      voiceId: data.id,
      title: data.name,
      artistName: data.display_artist,
      split: `${data.voices[0].royalty_percent}`
    });
  };

  const options: MenuOptionProps[] = [
    {
      label: 'Delete',
      color: colors.red[0],
      onClick: (event: unknown) => handleConfirmDelete(event)
    },
    ...(data.master_url && isEligibleForReuploadMaster && data.requires_license
      ? [
          {
            label: 'Reupload Master',
            onClick: () => handleReuploadMaster()
          }
        ]
      : []),
    ...(isEligibleForWithdrawApprove
      ? [
          {
            label: 'Withdraw from Approval',
            onClick: handleWithdrawFromApproval
          }
        ]
      : [])
  ];

  return <ItemMenu options={options} buttonProps={buttonProps} />;
};
