import { FC, ReactNode } from 'react';
import { FloatingPosition, Tooltip as TooltipMantine, TransitionOverride } from '@mantine/core';
import { Icon, IconSizes } from '@/components';

import styles from './tooltip.module.css';

export interface BaseTooltipProps {
  tooltipContent: ReactNode;
  tooltipWidthContent?: number;
  position?: FloatingPosition;
  size?: 'sm' | 'md';
}

interface TooltipPropsWithReactComponent extends BaseTooltipProps {
  content: ReactNode;
  iconSize: never;
}

interface TooltipPropsWithoutReactComponent extends BaseTooltipProps {
  iconSize?: IconSizes;
  iconBoxClasses?: string;
}

type TooltipProps = TooltipPropsWithReactComponent | TooltipPropsWithoutReactComponent;

const TRANSITION: TransitionOverride = { transition: 'scale', duration: 300 };

const isTooltipWithReactComponent = (
  props: TooltipProps
): props is TooltipPropsWithReactComponent =>
  Object.prototype.hasOwnProperty.call(props, 'content');

export const Tooltip: FC<TooltipProps> = (props) => {
  const { tooltipContent, position = 'bottom', size = 'sm', tooltipWidthContent = 250 } = props;
  const renderTooltip = (children: ReactNode) => (
    <TooltipMantine
      multiline
      w={tooltipWidthContent}
      label={tooltipContent}
      position={position}
      offset={10}
      withArrow={size !== 'sm'}
      arrowSize={9}
      transitionProps={TRANSITION}
      className={styles[`tooltip-${size}`]}
    >
      {children}
    </TooltipMantine>
  );
  if (isTooltipWithReactComponent(props)) {
    const { content } = props;

    return renderTooltip(content);
  } else {
    const { iconSize, iconBoxClasses = '' } = props;

    return renderTooltip(
      <div className={`leading-4 inline-block ${iconBoxClasses}`}>
        <Icon
          size={iconSize || 'md'}
          icon="Info"
          className={`dark:text-hookybase-100 group-hover:text-white cursor-pointer ${styles.defaultIcon}`}
        />
      </div>
    );
  }
};
