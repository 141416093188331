import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks';
import { IParticipant, ParticipantsApiParams, ParticipantType } from '@/models/Participant.ts';

export const useGetParticipants = ({
  workType = 'songs',
  workId,
  type = ParticipantType.PARTICIPANT
}: ParticipantsApiParams) => {
  const api = useApi({});

  return useQuery<IParticipant[], AxiosError>({
    queryKey: ['participants', workType, workId, type],
    queryFn: async (): Promise<IParticipant[]> => {
      const response = await api.get<IParticipant[]>(
        `/v1/${workType}/${workId}/participants/?type=${type}`
      );
      return response.data;
    },
    enabled: !!workId
  });
};
