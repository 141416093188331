import { Control, useController } from 'react-hook-form';
import { MonthPickerInput } from '@mantine/dates';

import { CalendarIcon } from '@/assets/icons';

import { INPUT_WRAPPER_ORDER } from '../input/constant';
import inputStyles from '../input/input.module.css';
import styles from './date-picker.module.css';

interface MonthPickerFieldProps {
  name: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  placeholder?: string;
  description?: string;
  minDate?: Date;
  valueFormat?: string;
}

const CLASSES = {
  wrapper: inputStyles.wrapper,
  input: `${inputStyles.input} ${styles.input}`,
  label: inputStyles.label,
  error: inputStyles.error,
  description: inputStyles.description,
  calendarHeader: styles.calendarHeader,
  day: styles.day,
  monthsListCell: styles.month,
  yearsListCell: styles.year,
  calendarHeaderControl: styles.controls,
  calendarHeaderLevel: styles.controlLevels
};

export const MonthPickerField = (props: MonthPickerFieldProps) => {
  const {
    name,
    label,
    control,
    placeholder = '',
    description,
    minDate,
    valueFormat = 'MM/YY'
  } = props;
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue: null
  });

  const handleChange = (value: Date | null) => {
    field.onChange(value);
  };

  return (
    <MonthPickerInput
      {...field}
      label={label}
      classNames={CLASSES}
      placeholder={placeholder}
      onChange={handleChange}
      error={fieldState.error ? fieldState.error.message : null}
      description={description}
      minDate={minDate}
      valueFormat={valueFormat}
      inputWrapperOrder={INPUT_WRAPPER_ORDER}
      rightSection={<CalendarIcon />}
      size="lg"
    />
  );
};
