import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@/hooks';
import { useSongStore } from '@/store';
import { SongTrackProps } from '@/models/Song';
import { notifications } from '@/utils/notifications';

export const useDeleteSongTrack = () => {
  const api = useApi({});

  const {
    songData: { id: songId }
  } = useSongStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: { id: string }): Promise<string> => {
      const response = await api.delete<string>(`/v1/songs/${songId}/tracks/${id}`);

      return response.data;
    },
    onSuccess: (_, variables) => {
      void queryClient.setQueryData(['tracks', songId], (oldTracks?: SongTrackProps[]) => {
        if (oldTracks?.length) {
          return oldTracks.filter((track) => track.id !== variables.id);
        }
        return oldTracks;
      });
      notifications.success('Track is removed successfully');
    },
    onError: () => {
      notifications.error('Could not remove track');
    }
  });
};
