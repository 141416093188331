import { createTheme, DEFAULT_THEME, mergeMantineTheme } from '@mantine/core';

const themeOverride = createTheme({
  fontFamily: 'Space Grotesk, Arial',
  cursorType: 'pointer',
  primaryColor: 'yellow',
  colors: {
    yellow: [
      '#ECE81A',
      '#FEFDEE',
      '#FCFBD6',
      '#F8F6A7',
      '#F4F178',
      '#F0ED49',
      '#ECE81A',
      '#BEBB10',
      '#8A880B',
      '#575507',
      '#232203',
      '#090901'
    ],
    blue: [
      '#0032A0',
      '#B4CCFF',
      '#96B7FF',
      '#598DFF',
      '#1B63FF',
      '#0045DD',
      '#0032A0',
      '#002C8C',
      '#002577',
      '#001F63',
      '#00184E',
      '#001544'
    ],
    green: [
      '#3CDBC0',
      '#C4F4EC',
      '#B5F1E7',
      '#96ECDD',
      '#78E6D3',
      '#5AE1CA',
      '#3CDBC0',
      '#24C0A6',
      '#1C9581',
      '#146A5C',
      '#0C3F37',
      '#082A24'
    ],
    red: [
      '#FF585D',
      '#FFE2E3',
      '#FFD2D4',
      '#FFB4B6',
      '#FF9598',
      '#FF777B',
      '#FF585D',
      '#FF161D',
      '#D20006',
      '#900004',
      '#4E0002',
      '#2D0001'
    ],
    dark: [
      '#F5F5F5',
      '#EFEFEF',
      '#A7A7A7',
      '#858585',
      '#545454',
      '#292929',
      '#1C1C1C',
      '#161616',
      '#101010',
      '#141414'
    ]
  },
  breakpoints: {
    xs: '26rem',
    sm: '40rem',
    md: '48rem',
    lg: '64rem',
    xl: '80rem',
    '2xl': '96rem'
  }
});

export const mantineTheme = mergeMantineTheme(DEFAULT_THEME, themeOverride);
