import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useApi } from '@/hooks';
import { VoiceModel } from '@/models/VoiceModel';

export const useGetModels = (): UseQueryResult<VoiceModel[], AxiosError> => {
  const api = useApi({});

  return useQuery<VoiceModel[], AxiosError>({
    queryKey: ['models'],
    queryFn: async (): Promise<VoiceModel[]> => {
      const response = await api.get<VoiceModel[]>('/v1/models');

      return response.data;
    }
  });
};
