import { FC } from 'react';
import { Pie, PieChart as Chart, Cell } from 'recharts';
import { twMerge } from 'tailwind-merge';
import { useMantineTheme } from '@mantine/core';
import { Loader } from '@/components';

interface Props {
  data?: Array<{ id: string; name: string; value: number }>;
  label?: string;
}

const MAX_ROWS = 4;

export const PieChart: FC<Props> = ({ data, label }) => {
  const { colors } = useMantineTheme();
  const COLORS = [
    colors.yellow[6],
    colors.blue[6],
    colors.green[6],
    colors.red[6],
    colors.dark[0],
    colors.dark[3],
    colors.dark[5]
  ];
  return data ? (
    <div className="flex flex-row flex-wrap-reverse gap-y-8 gap-x-12 md:gap-x-16 justify-center px-6 pb-12">
      <section className="flex-auto">
        {label ? <p className="mb-3 text-xs text-hookybase-300">{label}</p> : null}
        <ul
          className={twMerge(
            'grid grid-rows-4 gap-x-1 border-t border-t-hookybase-100 dark:border-t-hookybase-400 pt-2 grid-flow-col',
            data.length > MAX_ROWS ? 'grid-cols-2' : 'grid-cols-1'
          )}
        >
          {data.map((sector, index) => (
            <li
              key={sector.id}
              className="flex items-center border-b border-b-hookybase-100 dark:border-b-hookybase-400 py-2 w-full"
            >
              <div
                className="mr-2 w-3 h-3 flex-none rounded-full"
                style={{ backgroundColor: COLORS[index % COLORS.length] }}
              />
              <h3 className="text-black dark:text-white cursor-default">
                {sector.value}%&nbsp;•&nbsp;{sector.name}
              </h3>
            </li>
          ))}
        </ul>
      </section>
      <Chart width={202} height={202}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={85}
          outerRadius={100}
          paddingAngle={1}
          cornerRadius={50}
          dataKey="value"
          className="outline-none"
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} stroke="none" />
          ))}
        </Pie>
      </Chart>
    </div>
  ) : (
    <Loader className="py-6" />
  );
};
