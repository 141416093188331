import { useEffect, useRef } from 'react';
import { Row } from '@tanstack/react-table';
import { VoiceModel } from '@/models/VoiceModel.ts';
import { useDrawerStore, useModelStore } from '@/store';

export const useMyModelsTableRowHandler = () => {
  const closeDelayDrawer = useRef<NodeJS.Timeout>();
  const handleUnselectRow = useRef<() => void>();
  const { isOpenDrawer, setDrawerState } = useDrawerStore();
  const { setModelData } = useModelStore();

  useEffect(
    () => () => {
      if (isOpenDrawer) {
        setModelData(null);
        setDrawerState(false);

        if (handleUnselectRow.current) {
          handleUnselectRow.current();
        }
      }
    },
    [isOpenDrawer, setDrawerState, setModelData]
  );

  // TODO: Think of using this hook in ProjectContent.tsx
  return (data?: Row<VoiceModel> | VoiceModel | null, onUnselectRow?: () => void) => {
    handleUnselectRow.current = onUnselectRow;

    let adaptedData: VoiceModel | null | undefined;

    if (data && 'original' in data) {
      adaptedData = data.original;
    } else {
      adaptedData = data;
    }

    setDrawerState(!(isOpenDrawer && !data));

    if (!adaptedData) {
      closeDelayDrawer.current = setTimeout(() => {
        setModelData(null);
      }, 200);
    } else {
      setModelData(adaptedData);
    }

    clearTimeout(closeDelayDrawer.current);
  };
};
