import React from 'react';
import { twMerge } from 'tailwind-merge';

export interface ProgressRingProps {
  className?: string;
  radius?: number;
  stroke?: number;
  progress?: number;
  color?: string;
}

// Default color is hookyyellow
export const ProgressRing: React.FC<ProgressRingProps> = ({
  className = '',
  radius = 60,
  stroke = 3,
  progress = 0,
  color = '#ECE81A'
}) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  const animationStyle = {
    transition: 'all 0.6s ease 0s, stroke 0.6s ease'
  };

  return (
    <svg
      height={radius * 2}
      width={radius * 2}
      data-testid="progress-ring"
      className={twMerge('-rotate-90', className)}
    >
      <circle
        data-testid="progress-ring-circle"
        stroke={color}
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ ...animationStyle, strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
};
