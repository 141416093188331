/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';

import { SENTRY_DSN } from '@/config';

Sentry.init({
  dsn: SENTRY_DSN,
  enabled: import.meta.env.VITE_HOOKY_ENVIRONMENT !== 'local',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracePropagationTargets: [/^https:\/\/hooky\.co/, /^https:\/\/dev\.hooky\.co/],
  // Performance Monitoring
  tracesSampleRate: 0.01,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
