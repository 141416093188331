import { Collaborator, IParticipant } from '@/models/Participant.ts';

export const getUserMaxAvailableSplitPercent = (
  collaborators?: IParticipant[],
  royaltyPercent?: number
) =>
  100 -
  (collaborators?.reduce(
    (totalSplit: number, collaborator) => totalSplit + (collaborator as Collaborator).split,
    0
  ) || 0) -
  (royaltyPercent || 0);
