import { API_URL } from '@/config';
import { useGetAccessToken } from '@/hooks';
import axios from 'axios';
import { useAuth } from 'oidc-react';

export interface UseApiProps {
  isPublic?: boolean;
  isFileIncluded?: boolean;
}

export const useApi = ({ isPublic = false, isFileIncluded = false }: UseApiProps) => {
  const { signOut } = useAuth();
  const token = useGetAccessToken();

  const api = axios.create({
    baseURL: API_URL,
    withCredentials: isPublic
  });

  api.interceptors.request.use(
    async function (config) {
      if (token) {
        config.headers.authorization = `Bearer ${token}`;
      }

      config.headers.Accept = 'application/json';

      // application/x-www-form-urlencoded
      // text/plain
      // multipart/form-data
      if (isFileIncluded) {
        config.headers['Content-Type'] = 'x-www-form-urlencoded';
      }

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      if (error.response.status === 401) {
        void signOut();
      }

      return Promise.reject(error);
    }
  );

  return api;
};
