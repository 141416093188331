import { ComponentPropsWithoutRef, FC, MouseEventHandler } from 'react';
import { Icon, Logo, ProgressRing } from '@/components';
import { twMerge } from 'tailwind-merge';

export interface AvatarProps extends ComponentPropsWithoutRef<'div'> {
  className?: string;
  image?: string;
  onClick?: () => void;
  progress?: number;
  onDeleteIconClick?: () => void;
  disabled?: boolean;
}

export const Avatar: FC<AvatarProps> = ({
  className = '',
  image = '',
  onClick,
  progress = 0,
  onDeleteIconClick,
  disabled = false
}) => {
  const rootClassName = twMerge(
    onClick && !disabled ? 'cursor-pointer' : '',
    'z-10 relative group flex items-center justify-center w-40 h-40 rounded-full bg-hookybase-400 overflow-hidden',
    image ? 'bg-cover' : '',
    className
  );

  const overlayClassName = twMerge(
    'absolute opacity-0 bg-white dark:bg-black bg-opacity-50 transition-opacity duration-300',
    'top-0 left-0 w-full h-full',
    onClick && !disabled && 'group-hover:opacity-70'
  );

  const onDeleteClick: MouseEventHandler<SVGElement> = (e) => {
    e.stopPropagation();
    onDeleteIconClick && onDeleteIconClick();
  };

  return (
    <div
      className={rootClassName}
      onClick={onClick}
      style={{ backgroundImage: image ? `url(${image})` : undefined }}
    >
      {!image && (
        <Logo
          className="h-5 text-hookybase-100 absolute top-[50%] translate-y-[-50%]"
          expand={false}
        />
      )}

      {progress > 0 && (
        <ProgressRing className="absolute z-20 top-0" progress={progress} radius={75} />
      )}

      {onClick && !disabled && (
        <div className={overlayClassName}>
          <div className="flex w-full h-full inset-0 items-center justify-center">
            <span className="text-white text-xl">
              <Icon icon={image ? 'Edit' : 'Add'} size="xl" />
            </span>
            {image && onDeleteIconClick ? (
              <span className="text-white text-xl ml-3">
                <Icon onClick={onDeleteClick} icon="Delete" size="xl" />
              </span>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};
