import { FC, ReactNode, MouseEvent } from 'react';
import { Button, MantineSize } from '@mantine/core';

import styles from './button.module.css';

export type ButtonBackgroundProps = 'primary' | 'secondary' | 'third' | 'fourth';
export type ButtonVariantProps = 'filled' | 'outline' | 'pill';

export interface ButtonProps {
  fullWidth?: boolean;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  variant?: ButtonVariantProps;
  background?: ButtonBackgroundProps;
  size?: MantineSize;
  type?: 'submit' | 'button';
  children: ReactNode;
  className?: string;
  classNames?: { section: string };
}

const CLASSES = {
  root: styles.root
};

export const ButtonLib: FC<ButtonProps> = (props) => {
  const {
    children,
    variant = 'filled',
    background = 'primary',
    size = 'lg',
    type = 'button',
    classNames,
    ...rest
  } = props;

  return (
    <Button
      classNames={{ ...CLASSES, ...classNames }}
      data-background={background}
      data-variant={variant}
      radius="xl"
      size={size}
      type={type}
      {...rest}
    >
      {children}
    </Button>
  );
};
