import { Tab, Tabs } from '@/components';
import { TAB_NAME } from '@/constants/song';

import { Collaborators, MasterTab } from './components';

export const Splits = () => {
  return (
    <Tabs className="relative mt-2 md:mt-4" variant="square">
      <Tab name={TAB_NAME.splitsMaster} label="Master" data-testid="song-info-general">
        <MasterTab />
      </Tab>

      <Tab name={TAB_NAME.splitsCollaborations} label="Publishing" data-testid="splits-composition">
        <Collaborators />
      </Tab>
    </Tabs>
  );
};
