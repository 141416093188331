import { Icon as Iconify } from '@iconify/react';
import React from 'react';
import { tv, type VariantProps } from 'tailwind-variants';
import * as Icons from '../../../assets/icons';

const iconVariants = tv({
  base: 'inline-block',
  variants: {
    size: {
      sm: 'w-3 h-3 text-xs/0',
      md: 'w-4 h-4 text-base/0',
      lg: 'w-5 h-5 text-lg/0',
      xl: 'w-6 h-6 text-xl/0',
      '2xl': 'w-7 h-7 text-2xl/0',
      '3xl': 'w-8 h-8 text-3xl/0',
      '4xl': 'w-9 h-9 text-4xl/0'
    }
  },
  defaultVariants: {
    size: 'lg'
  }
});

type IconVariants = VariantProps<typeof iconVariants>;

export type IconSizes = IconVariants['size'];

interface IconProps extends IconVariants, React.HTMLAttributes<SVGElement> {
  className?: string;
  icon: string;
  fill?: string;
}

export const Icon: React.FC<IconProps> = (props: IconProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, icon, size, ...rest } = props;

  const IconComponent = Icons[(icon + 'Icon') as keyof typeof Icons];

  const computedClass = iconVariants({
    ...props,
    // "className" is incorrectly required in tailwind-variants's type definition
    className: className || ''
  });

  if (!IconComponent) {
    // fallback to Iconify
    return <Iconify icon={icon} className={computedClass} data-testid="icon" />;
  }

  return <IconComponent className={computedClass} {...rest} data-testid="icon" />;
};
