import { FC, MouseEvent } from 'react';

import { useDeleteParticipant, useSongPermission, useResentParticipantInvitation } from '@/hooks';
import { useSongStore } from '@/store';
import { ItemMenu, MenuOptionProps } from '@/components';

interface ItemMenuCellProps {
  id: string;
  isHidden?: boolean;
}

export const ItemMenuCell: FC<ItemMenuCellProps> = ({ id, isHidden = false }) => {
  const { hasReadPermission } = useSongPermission();
  const { songData } = useSongStore();
  const { mutate: deleteCollaborator } = useDeleteParticipant({
    workType: 'songs',
    workId: songData.id
  });
  const { mutate: resendInvite } = useResentParticipantInvitation({
    workType: 'songs',
    workId: songData.id
  });

  if (isHidden) return null;

  const OPTIONS: MenuOptionProps[] = [
    {
      label: 'Resend Invitation',
      onClick: () => resendInvite(id)
    },
    {
      label: 'Remove',
      onClick: () => {
        deleteCollaborator(id);
      }
    }
  ];

  const buttonProps = {
    onClick: (event: MouseEvent<HTMLButtonElement>) => event.stopPropagation(),
    disabled: hasReadPermission
  };

  return <ItemMenu options={OPTIONS} buttonProps={buttonProps} />;
};
