import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useApi } from '@/hooks';
import { SongReleaseDataProps } from '@/models/Song';

export const useGetSongReleases = (id: string) => {
  const api = useApi({});

  return useQuery<SongReleaseDataProps | null, AxiosError>({
    queryKey: ['song-releases', id],
    queryFn: async (): Promise<SongReleaseDataProps> => {
      const response = await api.get<SongReleaseDataProps[]>(`/v1/songs/${id}/releases`);

      if (response.data.length >= 1) {
        return response.data[0];
      }

      return {} as SongReleaseDataProps;
    },
    enabled: !!id
  });
};
