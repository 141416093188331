import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@/hooks';
import { IParticipant, ParticipantsApiParams, ParticipantType } from '@/models/Participant.ts';
import { notifications } from '@/utils/notifications';

export const useDeleteParticipant = ({
  workType = 'songs',
  workId,
  type = ParticipantType.COLLABORATOR
}: ParticipantsApiParams) => {
  const api = useApi({});
  const queryClient = useQueryClient();

  return useMutation<IParticipant, AxiosError, string>({
    mutationFn: async (participantId): Promise<IParticipant> => {
      const response = await api.delete<IParticipant>(
        `/v1/${workType}/${workId}/participants/${participantId}`
      );

      return response.data;
    },
    onSuccess: (_, participantId) => {
      queryClient.setQueryData(
        ['participants', workType, workId, type],
        (participants?: IParticipant[]) =>
          participants?.filter((participant) => participant.id !== participantId) || []
      );

      notifications.success(`${type[0].toUpperCase() + type.substring(1)} is removed`);
    },
    onError: () => {
      notifications.error(`Couldn't remove ${type}`);
    }
  });
};
