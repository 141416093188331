import { createColumnHelper, CellContext } from '@tanstack/react-table';

import { PersonnelParticipant } from '@/models/Participant';
import { NameCell } from '@/components';
import { ROLE_OPTIONS_AGGREGATED } from '@/constants/song';

import { DeleteCell } from './DeleteCell.tsx';

const columnHelper = createColumnHelper<PersonnelParticipant>();

// eslint-disable-next-line react-refresh/only-export-components
export const COLUMNS = [
  columnHelper.accessor('name', {
    header: 'NAME',
    cell: (info) => {
      const { avatar_url, name } = info.row.original;
      return <NameCell avatarUrl={avatar_url} title={name} />;
    },
    size: 50
  }),
  columnHelper.accessor('role', {
    header: 'ROLE',
    cell: (info) => (
      <div data-content="cell-split" className="text-hookybase-200 dark:text-hookybase-300">
        {(ROLE_OPTIONS_AGGREGATED as never)[info.getValue()]}
      </div>
    ),
    size: 20
  }),
  columnHelper.accessor('id', {
    header: ' ',
    cell: (info: CellContext<PersonnelParticipant, string>) => (
      <DeleteCell data={info.row.original} />
    ),
    size: 1
  })
];
