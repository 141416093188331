import React from 'react';

const StepperBg = (props: React.SVGAttributes<SVGElement> & { step: number }) => {
  const { step, ...rest } = props;

  if (step < 3) {
    return (
      <svg
        width="354"
        height="26"
        viewBox="0 0 354 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <g clipPath="url(#clip0_1987_77447)">
          <path
            d="M337.436 -1.87136C283.272 4.43745 228.761 6.74373 174.314 8.05311C119.92 9.10458 65.3463 8.89627 11.1919 2.95448C5.7301 2.275 0.756387 6.22296 0.0876938 11.7779C-0.776238 18.2107 4.87593 23.9243 11.1919 23.0613C17.9862 22.2132 24.7854 21.5982 31.5797 21.0328C79.0667 17.2882 126.72 16.9211 174.309 17.9627C228.756 19.2721 283.262 21.5784 337.431 27.8872C345.519 28.894 352.879 23.0514 353.87 14.8331C355.149 5.31037 346.778 -3.15097 337.431 -1.87136H337.436Z"
            fill="var(--mantine-color-dark-5)"
          />
        </g>
        <g clipPath="url(#clip1_1987_77447)">
          <path
            d="M337.436 -1.87136C283.272 4.43745 228.761 6.74373 174.314 8.05311C119.92 9.10458 65.3463 8.89627 11.1919 2.95448C5.7301 2.275 0.756387 6.22296 0.0876938 11.7779C-0.776238 18.2107 4.87593 23.9243 11.1919 23.0613C17.9862 22.2132 24.7854 21.5982 31.5797 21.0328C79.0667 17.2882 126.72 16.9211 174.309 17.9627C228.756 19.2721 283.262 21.5784 337.431 27.8872C345.519 28.894 352.879 23.0514 353.87 14.8331C355.149 5.31037 346.778 -3.15097 337.431 -1.87136H337.436Z"
            fill={step >= 2 ? 'var(--mantine-color-yellow-0)' : 'var(--mantine-color-dark-5)'}
          />
        </g>
        <defs>
          <clipPath id="clip0_1987_77447">
            <rect
              width="24.8058"
              height="511"
              fill="white"
              transform="translate(0 25.0518) rotate(-90)"
            />
          </clipPath>

          <clipPath id="clip1_1987_77447">
            <rect
              width="25"
              height="178"
              fill="var(--mantine-color-dark-5)"
              transform="translate(0 25) rotate(-90)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="354"
      height="26"
      viewBox="0 0 354 26"
      fill="#292929"
      {...props}
    >
      <path
        d="M337.436 -1.87136C283.272 4.43745 228.761 6.74373 174.314 8.05311C119.92 9.10458 65.3463 8.89627 11.1919 2.95448C5.7301 2.275 0.756387 6.22296 0.0876938 11.7779C-0.776238 18.2107 4.87593 23.9243 11.1919 23.0613C17.9862 22.2132 24.7854 21.5982 31.5797 21.0328C79.0667 17.2882 126.72 16.9211 174.309 17.9627C228.756 19.2721 283.262 21.5784 337.431 27.8872C345.519 28.894 352.879 23.0514 353.87 14.8331C355.149 5.31037 346.778 -3.15097 337.431 -1.87136H337.436Z"
        fill="var(--mantine-color-yellow-0)"
      />
    </svg>
  );
};

StepperBg.displayName = 'StepperBg';

export default StepperBg;
