import { SVGAttributes } from 'react';

const ShareIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.6665 29.3346C21.5554 29.3346 20.6109 28.9457 19.8332 28.168C19.0554 27.3902 18.6665 26.4457 18.6665 25.3346C18.6665 25.2013 18.6998 24.8902 18.7665 24.4013L9.39984 18.9346C9.04428 19.268 8.63317 19.5291 8.1665 19.718C7.69984 19.9069 7.19984 20.0013 6.6665 20.0013C5.55539 20.0013 4.61095 19.6124 3.83317 18.8346C3.05539 18.0569 2.6665 17.1124 2.6665 16.0013C2.6665 14.8902 3.05539 13.9457 3.83317 13.168C4.61095 12.3902 5.55539 12.0013 6.6665 12.0013C7.19984 12.0013 7.69984 12.0957 8.1665 12.2846C8.63317 12.4735 9.04428 12.7346 9.39984 13.068L18.7665 7.6013C18.7221 7.44575 18.6943 7.29575 18.6832 7.1513C18.6721 7.00686 18.6665 6.84575 18.6665 6.66797C18.6665 5.55686 19.0554 4.61241 19.8332 3.83464C20.6109 3.05686 21.5554 2.66797 22.6665 2.66797C23.7776 2.66797 24.7221 3.05686 25.4998 3.83464C26.2776 4.61241 26.6665 5.55686 26.6665 6.66797C26.6665 7.77908 26.2776 8.72352 25.4998 9.5013C24.7221 10.2791 23.7776 10.668 22.6665 10.668C22.1332 10.668 21.6332 10.5735 21.1665 10.3846C20.6998 10.1957 20.2887 9.93464 19.9332 9.6013L10.5665 15.068C10.6109 15.2235 10.6387 15.3735 10.6498 15.518C10.6609 15.6624 10.6665 15.8235 10.6665 16.0013C10.6665 16.1791 10.6609 16.3402 10.6498 16.4846C10.6387 16.6291 10.6109 16.7791 10.5665 16.9346L19.9332 22.4013C20.2887 22.068 20.6998 21.8069 21.1665 21.618C21.6332 21.4291 22.1332 21.3346 22.6665 21.3346C23.7776 21.3346 24.7221 21.7235 25.4998 22.5013C26.2776 23.2791 26.6665 24.2235 26.6665 25.3346C26.6665 26.4457 26.2776 27.3902 25.4998 28.168C24.7221 28.9457 23.7776 29.3346 22.6665 29.3346Z"
        fill="currentColor"
      />
    </svg>
  );
};

ShareIcon.displayName = 'ShareIcon';

export default ShareIcon;
