import { SVGAttributes } from 'react';

const GoogleDriveIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      s
      <g clipPath="url(#clip0_3447_7253)">
        <path
          d="M1.81443 19.8779L2.87285 21.7061C3.09278 22.0909 3.40893 22.3933 3.78007 22.6133L7.56014 16.0703H0C0 16.4964 0.109966 16.9225 0.329897 17.3074L1.81443 19.8779Z"
          fill="#0066DA"
        />
        <path
          d="M12 8.37303L8.21993 1.83008C7.8488 2.05001 7.53265 2.35241 7.31271 2.73729L0.329897 14.8335C0.11401 15.2101 0.00028744 15.6366 0 16.0706H7.56014L12 8.37303Z"
          fill="#00AC47"
        />
        <path
          d="M20.2201 22.6133C20.5912 22.3933 20.9074 22.0909 21.1273 21.7061L21.5671 20.95L23.6702 17.3074C23.8902 16.9225 24.0001 16.4964 24.0001 16.0703H16.4395L18.0483 19.2318L20.2201 22.6133Z"
          fill="#EA4335"
        />
        <path
          d="M12 8.37285L15.7801 1.8299C15.409 1.60997 14.9829 1.5 14.543 1.5H9.45708C9.01722 1.5 8.5911 1.62371 8.21997 1.8299L12 8.37285Z"
          fill="#00832D"
        />
        <path
          d="M16.4398 16.0703H7.5601L3.78003 22.6133C4.15116 22.8332 4.57728 22.9432 5.01714 22.9432H18.9828C19.4226 22.9432 19.8488 22.8195 20.2199 22.6133L16.4398 16.0703Z"
          fill="#2684FC"
        />
        <path
          d="M20.1787 8.7854L16.6873 2.73729C16.4674 2.35241 16.1512 2.05001 15.7801 1.83008L12 8.37303L16.4399 16.0706H23.9863C23.9863 15.6445 23.8763 15.2184 23.6564 14.8335L20.1787 8.7854Z"
          fill="#FFBA00"
        />
      </g>
      <defs>
        <clipPath xmlns="http://www.w3.org/2000/svg" id="clip0_3447_7253">
          <rect width="24" height="21.4433" fill="white" transform="translate(0 1.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

GoogleDriveIcon.displayName = 'GoogleDriveIcon';

export default GoogleDriveIcon;
