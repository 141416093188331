import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IVoice } from '@/models/Voice';
import { useModalStore } from '@/store';
import { setServerFormError } from '@/utils/serverTestValidation';
import { CREATE_SONG_MODAL } from '@/pages/Create/components/constants';
import { Icon, InputField, ModalFooterBtn, Modal, SwitchForm } from '@/components';
import styles from './handle-song-form-modal.module.css';

export type FormProps = {
  name: string;
  display_artist?: string;
  genre_id?: string;
  isPrimary: boolean;
};

export type HandleSongFormModalProps = {
  voice?: IVoice;
  isLoading: boolean;
  onSubmitSong: (values: FormProps) => void;
  isGetVoiceLoading?: boolean;
  error?: unknown;
  defaultValues?: FormProps;
};

const SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required('Song Title is required')
    .min(3, 'Name must be at least 3 characters in length'),
  isPrimary: Yup.boolean().required(),
  display_artist: Yup.string()
    .test('display_artist', 'Artist name must be at least 3 characters in length', (value) => {
      if (!value) {
        return true;
      }

      return !(value.length < 3);
    })
    .when('isPrimary', ([isPrimary], schema) =>
      isPrimary ? schema.optional() : schema.required('Artist Name is required')
    ),
  genre_id: Yup.string()
});

export const HandleSongFormModal: FC<HandleSongFormModalProps> = ({
  voice,
  isLoading,
  onSubmitSong,
  isGetVoiceLoading = false,
  error,
  defaultValues
}) => {
  const { closeModal, modalOption } = useModalStore();
  const [isPrimarySwitchedOn, setIsPrimarySwitchedOn] = useState(false);
  const [artistDisplayName, setArtistDisplayName] = useState('');
  const { handleSubmit, control, setValue, formState, setError, reset, watch, trigger } =
    useForm<FormProps>({
      resolver: yupResolver(SCHEMA),
      defaultValues: {
        isPrimary: defaultValues?.isPrimary || false,
        name: defaultValues?.name || '',
        genre_id: defaultValues?.genre_id || '',
        display_artist: defaultValues?.display_artist || ''
      }
    });

  useEffect(() => {
    const subscription = watch(({ isPrimary, display_artist }) => {
      if (voice) {
        setArtistDisplayName(
          isPrimary
            ? `${voice.name} AI${display_artist?.trim() ? ` & ${display_artist.trim()}` : ''}`
            : `${display_artist} feat. ${voice.name} AI`
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [voice, watch]);

  useEffect(() => {
    if (error) {
      setServerFormError<FormProps>(error, setError);
    }
  }, [error, setError]);

  useEffect(() => {
    if (modalOption.name === CREATE_SONG_MODAL && defaultValues) {
      const { name, genre_id, isPrimary, display_artist } = defaultValues;
      reset({
        name,
        isPrimary,
        genre_id,
        display_artist
      });
      setIsPrimarySwitchedOn(isPrimary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOption.name, reset]);

  const isEditSongModal = !!defaultValues;

  const handleSwitch = (name: string, value: boolean) => {
    setValue(name as 'isPrimary', value, { shouldDirty: true });
    void trigger('display_artist');
    setIsPrimarySwitchedOn(value);
  };

  const cancelCreatedSong = () => {
    closeModal();
    reset();
  };

  const handleSubmitSong = handleSubmit((values: FormProps) => {
    onSubmitSong({
      ...values,
      ...(artistDisplayName && { display_artist: artistDisplayName })
    });
  });

  const footerBtn: ModalFooterBtn[] = [
    {
      key: 'cancel',
      background: 'secondary',
      variant: 'outline',
      onClick: cancelCreatedSong,
      content: 'CANCEL'
    },
    {
      key: 'submit',
      disabled: isLoading || !formState.isDirty,
      loading: isLoading,
      onClick: handleSubmitSong,
      content: 'DOWNLOAD'
    }
  ];

  return (
    <Modal
      name={CREATE_SONG_MODAL}
      footerBtns={footerBtn}
      className={styles.modal}
      title={isEditSongModal ? 'EDIT SONG' : 'CREATE NEW SONG'}
      size="500px"
      isCentered
    >
      <div className={styles.content}>
        <form className={styles.form}>
          <InputField
            name="name"
            label="Song title"
            placeholder="Song Title"
            description="*required"
            control={control}
          />
          <SwitchForm
            label="Artist association"
            name="isPrimary"
            leftBox="Featured"
            rightBox="Primary"
            onChange={handleSwitch}
            control={control}
          />
          <InputField
            description={
              <FeatDescription isDescriptionOnTop={isPrimarySwitchedOn}>
                {voice ? `${voice.name} AI` : ''}
              </FeatDescription>
            }
            isDescriptionOnTop={isPrimarySwitchedOn}
            name="display_artist"
            label="Artist name"
            placeholder="John Doe"
            control={control}
          />

          <div>
            <div className={styles.title}>Preview</div>

            <div className={styles.aiModelBox}>
              {voice && !isGetVoiceLoading ? (
                <span>{artistDisplayName}</span>
              ) : (
                <Icon icon="mdi:loading" className="animate-spin" />
              )}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const FeatDescription = ({
  children,
  isDescriptionOnTop
}: {
  children: string;
  isDescriptionOnTop: boolean;
}) => (
  <span
    className={twMerge(
      'flex flex-grow-0 justify-center items-center dark:text-hookybase-100 text-hookybase-300 text-xs leading-6 px-2 bg-hookybase-400 border border-hookybase-400 rounded-full',
      isDescriptionOnTop && 'mt-[-0.5rem]'
    )}
  >
    {isDescriptionOnTop ? null : (
      <span className="dark:text-hookybase-300 text-hookybase-300">feat.&nbsp;</span>
    )}

    {children}
  </span>
);
