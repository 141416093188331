import { Plan, PlanNames } from '@/models/Plan.ts';

export const getPrevCardLabel = (
  plans?: Plan[],
  currentIndex?: number
): Omit<PlanNames, 'Free'> | undefined => {
  if (currentIndex && currentIndex > 0) {
    return plans?.[currentIndex - 1].name;
  }
  return;
};
