import { SVGAttributes } from 'react';

const LinkIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g xmlns="http://www.w3.org/2000/svg" id="Icon-link">
        <path
          id="Vector"
          d="M14.6667 22.6654H9.33341C7.48897 22.6654 5.91675 22.0154 4.61675 20.7154C3.31675 19.4154 2.66675 17.8431 2.66675 15.9987C2.66675 14.1543 3.31675 12.582 4.61675 11.282C5.91675 9.98203 7.48897 9.33203 9.33341 9.33203H14.6667V11.9987H9.33341C8.2223 11.9987 7.27786 12.3876 6.50008 13.1654C5.7223 13.9431 5.33341 14.8876 5.33341 15.9987C5.33341 17.1098 5.7223 18.0543 6.50008 18.832C7.27786 19.6098 8.2223 19.9987 9.33341 19.9987H14.6667V22.6654ZM10.6667 17.332V14.6654H21.3334V17.332H10.6667ZM17.3334 22.6654V19.9987H22.6667C23.7779 19.9987 24.7223 19.6098 25.5001 18.832C26.2779 18.0543 26.6667 17.1098 26.6667 15.9987C26.6667 14.8876 26.2779 13.9431 25.5001 13.1654C24.7223 12.3876 23.7779 11.9987 22.6667 11.9987H17.3334V9.33203H22.6667C24.5112 9.33203 26.0834 9.98203 27.3834 11.282C28.6834 12.582 29.3334 14.1543 29.3334 15.9987C29.3334 17.8431 28.6834 19.4154 27.3834 20.7154C26.0834 22.0154 24.5112 22.6654 22.6667 22.6654H17.3334Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

LinkIcon.displayName = 'LinkIcon';

export default LinkIcon;
