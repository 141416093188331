import { ModalFooterBtn, Modal } from '@/components';
import { useModalStore } from '@/store';
import { DELETE_ACCOUNT_CONFIRMED_MODAL } from '..';

import styles from './delete-account-modal.module.css';

export const DELETE_ACCOUNT_MODAL = 'delete_account';

export const DeleteAccountModal = () => {
  const { closeModal, openModal } = useModalStore();

  const handleConfirm = () => {
    // TODO: Replace openModal function with API call, openModal should be placed into useEffect on success response
    openModal({ name: DELETE_ACCOUNT_CONFIRMED_MODAL });
  };

  const footerBtns: ModalFooterBtn[] = [
    {
      key: 'cancel',
      background: 'secondary',
      variant: 'outline',
      onClick: closeModal,
      content: 'CANCEL'
    },
    {
      key: 'confirm',
      onClick: handleConfirm,
      content: 'SUBMIT'
    }
  ];

  return (
    <Modal
      name={DELETE_ACCOUNT_MODAL}
      title="What you need to know before you delete your Hooky account"
      size="750px"
      footerBtns={footerBtns}
      isCentered
    >
      <div className={styles.content}>
        <p>
          If you want to delete your Hooky account, you must cancel your subscription first or you
          will continue to be charged.
        </p>

        <p>
          Deleting your Hooky account will delete your email address, first and last name, and
          profile name and attributes. It will also make all other Hooky account information
          anonymous so it is no longer associated with your email address. You will also be
          unsubscribed from all marketing from Hooky.
        </p>

        <p>
          To cancel, navigate to ‘Cancel subscription’ under Account Settings. If your subscription
          is invoiced through a third party, go here for more information on how to cancel.
        </p>

        <p>
          {/* TODO add link to landing site */}
          If your Hooky subscription is invoiced by Hooky - Coming Early 2024 , once you’ve
          cancelled, you will continue to have access to Hooky until the end of your current payment
          period. Your Hooky account deletion will then be processed promptly after your
          subscription ends.
        </p>
      </div>
    </Modal>
  );
};
